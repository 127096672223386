import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './Home';
import Feed from './Feed';
import Join from './Join';
import Signin from './Signin';
import JoinGuide from './JoinGuide';
import JoinProspective from './JoinProspective';
import GuideInformation from './GuideInformation';
import Account from './Account';
import SpecificTour from './SpecificTour';
import ProspectiveAccount from './ProspectiveAccount';
import ProspectiveTours from './ProspectiveTours';
import Book from './Book';
import CompletedBooking from './CompletedBooking';
import GuideTours from './GuideTours';
import {Link} from "react-router-dom";
import NavBar from './NavBar';
import GuideInformation1 from './GuideInformation1';
import { UserProvider } from './UserContext';
import FocusTour from './FocusTour';
import Booking from './Booking';
import ManageTour from './ManageTour';
import AllBookings from './AllBookings';
import Try from './Try';
import CompleteSignUp from './CompleteSignUp';
import Homecopy from './Homecopy';
function App() {
  return (
    <UserProvider>
    <div className="App">
    
     <BrowserRouter>
      <Routes>
       
        <Route path="/" element={<Home />} />
        <Route path="/Homecopy" element={<Homecopy />} />
        <Route path="/Feed" element={<Feed />} />
        <Route path="/Join" element={<Join />} />
        <Route path="/Signin" element={<Signin />} />
        <Route path="/JoinGuide" element={<JoinGuide />} />
        <Route path="/JoinProspective" element={<JoinProspective />} />
        <Route path="/GuideInformation" element={<GuideInformation />} />
        <Route path="/GuideInformation1" element={<GuideInformation1 />} />
        <Route path="/Account/:onboarded" element={<Account />} />
        <Route path="/SpecificTour/:id" element={<SpecificTour />} />
        <Route path="/ProspectiveAccount" element={<ProspectiveAccount />} />
        <Route path="/Book/:id" element={<Book />} />
        <Route path="/ProspectiveTours" element={<ProspectiveTours />} />
        <Route path="/CompletedBooking" element={<CompletedBooking />} />
        <Route path="/GuideTours" element={<GuideTours />} />
        <Route path="/FocusTour/:id" element={<FocusTour />} />
        <Route path="/Booking/:id" element={<Booking />} />
        <Route path="/ManageTour" element={<ManageTour />} />
        <Route path="/AllBookings" element={<AllBookings />} />
        <Route path="/Try" element={<Try />} />
        <Route path="/CompleteSignUp" element={<CompleteSignUp />} />
      </Routes>
    </BrowserRouter>
    </div>
    </UserProvider>
  );
}

export default App;







