import {Link, useNavigate} from "react-router-dom";
import { auth, db } from './init';
import {isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { doc, setDoc, addDoc, collection, updateDoc, getDoc } from "firebase/firestore";
function NavBar(props) {
 
  const [loggedIn, setLoggedIn] = useState();

  useEffect(() => {
   
   
    // This is the listener for auth state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        
        // User is signed in
        setLoggedIn(true);
      } else {
        // User is signed out
        setLoggedIn(false);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    
    <div className={props.border == null ? 'NavHolder' : 'NavHolder_1'}>
      <div className="hamburger">
        {!props.color && <img onClick={props.onClick} className="hamburger_img" src={require('./hamburger.png')} alt="navigation menu" ></img> }
        {props.color == null && <img onClick={props.onClick} className="hamburger_img" src={require('./hamburgerblack.png')} alt="navigation menu" ></img> }
      </div>
    <div className="NavHolder1">
    <div className="NavHolder2">
    
    {props.color == null && <Link to="/" className="home">EduGold</Link> }
    {props.color == false && <Link to="/" className="home" style={{color: 'white'}}>EduGold</Link> }
    </div>
    <div className="NavHolder3">
    {!loggedIn && 
    <div>
    {/*props.color == null && <Link to="/Signin" className="nav">Sign in</Link> */}
    {/*props.color == false && <Link to="/Signin" className="nav" style={{color: 'white'}}>Sign in</Link> */}
    {props.color == null && <Link to="/JoinGuide" className="nav">Become a guide</Link> }
    {props.color == false && <Link to="/JoinGuide" className="nav" style={{color: 'white'}}>Become a guide</Link> }
    </div>
}

{props.color == null && <Link to="/Feed" className="nav">Explore</Link> }
{props.color == false && <Link to="/Feed" className="nav" style={{color: 'white'}}>Explore</Link> }

    {loggedIn && 
    <div> 
      
      {props.color == null &&  <Link to="/Account/0" className="nav" >My Account</Link> }
      {props.color == false &&  <Link to="/Account/0" className="nav" style={{color: 'white'}} >My Account</Link> }
      
      {props.color == null &&  <Link to="/GuideTours" className="nav" >Dashboard</Link> }
      {props.color == false &&  <Link to="/GuideTours" className="nav" style={{color: 'white'}}>Dashboard</Link> }
    </div>
}
 {/*loggedIn && props.loggedIn == false &&
  <div>
      {props.color == null &&  <Link to="/ProspectiveAccount" className="nav">My Account</Link> }
      {props.color == false &&  <Link to="/ProspectiveAccount" className="nav" style={{color: "white"}}>My Account</Link> }
      {props.color == null &&  <Link to="/ProspectiveTours" className="nav">Booked Tours</Link> }
      {props.color == false &&  <Link to="/ProspectiveTours" className="nav" style={{color: 'white'}}>Booked Tours</Link> }
  </div>
*/}
   
   </div>
    
   

  
    
    </div>
    </div>
  
  );
}
export function getUser() {
  console.log("hi");
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe(); // Unsubscribe after receiving the initial value
      if (user) {
        //checkIfGuide(user.uid);

        resolve(user); // Resolve the promise with the user object
      } else {
        resolve(null); // Resolve with null if there is no user
      }
    }, reject); // Reject the promise on error
  });
}
export function userIsGuide() {
  console.log("hi");
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      unsubscribe(); // Unsubscribe after receiving the initial value
      if (user) {
        try {
          console.log("hi");
          const isGuide = await checkIfGuide(user.uid);
          resolve(isGuide); // Resolve with the boolean value from checkIfGuide
        } catch (error) {
          reject(error); // Handle errors from checkIfGuide
        }
      } else {
        resolve(false); // Resolve with false if there is no user
      }
    }, reject); // Reject the promise on error
  });
}
async function checkIfGuide(id){
  const docRef = doc(db, "users", id);
  console.log("hi");
  // alert(user.uid);
   const docSnap = await getDoc(docRef);
   if (docSnap.exists() && typeof docSnap.data().guide === 'boolean') {
    console.log(docSnap.data().guide);
    return docSnap.data().guide;
  }
}
export default NavBar;
