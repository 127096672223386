import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {doc, getDoc} from 'firebase/firestore';
import { auth, db } from './init';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { useUser } from './UserContext';
import { onAuthStateChanged } from 'firebase/auth';
function FocusTour() {
    let { id } = useParams();
  const storage = getStorage();
    const [db_name, set_db_name] = useState('');
    const [db_graduation_year, set_db_graduation_year] = useState(null);
    const [db_university, set_db_university] = useState(null);
    const [db_majors, set_db_majors] = useState([]);
    const [db_minors, set_db_minors] = useState([]);
    const [added_categories, setAddedCategories] = useState([]);
    const [db_bio, set_db_bio] = useState('');
    const [db_description, set_db_description] = useState('');
    const [profileImageUrls, setProfileImageUrls] = useState([]);
   
    
    useEffect(() => {
        read_db();
        fetchProfileImages_db(setProfileImageUrls);
      }, [id]);
     const navigate = useNavigate();
      function Book(){
     
        /* 1. Navigate to the Details route with params */
        navigate(`/Booking/${id}`);
       
    }
      useEffect(() => {
        const parentDiv = document.getElementById("holder");
        let length = added_categories.length + db_majors.length + db_minors.length;
        let percentage = 30;
        if(length < 4){
          percentage = 20;
        }
        if(length > 6){
          percentage = 50;
        }
        if(length > 9){
          percentage = 50;
        }
        parentDiv.style.height = `${percentage}%`;
      }, [added_categories])
    async function read_db(){
        // alert(user.uid);
            const docRef = doc(db, "guide_tours", id);
           // alert(user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                set_db_university(docSnap.data().university);
                set_db_graduation_year(docSnap.data().graduationYear);
                set_db_majors(docSnap.data().majors);
                set_db_minors(docSnap.data().minors);
                set_db_bio(docSnap.data().bio);
                set_db_name(docSnap.data().name);
                set_db_description(docSnap.data().description);
                setAddedCategories(docSnap.data().ecs);
                console.log("Document data:", docSnap.data());
              } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
              }
        }
        function fetchProfileImages_db(setProfileImageUrls) {
            const imagesRef = ref(storage, `profile_images/${id}/`); // Adjust 'images/' to your specific path
            
            listAll(imagesRef)
              .then((res) => {
               
                res.items.forEach((itemRef) => {
                  // Get the download URL for each image
                  getDownloadURL(itemRef).then((url) => {
                    setProfileImageUrls(prevUrls => [...prevUrls, url]);
                  });
                });
              }).catch((error) => {
                console.error("Error fetching images:", error);
              });
          }
          let element = document.getElementById("h");
     
// Iterate over each element
if(element){
    // Get the text content of the <p> element
    let text = element.textContent;
    // Set the maximum length of the text
    let maxLength = 20; // Adjust as needed

    // Check if the text length exceeds the maximum length
    if (text.length > maxLength) {
        // Truncate the text and add ellipsis
        let truncatedText = text.substring(0, maxLength) + '...';
        // Set the truncated text as the new content of the <p> element
        element.textContent = truncatedText;
    }
  }

  return (
    <div className="App">
       <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svggg'>
  {/* Define linear gradient for the background */}
  <defs>
    {/* Define linear gradient animation */}
    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#4158D0">
        <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="46%" stopColor="#C850C0">
        <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="100%" stopColor="#FFCC70">
        <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
      </stop>
    </linearGradient>
  </defs>
 
  {/* Rotate the entire SVG upside down */}
  <g transform="rotate(180 250 100)">
    {/* Draw the paths with their respective gradients */}
    <path d="M 0 100 C 50 50 200 00 200 0 L 0 0 L 0 0" fill="url(#gradient1)" />
  </g>
</svg>
       <div className='guideTopNav'>
     <div className='guideTopNavHalf'>
      <h3>EduGold</h3>
     </div>
      <div className='guideTopNavHalf'>
      <p>Back</p>
      </div>
     </div>
     <div className='app_bottom'>
    <div className='app_bottom_center'>
      <div className='abc_left'>
        <div className='abc_top'>
          <h1>{db_name}</h1>
        </div>
        <div className='abc_top1'>
         <img src={profileImageUrls[0]}alt="profile image" /> 
        </div>
        <div className='abc_top2'>
          <h2 style={{height: '10px'}}>Tour Details</h2>
          <p>{db_description}</p>
        </div>
      </div>
      <div className='abc_middle'></div>
      <div className='abc_right'>
        
        <div className='abc_right_top1'>
          <div className='abc_right_top1_center'>
         {db_university && <h2 id="h">{db_university.value}</h2> }
        
         <div class="circle"></div>
        
         {db_graduation_year && <h2>{db_graduation_year.value}</h2> }
         
        </div>
        </div>
        <div className='abc_right_top2' id="holder">
        {db_majors.map((major, index) => {
          return(
        <div className='major_holder1'> 
            <p>{major.value}</p>
          </div>
          );
        })}
           {db_minors.map((major, index) => {
          return(
        <div className='major_holder1'> 
            <p>{major.value}</p>
          </div>
          );
        })}
          {added_categories.map((ec, index) => {
          return(
        <div className='major_holder'> 
            <p>{ec}</p>
          </div>
          );
        })}
          
         
        </div>
        <div className='abc_right_top33'>
          <h3 style={{margin: 0, padding: 0, height: '20px'}}>Bio</h3>
          <p>{db_bio}</p>
        </div>
        <div className='book_button'>
          <button onClick={Book}>Book now</button>
     </div>
      </div>
    </div>
     </div>
       
        {/*
         <img src={profileImageUrls[0]}alt="profile image" /> 
         <p>{db_name}</p>
      <p>{db_university.value}</p>
      <p>{db_graduation_year.value}</p>
      <p>{db_majors}</p>
      <p>{db_minors}</p>
      <p>{db_bio}</p>
      <p>{db_description}</p>
      <p>{added_categories}</p>
  */}
    
    </div>
  );
}

export default FocusTour;
