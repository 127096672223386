import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import NavBar from './NavBar'
import { doc, setDoc, addDoc, collection, updateDoc, getDoc } from "firebase/firestore";
import { auth, db } from './init';
import {isSignInWithEmailLink, signInWithEmailLink, signOut } from "firebase/auth";
import { useEffect, useState, useRef } from 'react';
import { getUser } from './NavBar';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { useUser } from './UserContext';
import DaySelector from './components/DaySelector';
import Select from 'react-select'
import SearchComponent from './SearchComponent';
import CategoryChoice from './CategoryChoice';
import T from './T';
import { useParams } from 'react-router-dom';
import DashboardComponent from './DashboardComponent';
function Account() {
  let { onboarded }= useParams();
  const [has_onboarded, setHas_onboarded] = useState(false);
  const [aId, setaId] = useState(null);
  const [addedCategories, setAddedCategories] = useState([]);
  const universitiess = [{ value:'A.T. Still University', label: 'A.T. Still University'}, 
{ value:'Abilene Christian University', label: 'Abilene Christian University'}, 
{ value:'Abraham Baldwin Agricultural College', label: 'Abraham Baldwin Agricultural College'}, 
{ value:'Academy of Art University', label: 'Academy of Art University'}, 
{ value:'Adams State University', label: 'Adams State University'}, 
{ value:'Adelphi University', label: 'Adelphi University'}, 
{ value:'Adler Graduate School', label: 'Adler Graduate School'}, 
{ value:'Adler University', label: 'Adler University'}, 
{ value:'Adrian College', label: 'Adrian College'}, 
{ value:'AdventHealth University', label: 'AdventHealth University'}, 
{ value:'Agnes Scott College', label: 'Agnes Scott College'}, 
{ value:'Air Force Institute of Technology', label: 'Air Force Institute of Technology'}, 
{ value:'Alabama A&M University', label: 'Alabama A&M University'}, 
{ value:'Alabama State University', label: 'Alabama State University'}, 
{ value:'Alaska Bible College', label: 'Alaska Bible College'}, 
{ value:'Alaska Pacific University', label: 'Alaska Pacific University'}, 
{ value:'Albany College of Pharmacy and Health Sciences', label: 'Albany College of Pharmacy and Health Sciences'}, 
{ value:'Albany Law School', label: 'Albany Law School'}, 
{ value:'Albany Medical College', label: 'Albany Medical College'}, 
{ value:'Albany State University', label: 'Albany State University'}, 
{ value:'Albertus Magnus College', label: 'Albertus Magnus College'}, 
{ value:'Albion College', label: 'Albion College'}, 
{ value:'Albright College', label: 'Albright College'}, 
{ value:'Alcorn State University', label: 'Alcorn State University'}, 
{ value:'Alderson Broaddus University', label: 'Alderson Broaddus University'}, 
{ value:'Alfred State College', label: 'Alfred State College'}, 
{ value:'Alfred University', label: 'Alfred University'}, 
{ value:'Alice Lloyd College', label: 'Alice Lloyd College'}, 
{ value:'Allegheny College', label: 'Allegheny College'}, 
{ value:'Allen College', label: 'Allen College'}, 
{ value:'Allen University', label: 'Allen University'}, 
{ value:'Alliance University', label: 'Alliance University'}, 
{ value:'Alliant International University', label: 'Alliant International University'}, 
{ value:'Alma College', label: 'Alma College'}, 
{ value:'Alvernia University', label: 'Alvernia University'}, 
{ value:'Alverno College', label: 'Alverno College'}, 
{ value:'Amberton University', label: 'Amberton University'}, 
{ value:'American Baptist College', label: 'American Baptist College'}, 
{ value:'American Film Institute Conservatory', label: 'American Film Institute Conservatory'}, 
{ value:'American International College', label: 'American International College'}, 
{ value:'American Jewish University', label: 'American Jewish University'}, 
{ value:'American University', label: 'American University'}, 
{ value:'Amherst College', label: 'Amherst College'}, 
{ value:'Anderson University', label: 'Anderson University'}, 
{ value:'Anderson University, Indiana', label: 'Anderson University, Indiana'}, 
{ value:'Andrews University', label: 'Andrews University'}, 
{ value:'Angelo State University', label: 'Angelo State University'}, 
{ value:'Anna Maria College', label: 'Anna Maria College'}, 
{ value:'Antioch University', label: 'Antioch University'}, 
{ value:'Antioch University Los Angeles', label: 'Antioch University Los Angeles'}, 
{ value:'Antioch University New England', label: 'Antioch University New England'}, 
{ value:'Antioch University Santa Barbara', label: 'Antioch University Santa Barbara'}, 
{ value:'Antioch University Seattle', label: 'Antioch University Seattle'}, 
{ value:'Appalachian Bible College', label: 'Appalachian Bible College'}, 
{ value:'Appalachian College of Pharmacy', label: 'Appalachian College of Pharmacy'}, 
{ value:'Appalachian State University', label: 'Appalachian State University'}, 
{ value:'Aquinas College', label: 'Aquinas College'}, 
{ value:'Aquinas College, Tennessee', label: 'Aquinas College, Tennessee'}, 
{ value:'Arcadia University', label: 'Arcadia University'}, 
{ value:'Arizona Christian University', label: 'Arizona Christian University'}, 
{ value:'Arizona State University', label: 'Arizona State University'}, 
{ value:'Arkansas Baptist College', label: 'Arkansas Baptist College'}, 
{ value:'Arkansas State University', label: 'Arkansas State University'}, 
{ value:'Arkansas Tech University', label: 'Arkansas Tech University'}, 
{ value:'Arlington Baptist University', label: 'Arlington Baptist University'}, 
{ value:'Art Academy of Cincinnati', label: 'Art Academy of Cincinnati'}, 
{ value:'ArtCenter College of Design', label: 'ArtCenter College of Design'}, 
{ value:'Asbury University', label: 'Asbury University'}, 
{ value:'Ashland University', label: 'Ashland University'}, 
{ value:'Assumption University', label: 'Assumption University'}, 
{ value:'Athens State University', label: 'Athens State University'}, 
{ value:'Atlanta Metropolitan State College', label: 'Atlanta Metropolitan State College'}, 
{ value:'Auburn University', label: 'Auburn University'}, 
{ value:'Auburn University at Montgomery', label: 'Auburn University at Montgomery'}, 
{ value:'Augsburg University', label: 'Augsburg University'}, 
{ value:'Augusta University', label: 'Augusta University'}, 
{ value:'Augustana College', label: 'Augustana College'}, 
{ value:'Augustana University', label: 'Augustana University'}, 
{ value:'Aurora University', label: 'Aurora University'}, 
{ value:'Austin College', label: 'Austin College'}, 
{ value:'Austin Peay State University', label: 'Austin Peay State University'}, 
{ value:'Ave Maria University', label: 'Ave Maria University'}, 
{ value:'Averett University', label: 'Averett University'}, 
{ value:'Avila University', label: 'Avila University'}, 
{ value:'Azusa Pacific University', label: 'Azusa Pacific University'}, 
{ value:'Babson College', label: 'Babson College'}, 
{ value:'Bacone College', label: 'Bacone College'}, 
{ value:'Baker College', label: 'Baker College'}, 
{ value:'Baker University', label: 'Baker University'}, 
{ value:'Baldwin Wallace University', label: 'Baldwin Wallace University'}, 
{ value:'Ball State University', label: 'Ball State University'}, 
{ value:'Bank Street College of Education', label: 'Bank Street College of Education'}, 
{ value:'Baptist Bible College', label: 'Baptist Bible College'}, 
{ value:'Baptist Health Sciences University', label: 'Baptist Health Sciences University'}, 
{ value:'Baptist University of the Americas', label: 'Baptist University of the Americas'}, 
{ value:'Barclay College', label: 'Barclay College'}, 
{ value:'Bard College', label: 'Bard College'}, 
{ value:'Bard College at Simons Rock', label: 'Bard College at Simons Rock'}, 
{ value:'Barnard College', label: 'Barnard College'}, 
{ value:'Barry University', label: 'Barry University'}, 
{ value:'Barton College', label: 'Barton College'}, 
{ value:'Baruch College, CUNY', label: 'Baruch College, CUNY'}, 
{ value:'Bastyr University', label: 'Bastyr University'}, 
{ value:'Bates College', label: 'Bates College'}, 
{ value:'Bay Path University', label: 'Bay Path University'}, 
{ value:'Bay State College', label: 'Bay State College'}, 
{ value:'Baylor College of Medicine', label: 'Baylor College of Medicine'}, 
{ value:'Baylor University', label: 'Baylor University'}, 
{ value:'Beacon College', label: 'Beacon College'}, 
{ value:'Belhaven University', label: 'Belhaven University'}, 
{ value:'Bellarmine University', label: 'Bellarmine University'}, 
{ value:'Bellevue College', label: 'Bellevue College'}, 
{ value:'Bellevue University', label: 'Bellevue University'}, 
{ value:'Bellin College', label: 'Bellin College'}, 
{ value:'Belmont Abbey College', label: 'Belmont Abbey College'}, 
{ value:'Belmont University', label: 'Belmont University'}, 
{ value:'Beloit College', label: 'Beloit College'}, 
{ value:'Bemidji State University', label: 'Bemidji State University'}, 
{ value:'Benedict College', label: 'Benedict College'}, 
{ value:'Benedictine College', label: 'Benedictine College'}, 
{ value:'Benedictine University', label: 'Benedictine University'}, 
{ value:'Benjamin Franklin Cummings Institute of Technology', label: 'Benjamin Franklin Cummings Institute of Technology'}, 
{ value:'Bennett College', label: 'Bennett College'}, 
{ value:'Bennington College', label: 'Bennington College'}, 
{ value:'Bentley University', label: 'Bentley University'}, 
{ value:'Berea College', label: 'Berea College'}, 
{ value:'Berkeley College', label: 'Berkeley College'}, 
{ value:'Berklee College of Music', label: 'Berklee College of Music'}, 
{ value:'Berry College', label: 'Berry College'}, 
{ value:'Bethany College', label: 'Bethany College'}, 
{ value:'Bethany College, Kansas', label: 'Bethany College, Kansas'}, 
{ value:'Bethany Lutheran College', label: 'Bethany Lutheran College'}, 
{ value:'Bethel College', label: 'Bethel College'}, 
 { value:'Bethel University', label: 'Bethel University'}, 
{ value:'Bethel University, Tennessee', label: 'Bethel University, Tennessee'}, 
{ value:'Bethesda University', label: 'Bethesda University'}, 
{ value:'Bethune-Cookman University', label: 'Bethune-Cookman University'}, 
{ value:'Beulah Heights University', label: 'Beulah Heights University'}, 
{ value:'Binghamton University, State University of New York', label: 'Binghamton University, State University of New York'}, 
{ value:'Biola University', label: 'Biola University'}, 
{ value:'Birmingham-Southern College', label: 'Birmingham-Southern College'}, 
{ value:'Bismarck State College', label: 'Bismarck State College'}, 
{ value:'Black Hills State University', label: 'Black Hills State University'}, 
{ value:'Blackburn College', label: 'Blackburn College'}, 
{ value:'Blessing-Rieman College of Nursing and Health Sciences', label: 'Blessing-Rieman College of Nursing and Health Sciences'}, 
{ value:'Bloomfield College', label: 'Bloomfield College'}, 
{ value:'Bloomsburg University of Pennsylvania', label: 'Bloomsburg University of Pennsylvania'}, 
{ value:'Blue Mountain Christian University', label: 'Blue Mountain Christian University'}, 
{ value:'Bluefield State University', label: 'Bluefield State University'}, 
{ value:'Bluefield University', label: 'Bluefield University'}, 
{ value:'Bluffton University', label: 'Bluffton University'}, 
{ value:'Bob Jones University', label: 'Bob Jones University'}, 
{ value:'Boise Bible College', label: 'Boise Bible College'}, 
{ value:'Boise State University', label: 'Boise State University'}, 
{ value:'Boricua College', label: 'Boricua College'}, 
{ value:'Boston Architectural College', label: 'Boston Architectural College'}, 
{ value:'Boston College', label: 'Boston College'}, 
{ value:'Boston Graduate School of Psychoanalysis', label: 'Boston Graduate School of Psychoanalysis'}, 
{ value:'Boston University', label: 'Boston University'}, 
{ value:'Bowdoin College', label: 'Bowdoin College'}, 
{ value:'Bowie State University', label: 'Bowie State University'}, 
{ value:'Bowling Green State University', label: 'Bowling Green State University'}, 
{ value:'Bradley University', label: 'Bradley University'}, 
{ value:'Brandeis University', label: 'Brandeis University'}, 
{ value:'Brazosport College', label: 'Brazosport College'}, 
{ value:'Brenau University', label: 'Brenau University'}, 
{ value:'Brescia University', label: 'Brescia University'}, 
{ value:'Brevard College', label: 'Brevard College'}, 
{ value:'Brewton-Parker College', label: 'Brewton-Parker College'}, 
{ value:'Briar Cliff University', label: 'Briar Cliff University'}, 
{ value:'Bridgewater College', label: 'Bridgewater College'}, 
{ value:'Bridgewater State University', label: 'Bridgewater State University'}, 
{ value:'Brigham Young University', label: 'Brigham Young University'}, 
{ value:'Brigham Young University-Hawaii', label: 'Brigham Young University-Hawaii'}, 
{ value:'Brigham Young University-Idaho', label: 'Brigham Young University-Idaho'}, 
{ value:'Brooklyn College', label: 'Brooklyn College'}, 
{ value:'Brooklyn Law School', label: 'Brooklyn Law School'}, 
{ value:'Broward College', label: 'Broward College'}, 
{ value:'Brown University', label: 'Brown University'}, 
{ value:'Bryan College', label: 'Bryan College'}, 
{ value:'Bryan College of Health Sciences', label: 'Bryan College of Health Sciences'}, 
{ value:'Bryant and Stratton College', label: 'Bryant and Stratton College'}, 
{ value:'Bryant University', label: 'Bryant University'}, 
{ value:'Bryn Athyn College', label: 'Bryn Athyn College'}, 
{ value:'Bryn Mawr College', label: 'Bryn Mawr College'}, 
{ value:'Bucknell University', label: 'Bucknell University'}, 
{ value:'Buena Vista University', label: 'Buena Vista University'}, 
{ value:'Buffalo State University', label: 'Buffalo State University'}, 
{ value:'Bushnell University', label: 'Bushnell University'}, 
{ value:'Butler University', label: 'Butler University'}, 
{ value:'Cabarrus College of Health Sciences', label: 'Cabarrus College of Health Sciences'}, 
{ value:'Cabrini University', label: 'Cabrini University'}, 
{ value:'Cairn University', label: 'Cairn University'}, 
{ value:'Caldwell University', label: 'Caldwell University'}, 
{ value:'California Baptist University', label: 'California Baptist University'}, 
{ value:'California College of the Arts', label: 'California College of the Arts'}, 
{ value:'California Institute of Integral Studies', label: 'California Institute of Integral Studies'}, 
{ value:'California Institute of Technology', label: 'California Institute of Technology'}, 
{ value:'California Institute of the Arts', label: 'California Institute of the Arts'}, 
{ value:'California Lutheran University', label: 'California Lutheran University'}, 
{ value:'California Polytechnic State University, San Luis Obispo', label: 'California Polytechnic State University, San Luis Obispo'}, 
{ value:'California State Polytechnic University, Pomona', label: 'California State Polytechnic University, Pomona'}, 
{ value:'California State University Channel Islands', label: 'California State University Channel Islands'}, 
{ value:'California State University Maritime Academy', label: 'California State University Maritime Academy'}, 
{ value:'California State University San Marcos', label: 'California State University San Marcos'}, 
{ value:'California State University, Bakersfield', label: 'California State University, Bakersfield'}, 
{ value:'California State University, Chico', label: 'California State University, Chico'}, 
{ value:'California State University, Dominguez Hills', label: 'California State University, Dominguez Hills'}, 
{ value:'California State University, East Bay', label: 'California State University, East Bay'}, 
{ value:'California State University, Fresno', label: 'California State University, Fresno'}, 
{ value:'California State University, Fullerton', label: 'California State University, Fullerton'}, 
{ value:'California State University, Long Beach', label: 'California State University, Long Beach'}, 
{ value:'California State University, Los Angeles', label: 'California State University, Los Angeles'}, 
{ value:'California State University, Monterey Bay', label: 'California State University, Monterey Bay'}, 
{ value:'California State University, Northridge', label: 'California State University, Northridge'}, 
{ value:'California State University, Sacramento', label: 'California State University, Sacramento'}, 
{ value:'California State University, San Bernardino', label: 'California State University, San Bernardino'}, 
{ value:'California State University, Stanislaus', label: 'California State University, Stanislaus'}, 
{ value:'California Western School of Law', label: 'California Western School of Law'}, 
{ value:'Calumet College of St. Joseph', label: 'Calumet College of St. Joseph'}, 
{ value:'Calvary University', label: 'Calvary University'}, 
{ value:'Calvin University', label: 'Calvin University'}, 
{ value:'Cambridge College', label: 'Cambridge College'}, 
{ value:'Cameron University', label: 'Cameron University'}, 
{ value:'Campbell University', label: 'Campbell University'}, 
{ value:'Campbellsville University', label: 'Campbellsville University'}, 
{ value:'Canisius University', label: 'Canisius University'}, 
{ value:'Capital University', label: 'Capital University'}, 
{ value:'Capitol Technology University', label: 'Capitol Technology University'}, 
{ value:'Cardinal Stritch University', label: 'Cardinal Stritch University'}, 
{ value:'Carleton College', label: 'Carleton College'}, 
{ value:'Carlow University', label: 'Carlow University'}, 
{ value:'Carnegie Mellon University', label: 'Carnegie Mellon University'}, 
{ value:'Carolina University', label: 'Carolina University'}, 
{ value:'Carroll College', label: 'Carroll College'}, 
{ value:'Carroll University', label: 'Carroll University'}, 
{ value:'Carson-Newman University', label: 'Carson-Newman University'}, 
{ value:'Carthage College', label: 'Carthage College'}, 
{ value:'Case Western Reserve University', label: 'Case Western Reserve University'}, 
{ value:'Castleton University', label: 'Castleton University'}, 
{ value:'Catawba College', label: 'Catawba College'}, 
{ value:'Cazenovia College', label: 'Cazenovia College'}, 
{ value:'Cedar Crest College', label: 'Cedar Crest College'}, 
{ value:'Cedarville University', label: 'Cedarville University'}, 
{ value:'Centenary College of Louisiana', label: 'Centenary College of Louisiana'}, 
{ value:'Centenary University', label: 'Centenary University'}, 
{ value:'Central Baptist College', label: 'Central Baptist College'}, 
{ value:'Central Christian College of Kansas', label: 'Central Christian College of Kansas'}, 
{ value:'Central Christian College of the Bible', label: 'Central Christian College of the Bible'}, 
{ value:'Central College', label: 'Central College'}, 
{ value:'Central Connecticut State University', label: 'Central Connecticut State University'}, 
{ value:'Central Methodist University', label: 'Central Methodist University'}, 
{ value:'Central Michigan University', label: 'Central Michigan University'}, 
{ value:'Central Penn College', label: 'Central Penn College'}, 
{ value:'Central State University', label: 'Central State University'}, 
{ value:'Central Washington University', label: 'Central Washington University'}, 
{ value:'Centralia College', label: 'Centralia College'}, 
{ value:'Centre College', label: 'Centre College'}, 
{ value:'Chadron State College', label: 'Chadron State College'}, 
{ value:'Chamberlain University', label: 'Chamberlain University'}, 
{ value:'Chaminade University of Honolulu', label: 'Chaminade University of Honolulu'}, 
{ value:'Champlain College', label: 'Champlain College'}, 
{ value:'Chapman University', label: 'Chapman University'}, 
{ value:'Charles R. Drew University of Medicine and Science', label: 'Charles R. Drew University of Medicine and Science'}, 
{ value:'Charleston Southern University', label: 'Charleston Southern University'}, 
{ value:'Chatham University', label: 'Chatham University'}, 
{ value:'Chestnut Hill College', label: 'Chestnut Hill College'}, 
{ value:'Cheyney University of Pennsylvania', label: 'Cheyney University of Pennsylvania'}, 
{ value:'Chicago State University', label: 'Chicago State University'}, 
{ value:'Chipola College', label: 'Chipola College'}, 
{ value:'Chowan University', label: 'Chowan University'}, 
{ value:'Christian Brothers University', label: 'Christian Brothers University'}, 
{ value:'Christopher Newport University', label: 'Christopher Newport University'}, 
{ value:'City University of Seattle', label: 'City University of Seattle'}, 
{ value:'Claflin University', label: 'Claflin University'}, 
{ value:'Claremont Graduate University', label: 'Claremont Graduate University'}, 
{ value:'Claremont McKenna College', label: 'Claremont McKenna College'}, 
{ value:'Clark Atlanta University', label: 'Clark Atlanta University'}, 
{ value:'Clark University', label: 'Clark University'}, 
{ value:'Clarke University', label: 'Clarke University'}, 
{ value:'Clarks Summit University', label: 'Clarks Summit University'}, 
{ value:'Clarkson College', label: 'Clarkson College'}, 
{ value:'Clarkson University', label: 'Clarkson University'}, 
{ value:'Clayton State University', label: 'Clayton State University'}, 
{ value:'Clear Creek Baptist Bible College', label: 'Clear Creek Baptist Bible College'}, 
{ value:'Cleary University', label: 'Cleary University'}, 
{ value:'Clemson University', label: 'Clemson University'}, 
{ value:'Cleveland Institute of Art', label: 'Cleveland Institute of Art'}, 
{ value:'Cleveland Institute of Music', label: 'Cleveland Institute of Music'}, 
{ value:'Cleveland State University', label: 'Cleveland State University'}, 
{ value:'Cleveland University-Kansas City', label: 'Cleveland University-Kansas City'}, 
{ value:'Coastal Carolina University', label: 'Coastal Carolina University'}, 
{ value:'Coe College', label: 'Coe College'}, 
{ value:'Coker University', label: 'Coker University'}, 
{ value:'Colby College', label: 'Colby College'}, 
{ value:'Colby-Sawyer College', label: 'Colby-Sawyer College'}, 
{ value:'Colgate University', label: 'Colgate University'}, 
{ value:'College for Creative Studies', label: 'College for Creative Studies'}, 
{ value:'College of Biblical Studies', label: 'College of Biblical Studies'}, 
{ value:'College of Central Florida', label: 'College of Central Florida'}, 
{ value:'College of Charleston', label: 'College of Charleston'}, 
{ value:'College of Coastal Georgia', label: 'College of Coastal Georgia'}, 
{ value:'College of Mount Saint Vincent', label: 'College of Mount Saint Vincent'}, 
{ value:'College of Our Lady of the Elms', label: 'College of Our Lady of the Elms'}, 
{ value:'College of Saint Benedict/Saint Johns University', label: 'College of Saint Benedict/Saint Johns University'}, 
{ value:'College of Saint Mary', label: 'College of Saint Mary'}, 
{ value:'College of Southern Nevada', label: 'College of Southern Nevada'}, 
{ value:'College of Staten Island', label: 'College of Staten Island'}, 
{ value:'College of the Atlantic', label: 'College of the Atlantic'}, 
{ value:'College of the Holy Cross', label: 'College of the Holy Cross'}, 
{ value:'College of the Ozarks', label: 'College of the Ozarks'}, 
{ value:'College of William & Mary', label: 'College of William & Mary'}, 
{ value:'Colorado Christian University', label: 'Colorado Christian University'}, 
{ value:'Colorado College', label: 'Colorado College'}, 
{ value:'Colorado Mesa University', label: 'Colorado Mesa University'}, 
{ value:'Colorado School of Mines', label: 'Colorado School of Mines'}, 
{ value:'Colorado State University', label: 'Colorado State University'}, 
{ value:'Colorado State University-Pueblo', label: 'Colorado State University-Pueblo'}, 
{ value:'Colorado Technical University', label: 'Colorado Technical University'}, 
{ value:'Columbia Basin College', label: 'Columbia Basin College'}, 
{ value:'Columbia College', label: 'Columbia College'}, 
{ value:'Columbia College Chicago', label: 'Columbia College Chicago'}, 
{ value:'Columbia College Hollywood', label: 'Columbia College Hollywood'}, 
{ value:'Columbia College, South Carolina', label: 'Columbia College, South Carolina'}, 
{ value:'Columbia International University', label: 'Columbia International University'}, 
{ value:'Columbia University in the City of New York', label: 'Columbia University in the City of New York'}, 
{ value:'Columbus College of Art and Design', label: 'Columbus College of Art and Design'}, 
{ value:'Columbus State University', label: 'Columbus State University'}, 
{ value:'Concord University', label: 'Concord University'}, 
{ value:'Concordia College', label: 'Concordia College'}, 
{ value:'Concordia University Ann Arbor', label: 'Concordia University Ann Arbor'}, 
{ value:'Concordia University Chicago', label: 'Concordia University Chicago'}, 
{ value:'Concordia University Irvine', label: 'Concordia University Irvine'}, 
{ value:'Concordia University Texas', label: 'Concordia University Texas'}, 
{ value:'Concordia University Wisconsin', label: 'Concordia University Wisconsin'}, 
{ value:'Concordia University, Nebraska', label: 'Concordia University, Nebraska'}, 
{ value:'Concordia University, St. Paul', label: 'Concordia University, St. Paul'}, 
{ value:'Connecticut College', label: 'Connecticut College'}, 
{ value:'Converse College', label: 'Converse College'}, 
{ value:'Conway School of Landscape Design', label: 'Conway School of Landscape Design'}, 
{ value:'Coppin State University', label: 'Coppin State University'}, 
{ value:'Corban University', label: 'Corban University'}, 
{ value:'Cornell College', label: 'Cornell College'}, 
{ value:'Cornell University', label: 'Cornell University'}, 
{ value:'Cornerstone University', label: 'Cornerstone University'}, 
{ value:'Cornish College of the Arts', label: 'Cornish College of the Arts'}, 
{ value:'Cottey College', label: 'Cottey College'}, 
{ value:'Covenant College', label: 'Covenant College'}, 
{ value:'Cox College', label: 'Cox College'}, 
{ value:'Cranbrook Academy of Art', label: 'Cranbrook Academy of Art'}, 
{ value:'Creighton University', label: 'Creighton University'}, 
{ value:'Criswell College', label: 'Criswell College'}, 
{ value:'Crowleys Ridge College', label: 'Crowleys Ridge College'}, 
{ value:'Crown College', label: 'Crown College'}, 
{ value:'Culver-Stockton College', label: 'Culver-Stockton College'}, 
{ value:'Cumberland University', label: 'Cumberland University'}, 
{ value:'CUNY School of Law', label: 'CUNY School of Law'}, 
{ value:'Curry College', label: 'Curry College'}, 
{ value:'Curtis Institute of Music', label: 'Curtis Institute of Music'}, 
{ value:'Daemen University', label: 'Daemen University'}, 
{ value:'Dakota State University', label: 'Dakota State University'}, 
{ value:'Dakota Wesleyan University', label: 'Dakota Wesleyan University'}, 
{ value:'Dallas Baptist University', label: 'Dallas Baptist University'}, 
{ value:'Dallas Christian College', label: 'Dallas Christian College'}, 
{ value:'Dalton State College', label: 'Dalton State College'}, 
{ value:'Dartmouth College', label: 'Dartmouth College'}, 
{ value:'Davenport University', label: 'Davenport University'}, 
{ value:'Davidson College', label: 'Davidson College'}, 
{ value:'Davis & Elkins College', label: 'Davis & Elkins College'}, 
{ value:'Davis College', label: 'Davis College'}, 
{ value:'Daytona State College', label: 'Daytona State College'}, 
{ value:'Dean College', label: 'Dean College'}, 
{ value:'Defiance College', label: 'Defiance College'}, 
{ value:'Delaware State University', label: 'Delaware State University'}, 
{ value:'Delaware Valley University', label: 'Delaware Valley University'}, 
{ value:'Delta State University', label: 'Delta State University'}, 
{ value:'Denison University', label: 'Denison University'}, 
{ value:'Denver College of Nursing', label: 'Denver College of Nursing'}, 
{ value:'DePaul University', label: 'DePaul University'}, 
{ value:'DePauw University', label: 'DePauw University'}, 
{ value:'Des Moines University', label: 'Des Moines University'}, 
{ value:'DeSales University', label: 'DeSales University'}, 
{ value:'DeVry University', label: 'DeVry University'}, 
{ value:'Dharma Realm Buddhist University', label: 'Dharma Realm Buddhist University'}, 
{ value:'Dickinson College', label: 'Dickinson College'}, 
{ value:'Dickinson State University', label: 'Dickinson State University'}, 
{ value:'Dillard University', label: 'Dillard University'}, 
{ value:'Doane University', label: 'Doane University'}, 
{ value:'Dominican School of Philosophy & Theology', label: 'Dominican School of Philosophy & Theology'}, 
{ value:'Dominican University', label: 'Dominican University'}, 
{ value:'Dominican University New York', label: 'Dominican University New York'}, 
{ value:'Dominican University of California', label: 'Dominican University of California'}, 
{ value:'Donnelly College', label: 'Donnelly College'}, 
{ value:'Dordt University', label: 'Dordt University'}, 
{ value:'Drake University', label: 'Drake University'}, 
{ value:'Drew University', label: 'Drew University'}, 
{ value:'Drexel University', label: 'Drexel University'}, 
{ value:'Drury University', label: 'Drury University'}, 
{ value:'Duke University', label: 'Duke University'}, 
{ value:'Dunwoody College of Technology', label: 'Dunwoody College of Technology'}, 
{ value:'Duquesne University', label: 'Duquesne University'}, 
{ value:'DYouville College', label: 'DYouville College'}, 
{ value:'Earlham College', label: 'Earlham College'}, 
{ value:'East Carolina University', label: 'East Carolina University'}, 
{ value:'East Central University', label: 'East Central University'}, 
{ value:'East Georgia State College', label: 'East Georgia State College'}, 
{ value:'East Stroudsburg University', label: 'East Stroudsburg University'}, 
{ value:'East Tennessee State University', label: 'East Tennessee State University'}, 
{ value:'East Texas Baptist University', label: 'East Texas Baptist University'}, 
{ value:'Eastern Connecticut State University', label: 'Eastern Connecticut State University'}, 
{ value:'Eastern Illinois University', label: 'Eastern Illinois University'}, 
{ value:'Eastern Kentucky University', label: 'Eastern Kentucky University'}, 
{ value:'Eastern Mennonite University', label: 'Eastern Mennonite University'}, 
{ value:'Eastern Michigan University', label: 'Eastern Michigan University'}, 
{ value:'Eastern Nazarene College', label: 'Eastern Nazarene College'}, 
{ value:'Eastern New Mexico University', label: 'Eastern New Mexico University'}, 
{ value:'Eastern Oregon University', label: 'Eastern Oregon University'}, 
{ value:'Eastern University', label: 'Eastern University'}, 
{ value:'Eastern Virginia Medical School', label: 'Eastern Virginia Medical School'}, 
{ value:'Eastern Washington University', label: 'Eastern Washington University'}, 
{ value:'East-West University', label: 'East-West University'}, 
{ value:'Ecclesia College', label: 'Ecclesia College'}, 
{ value:'Eckerd College', label: 'Eckerd College'}, 
{ value:'ECPI University', label: 'ECPI University'}, 
{ value:'Edgewood College', label: 'Edgewood College'}, 
{ value:'Edward Waters University', label: 'Edward Waters University'}, 
{ value:'Elizabeth City State University', label: 'Elizabeth City State University'}, 
{ value:'Elizabethtown College', label: 'Elizabethtown College'}, 
{ value:'Elmhurst University', label: 'Elmhurst University'}, 
{ value:'Elmira College', label: 'Elmira College'}, 
{ value:'Elon University', label: 'Elon University'}, 
{ value:'Embry-Riddle Aeronautical University', label: 'Embry-Riddle Aeronautical University'}, 
{ value:'Emerson College', label: 'Emerson College'}, 
{ value:'Emmanuel College', label: 'Emmanuel College'}, 
{ value:'Emmanuel College, Georgia', label: 'Emmanuel College, Georgia'}, 
{ value:'Emmaus Bible College', label: 'Emmaus Bible College'}, 
{ value:'Emory & Henry College', label: 'Emory & Henry College'}, 
{ value:'Emory University', label: 'Emory University'}, 
{ value:'Emporia State University', label: 'Emporia State University'}, 
{ value:'Endicott College', label: 'Endicott College'}, 
{ value:'Erskine College', label: 'Erskine College'}, 
{ value:'Eureka College', label: 'Eureka College'}, 
{ value:'Evangel University', label: 'Evangel University'}, 
{ value:'Everglades University', label: 'Everglades University'}, 
{ value:'Fairfield University', label: 'Fairfield University'}, 
{ value:'Fairleigh Dickinson University', label: 'Fairleigh Dickinson University'}, 
{ value:'Fairmont State University', label: 'Fairmont State University'}, 
{ value:'Faith Baptist Bible College and Theological Seminary', label: 'Faith Baptist Bible College and Theological Seminary'}, 
{ value:'Farmingdale State College', label: 'Farmingdale State College'}, 
{ value:'Fashion Institute of Technology', label: 'Fashion Institute of Technology'}, 
{ value:'Faulkner University', label: 'Faulkner University'}, 
{ value:'Fayetteville State University', label: 'Fayetteville State University'}, 
{ value:'Felician University', label: 'Felician University'}, 
{ value:'Ferris State University', label: 'Ferris State University'}, 
{ value:'Ferrum College', label: 'Ferrum College'}, 
{ value:'Fielding Graduate University', label: 'Fielding Graduate University'}, 
{ value:'Finlandia University', label: 'Finlandia University'}, 
{ value:'Fisher College', label: 'Fisher College'}, 
{ value:'Fisk University', label: 'Fisk University'}, 
{ value:'Fitchburg State University', label: 'Fitchburg State University'}, 
{ value:'Five Towns College', label: 'Five Towns College'}, 
{ value:'Flagler College', label: 'Flagler College'}, 
{ value:'Florida Agricultural and Mechanical University', label: 'Florida Agricultural and Mechanical University'}, 
{ value:'Florida Atlantic University', label: 'Florida Atlantic University'}, 
{ value:'Florida College', label: 'Florida College'}, 
{ value:'Florida Gateway College', label: 'Florida Gateway College'}, 
{ value:'Florida Gulf Coast University', label: 'Florida Gulf Coast University'}, 
{ value:'Florida Institute of Technology', label: 'Florida Institute of Technology'}, 
{ value:'Florida International University', label: 'Florida International University'}, 
{ value:'Florida Memorial University', label: 'Florida Memorial University'}, 
{ value:'Florida National University', label: 'Florida National University'}, 
{ value:'Florida Southern College', label: 'Florida Southern College'}, 
{ value:'Florida SouthWestern State College', label: 'Florida SouthWestern State College'}, 
{ value:'Florida State College at Jacksonville', label: 'Florida State College at Jacksonville'}, 
{ value:'Florida State University', label: 'Florida State University'}, 
{ value:'Fontbonne University', label: 'Fontbonne University'}, 
{ value:'Fordham University', label: 'Fordham University'}, 
{ value:'Fort Hays State University', label: 'Fort Hays State University'}, 
{ value:'Fort Lewis College', label: 'Fort Lewis College'}, 
{ value:'Fort Valley State University', label: 'Fort Valley State University'}, 
{ value:'Framingham State University', label: 'Framingham State University'}, 
{ value:'Francis Marion University', label: 'Francis Marion University'}, 
{ value:'Franciscan Missionaries of Our Lady University', label: 'Franciscan Missionaries of Our Lady University'}, 
{ value:'Franciscan University of Steubenville', label: 'Franciscan University of Steubenville'}, 
{ value:'Franklin and Marshall College', label: 'Franklin and Marshall College'}, 
{ value:'Franklin College', label: 'Franklin College'}, 
{ value:'Franklin Pierce University', label: 'Franklin Pierce University'}, 
{ value:'Franklin University', label: 'Franklin University'}, 
{ value:'Franklin W. Olin College of Engineering', label: 'Franklin W. Olin College of Engineering'}, 
{ value:'Freed-Hardeman University', label: 'Freed-Hardeman University'}, 
{ value:'Fresno Pacific University', label: 'Fresno Pacific University'}, 
{ value:'Friends University', label: 'Friends University'}, 
{ value:'Frostburg State University', label: 'Frostburg State University'}, 
{ value:'Furman University', label: 'Furman University'}, 
{ value:'Gallaudet University', label: 'Gallaudet University'}, 
{ value:'Gannon University', label: 'Gannon University'}, 
{ value:'Gardner-Webb University', label: 'Gardner-Webb University'}, 
{ value:'Geisinger Commonwealth School of Medicine', label: 'Geisinger Commonwealth School of Medicine'}, 
{ value:'Geneva College', label: 'Geneva College'}, 
{ value:'George Fox University', label: 'George Fox University'}, 
{ value:'George Mason University', label: 'George Mason University'}, 
{ value:'George Washington University', label: 'George Washington University'}, 
{ value:'Georgetown College', label: 'Georgetown College'}, 
{ value:'Georgetown University', label: 'Georgetown University'}, 
{ value:'Georgia College and State University', label: 'Georgia College and State University'}, 
{ value:'Georgia Gwinnett College', label: 'Georgia Gwinnett College'}, 
{ value:'Georgia Institute of Technology', label: 'Georgia Institute of Technology'}, 
{ value:'Georgia Southern University', label: 'Georgia Southern University'}, 
{ value:'Georgia Southwestern State University', label: 'Georgia Southwestern State University'}, 
{ value:'Georgia State University', label: 'Georgia State University'}, 
{ value:'Georgian Court University', label: 'Georgian Court University'}, 
{ value:'Gettysburg College', label: 'Gettysburg College'}, 
{ value:'Glenville State University', label: 'Glenville State University'}, 
{ value:'Goddard College', label: 'Goddard College'}, 
{ value:'Gods Bible School and College', label: 'Gods Bible School and College'}, 
{ value:'Golden Gate University', label: 'Golden Gate University'}, 
{ value:'Goldey-Beacom College', label: 'Goldey-Beacom College'}, 
{ value:'Goldfarb School of Nursing at Barnes-Jewish College', label: 'Goldfarb School of Nursing at Barnes-Jewish College'}, 
{ value:'Gonzaga University', label: 'Gonzaga University'}, 
{ value:'Good Samaritan College of Nursing and Health Science', label: 'Good Samaritan College of Nursing and Health Science'}, 
{ value:'Goodwin University', label: 'Goodwin University'}, 
{ value:'Gordon College', label: 'Gordon College'}, 
{ value:'Gordon State College', label: 'Gordon State College'}, 
{ value:'Goshen College', label: 'Goshen College'}, 
{ value:'Goucher College', label: 'Goucher College'}, 
{ value:'Governors State University', label: 'Governors State University'}, 
{ value:'Grace Christian University', label: 'Grace Christian University'}, 
{ value:'Grace College', label: 'Grace College'}, 
{ value:'Graceland University', label: 'Graceland University'}, 
{ value:'Grambling State University', label: 'Grambling State University'}, 
{ value:'Grand Canyon University', label: 'Grand Canyon University'}, 
{ value:'Grand Valley State University', label: 'Grand Valley State University'}, 
{ value:'Grand View University', label: 'Grand View University'}, 
{ value:'Gratz College', label: 'Gratz College'}, 
{ value:'Great Basin College', label: 'Great Basin College'}, 
{ value:'Great Lakes Christian College', label: 'Great Lakes Christian College'}, 
{ value:'Greensboro College', label: 'Greensboro College'}, 
{ value:'Greenville University', label: 'Greenville University'}, 
{ value:'Grinnell College', label: 'Grinnell College'}, 
{ value:'Grove City College', label: 'Grove City College'}, 
{ value:'Guilford College', label: 'Guilford College'}, 
{ value:'Gulf Coast State College', label: 'Gulf Coast State College'}, 
{ value:'Gustavus Adolphus College', label: 'Gustavus Adolphus College'}, 
{ value:'Gwynedd Mercy University', label: 'Gwynedd Mercy University'}, 
{ value:'Hamilton College', label: 'Hamilton College'}, 
{ value:'Hamline University', label: 'Hamline University'}, 
{ value:'Hampden-Sydney College', label: 'Hampden-Sydney College'}, 
{ value:'Hampshire College', label: 'Hampshire College'}, 
{ value:'Hampton University', label: 'Hampton University'}, 
{ value:'Hannibal-LaGrange University', label: 'Hannibal-LaGrange University'}, 
{ value:'Hanover College', label: 'Hanover College'}, 
{ value:'Harding University', label: 'Harding University'}, 
{ value:'Hardin-Simmons University', label: 'Hardin-Simmons University'}, 
{ value:'Harrisburg University of Science and Technology', label: 'Harrisburg University of Science and Technology'}, 
{ value:'Harris-Stowe State University', label: 'Harris-Stowe State University'}, 
{ value:'Hartwick College', label: 'Hartwick College'}, 
{ value:'Harvard University', label: 'Harvard University'}, 
{ value:'Harvey Mudd College', label: 'Harvey Mudd College'}, 
{ value:'Hastings College', label: 'Hastings College'}, 
{ value:'Haverford College', label: 'Haverford College'}, 
{ value:'Hawaii Pacific University', label: 'Hawaii Pacific University'}, 
{ value:'Hebrew College', label: 'Hebrew College'}, 
{ value:'Heidelberg University', label: 'Heidelberg University'}, 
{ value:'Helene Fuld College of Nursing', label: 'Helene Fuld College of Nursing'}, 
{ value:'Henderson State University', label: 'Henderson State University'}, 
{ value:'Hendrix College', label: 'Hendrix College'}, 
{ value:'Heritage Christian University', label: 'Heritage Christian University'}, 
{ value:'Heritage University', label: 'Heritage University'}, 
{ value:'Herzing University', label: 'Herzing University'}, 
{ value:'High Point University', label: 'High Point University'}, 
{ value:'Hilbert College', label: 'Hilbert College'}, 
{ value:'Hillsdale College', label: 'Hillsdale College'}, 
{ value:'Hiram College', label: 'Hiram College'}, 
{ value:'Hobart and William Smith Colleges', label: 'Hobart and William Smith Colleges'}, 
{ value:'Hodges University', label: 'Hodges University'}, 
{ value:'Hofstra University', label: 'Hofstra University'}, 
{ value:'Hollins University', label: 'Hollins University'}, 
{ value:'Holy Apostles College and Seminary', label: 'Holy Apostles College and Seminary'}, 
{ value:'Holy Cross College', label: 'Holy Cross College'}, 
{ value:'Holy Family University', label: 'Holy Family University'}, 
{ value:'Holy Names University', label: 'Holy Names University'}, 
{ value:'Hood College', label: 'Hood College'}, 
{ value:'Hope College', label: 'Hope College'}, 
{ value:'Hope International University', label: 'Hope International University'}, 
{ value:'Houghton University', label: 'Houghton University'}, 
{ value:'Houston Baptist University', label: 'Houston Baptist University'}, 
{ value:'Howard Payne University', label: 'Howard Payne University'}, 
{ value:'Howard University', label: 'Howard University'}, 
{ value:'Hult International Business School', label: 'Hult International Business School'}, 
{ value:'Humboldt State University', label: 'Humboldt State University'}, 
{ value:'Humphreys University', label: 'Humphreys University'}, 
{ value:'Hunter College, CUNY', label: 'Hunter College, CUNY'}, 
{ value:'Huntingdon College', label: 'Huntingdon College'}, 
{ value:'Huntington University', label: 'Huntington University'}, 
{ value:'Husson University', label: 'Husson University'}, 
{ value:'Icahn School of Medicine at Mount Sinai', label: 'Icahn School of Medicine at Mount Sinai'}, 
{ value:'Idaho State University', label: 'Idaho State University'}, 
{ value:'Illinois College', label: 'Illinois College'}, 
{ value:'Illinois College of Optometry', label: 'Illinois College of Optometry'}, 
{ value:'Illinois Institute of Technology', label: 'Illinois Institute of Technology'}, 
{ value:'Illinois State University', label: 'Illinois State University'}, 
{ value:'Illinois Wesleyan University', label: 'Illinois Wesleyan University'}, 
{ value:'Immaculata University', label: 'Immaculata University'}, 
{ value:'Indian River State College', label: 'Indian River State College'}, 
{ value:'Indiana Institute of Technology', label: 'Indiana Institute of Technology'}, 
{ value:'Indiana State University', label: 'Indiana State University'}, 
{ value:'Indiana University Bloomington', label: 'Indiana University Bloomington'}, 
{ value:'Indiana University East', label: 'Indiana University East'}, 
{ value:'Indiana University Fort Wayne', label: 'Indiana University Fort Wayne'}, 
{ value:'Indiana University Kokomo', label: 'Indiana University Kokomo'}, 
{ value:'Indiana University Northwest', label: 'Indiana University Northwest'}, 
{ value:'Indiana University of Pennsylvania', label: 'Indiana University of Pennsylvania'}, 
{ value:'Indiana University South Bend', label: 'Indiana University South Bend'}, 
{ value:'Indiana University Southeast', label: 'Indiana University Southeast'}, 
{ value:'Indiana University-Purdue University Indianapolis', label: 'Indiana University-Purdue University Indianapolis'}, 
{ value:'Indiana Wesleyan University', label: 'Indiana Wesleyan University'}, 
{ value:'Iona University', label: 'Iona University'}, 
{ value:'Iowa State University', label: 'Iowa State University'}, 
{ value:'Irell and Manella Graduate School of Biological Sciences', label: 'Irell and Manella Graduate School of Biological Sciences'}, 
{ value:'Ithaca College', label: 'Ithaca College'}, 
{ value:'Jackson State University', label: 'Jackson State University'}, 
{ value:'Jacksonville State University', label: 'Jacksonville State University'}, 
{ value:'Jacksonville University', label: 'Jacksonville University'}, 
{ value:'James Madison University', label: 'James Madison University'}, 
{ value:'Jamestown Business College', label: 'Jamestown Business College'}, 
{ value:'Jarvis Christian University', label: 'Jarvis Christian University'}, 
{ value:'Jessup University', label: 'Jessup University'}, 
{ value:'John Brown University', label: 'John Brown University'}, 
{ value:'John Carroll University', label: 'John Carroll University'}, 
{ value:'John F. Kennedy University', label: 'John F. Kennedy University'}, 
{ value:'John Jay College of Criminal Justice', label: 'John Jay College of Criminal Justice'}, 
{ value:'John Paul the Great Catholic University', label: 'John Paul the Great Catholic University'}, 
{ value:'Johns Hopkins University', label: 'Johns Hopkins University'}, 
{ value:'Johnson and Wales University', label: 'Johnson and Wales University'}, 
{ value:'Johnson C. Smith University', label: 'Johnson C. Smith University'}, 
{ value:'Johnson University', label: 'Johnson University'}, 
{ value:'Judson University', label: 'Judson University'}, 
{ value:'Juniata College', label: 'Juniata College'}, 
{ value:'Kalamazoo College', label: 'Kalamazoo College'}, 
{ value:'Kansas City Art Institute', label: 'Kansas City Art Institute'}, 
{ value:'Kansas City University', label: 'Kansas City University'}, 
{ value:'Kansas State University', label: 'Kansas State University'}, 
{ value:'Kansas Wesleyan University', label: 'Kansas Wesleyan University'}, 
{ value:'Kean University', label: 'Kean University'}, 
{ value:'Keck Graduate Institute', label: 'Keck Graduate Institute'}, 
{ value:'Keene State College', label: 'Keene State College'}, 
{ value:'Keiser University', label: 'Keiser University'}, 
{ value:'Kennesaw State University', label: 'Kennesaw State University'}, 
{ value:'Kent State University', label: 'Kent State University'}, 
{ value:'Kentucky Christian University', label: 'Kentucky Christian University'}, 
{ value:'Kentucky State University', label: 'Kentucky State University'}, 
{ value:'Kentucky Wesleyan College', label: 'Kentucky Wesleyan College'}, 
{ value:'Kenyon College', label: 'Kenyon College'}, 
{ value:'Kettering College', label: 'Kettering College'}, 
{ value:'Kettering University', label: 'Kettering University'}, 
{ value:'Keuka College', label: 'Keuka College'}, 
{ value:'Keystone College', label: 'Keystone College'}, 
{ value:'King University', label: 'King University'}, 
{ value:'Kings College', label: 'Kings College'}, 
{ value:'Knox College', label: 'Knox College'}, 
{ value:'Kutztown University of Pennsylvania', label: 'Kutztown University of Pennsylvania'}, 
{ value:'Kuyper College', label: 'Kuyper College'}, 
{ value:'La Roche University', label: 'La Roche University'}, 
{ value:'La Salle University', label: 'La Salle University'}, 
{ value:'La Sierra University', label: 'La Sierra University'}, 
{ value:'Labouré College', label: 'Labouré College'}, 
{ value:'Lafayette College', label: 'Lafayette College'}, 
{ value:'LaGrange College', label: 'LaGrange College'}, 
{ value:'Laguna College of Art and Design', label: 'Laguna College of Art and Design'}, 
{ value:'Lake Erie College', label: 'Lake Erie College'}, 
{ value:'Lake Forest College', label: 'Lake Forest College'}, 
{ value:'Lake Forest Graduate School of Management', label: 'Lake Forest Graduate School of Management'}, 
{ value:'Lake Superior State University', label: 'Lake Superior State University'}, 
{ value:'Lake Washington Institute of Technology', label: 'Lake Washington Institute of Technology'}, 
{ value:'Lakeland University', label: 'Lakeland University'}, 
{ value:'Lakeview College of Nursing', label: 'Lakeview College of Nursing'}, 
{ value:'Lamar University', label: 'Lamar University'}, 
{ value:'Lancaster Bible College', label: 'Lancaster Bible College'}, 
{ value:'Lander University', label: 'Lander University'}, 
{ value:'Landmark College', label: 'Landmark College'}, 
{ value:'Lane College', label: 'Lane College'}, 
{ value:'Langston University', label: 'Langston University'}, 
{ value:'Lasell University', label: 'Lasell University'}, 
{ value:'Lawrence Technological University', label: 'Lawrence Technological University'}, 
{ value:'Lawrence University', label: 'Lawrence University'}, 
{ value:'Le Moyne College', label: 'Le Moyne College'}, 
{ value:'Lebanon Valley College', label: 'Lebanon Valley College'}, 
{ value:'Lee University', label: 'Lee University'}, 
{ value:'Lees-McRae College', label: 'Lees-McRae College'}, 
{ value:'Lehigh University', label: 'Lehigh University'}, 
{ value:'Lehman College, CUNY', label: 'Lehman College, CUNY'}, 
{ value:'LeMoyne-Owen College', label: 'LeMoyne-Owen College'}, 
{ value:'Lenoir-Rhyne University', label: 'Lenoir-Rhyne University'}, 
{ value:'Lesley University', label: 'Lesley University'}, 
{ value:'LeTourneau University', label: 'LeTourneau University'}, 
{ value:'Lewis & Clark College', label: 'Lewis & Clark College'}, 
{ value:'Lewis University', label: 'Lewis University'}, 
{ value:'Lewis-Clark State College', label: 'Lewis-Clark State College'}, 
{ value:'Liberty University', label: 'Liberty University'}, 
{ value:'Life Pacific University', label: 'Life Pacific University'}, 
{ value:'Life University', label: 'Life University'}, 
{ value:'LIM College', label: 'LIM College'}, 
{ value:'Limestone University', label: 'Limestone University'}, 
{ value:'Lincoln Christian University', label: 'Lincoln Christian University'}, 
{ value:'Lincoln College', label: 'Lincoln College'}, 
{ value:'Lincoln Memorial University', label: 'Lincoln Memorial University'}, 
{ value:'Lincoln University', label: 'Lincoln University'}, 
{ value:'Lincoln University, Missouri', label: 'Lincoln University, Missouri'}, 
{ value:'Lindenwood University', label: 'Lindenwood University'}, 
{ value:'Lindsey Wilson College', label: 'Lindsey Wilson College'}, 
{ value:'Linfield University', label: 'Linfield University'}, 
{ value:'Lipscomb University', label: 'Lipscomb University'}, 
{ value:'Livingstone College', label: 'Livingstone College'}, 
{ value:'Lock Haven University', label: 'Lock Haven University'}, 
{ value:'Logan University', label: 'Logan University'}, 
{ value:'Loma Linda University', label: 'Loma Linda University'}, 
{ value:'Long Island University', label: 'Long Island University'}, 
{ value:'Longwood University', label: 'Longwood University'}, 
{ value:'Loras College', label: 'Loras College'}, 
{ value:'Louisiana Christian University', label: 'Louisiana Christian University'}, 
{ value:'Louisiana State University', label: 'Louisiana State University'}, 
{ value:'Louisiana State University Health Sciences Center', label: 'Louisiana State University Health Sciences Center'}, 
{ value:'Louisiana State University Health Sciences Center-Shreveport', label: 'Louisiana State University Health Sciences Center-Shreveport'}, 
{ value:'Louisiana State University in Shreveport', label: 'Louisiana State University in Shreveport'}, 
{ value:'Louisiana State University of Alexandria', label: 'Louisiana State University of Alexandria'}, 
{ value:'Louisiana Tech University', label: 'Louisiana Tech University'}, 
{ value:'Lourdes University', label: 'Lourdes University'}, 
{ value:'Loyola Marymount University', label: 'Loyola Marymount University'}, 
{ value:'Loyola University Chicago', label: 'Loyola University Chicago'}, 
{ value:'Loyola University Maryland', label: 'Loyola University Maryland'}, 
{ value:'Loyola University New Orleans', label: 'Loyola University New Orleans'}, 
{ value:'Lubbock Christian University', label: 'Lubbock Christian University'}, 
{ value:'Luther College', label: 'Luther College'}, 
{ value:'Lycoming College', label: 'Lycoming College'}, 
{ value:'Lynn University', label: 'Lynn University'}, 
{ value:'Lyon College', label: 'Lyon College'}, 
{ value:'Macalester College', label: 'Macalester College'}, 
{ value:'Madonna University', label: 'Madonna University'}, 
{ value:'Magdalen College of the Liberal Arts', label: 'Magdalen College of the Liberal Arts'}, 
{ value:'Maharishi International University', label: 'Maharishi International University'}, 
{ value:'Maine College of Art', label: 'Maine College of Art'}, 
{ value:'Maine Maritime Academy', label: 'Maine Maritime Academy'}, 
{ value:'Malone University', label: 'Malone University'}, 
{ value:'Manchester University', label: 'Manchester University'}, 
{ value:'Manhattan Christian College', label: 'Manhattan Christian College'}, 
{ value:'Manhattan College', label: 'Manhattan College'}, 
{ value:'Manhattan School of Music', label: 'Manhattan School of Music'}, 
{ value:'Manhattanville College', label: 'Manhattanville College'}, 
{ value:'Mansfield University of Pennsylvania', label: 'Mansfield University of Pennsylvania'}, 
{ value:'Maranatha Baptist University', label: 'Maranatha Baptist University'}, 
{ value:'Maria College', label: 'Maria College'}, 
{ value:'Marian University', label: 'Marian University'}, 
{ value:'Marian University, Wisconsin', label: 'Marian University, Wisconsin'}, 
{ value:'Marietta College', label: 'Marietta College'}, 
{ value:'Marist College', label: 'Marist College'}, 
{ value:'Marquette University', label: 'Marquette University'}, 
{ value:'Mars Hill University', label: 'Mars Hill University'}, 
{ value:'Marshall B. Ketchum University', label: 'Marshall B. Ketchum University'}, 
{ value:'Marshall University', label: 'Marshall University'}, 
{ value:'Martin Luther College', label: 'Martin Luther College'}, 
{ value:'Martin University', label: 'Martin University'}, 
{ value:'Mary Baldwin University', label: 'Mary Baldwin University'}, 
{ value:'Maryland Institute College of Art', label: 'Maryland Institute College of Art'}, 
{ value:'Maryland University of Integrative Health', label: 'Maryland University of Integrative Health'}, 
{ value:'Marymount Manhattan College', label: 'Marymount Manhattan College'}, 
{ value:'Marymount University', label: 'Marymount University'}, 
{ value:'Maryville College', label: 'Maryville College'}, 
{ value:'Maryville University', label: 'Maryville University'}, 
{ value:'Marywood University', label: 'Marywood University'}, 
{ value:'Massachusetts College of Art and Design', label: 'Massachusetts College of Art and Design'}, 
{ value:'Massachusetts College of Liberal Arts', label: 'Massachusetts College of Liberal Arts'}, 
{ value:'Massachusetts Institute of Technology', label: 'Massachusetts Institute of Technology'}, 
{ value:'Massachusetts Maritime Academy', label: 'Massachusetts Maritime Academy'}, 
{ value:'Massachusetts School of Law', label: 'Massachusetts School of Law'}, 
{ value:'Mayo Clinic College of Medicine and Science', label: 'Mayo Clinic College of Medicine and Science'}, 
{ value:'Mayville State University', label: 'Mayville State University'}, 
{ value:'McDaniel College', label: 'McDaniel College'}, 
{ value:'McKendree University', label: 'McKendree University'}, 
{ value:'McMurry University', label: 'McMurry University'}, 
{ value:'McNeese State University', label: 'McNeese State University'}, 
{ value:'McPherson College', label: 'McPherson College'}, 
{ value:'MCPHS University', label: 'MCPHS University'}, 
{ value:'Medaille University', label: 'Medaille University'}, 
{ value:'Medgar Evers College', label: 'Medgar Evers College'}, 
{ value:'Medical College of Wisconsin', label: 'Medical College of Wisconsin'}, 
{ value:'Medical University of South Carolina', label: 'Medical University of South Carolina'}, 
{ value:'Meharry Medical College', label: 'Meharry Medical College'}, 
{ value:'Menlo College', label: 'Menlo College'}, 
{ value:'Mercer University', label: 'Mercer University'}, 
{ value:'Mercy College of Health Sciences', label: 'Mercy College of Health Sciences'}, 
{ value:'Mercy College of Ohio', label: 'Mercy College of Ohio'}, 
{ value:'Mercy University', label: 'Mercy University'}, 
{ value:'Mercyhurst University', label: 'Mercyhurst University'}, 
{ value:'Meredith College', label: 'Meredith College'}, 
{ value:'Merrimack College', label: 'Merrimack College'}, 
{ value:'Messiah University', label: 'Messiah University'}, 
{ value:'Methodist College', label: 'Methodist College'}, 
{ value:'Methodist University', label: 'Methodist University'}, 
{ value:'Metropolitan College of New York', label: 'Metropolitan College of New York'}, 
{ value:'Metropolitan State University', label: 'Metropolitan State University'}, 
{ value:'Metropolitan State University of Denver', label: 'Metropolitan State University of Denver'}, 
{ value:'MGH Institute of Health Professions', label: 'MGH Institute of Health Professions'}, 
{ value:'Miami Dade College', label: 'Miami Dade College'}, 
{ value:'Miami University', label: 'Miami University'}, 
{ value:'Michigan School of Psychology', label: 'Michigan School of Psychology'}, 
{ value:'Michigan State University', label: 'Michigan State University'}, 
{ value:'Michigan Technological University', label: 'Michigan Technological University'}, 
{ value:'Mid-America Christian University', label: 'Mid-America Christian University'}, 
{ value:'MidAmerica Nazarene University', label: 'MidAmerica Nazarene University'}, 
{ value:'Mid-Atlantic Christian University', label: 'Mid-Atlantic Christian University'}, 
{ value:'Middle Georgia State University', label: 'Middle Georgia State University'}, 
{ value:'Middle Tennessee School of Anesthesia', label: 'Middle Tennessee School of Anesthesia'}, 
{ value:'Middle Tennessee State University', label: 'Middle Tennessee State University'}, 
{ value:'Middlebury College', label: 'Middlebury College'}, 
{ value:'Midland College', label: 'Midland College'}, 
{ value:'Midland University', label: 'Midland University'}, 
{ value:'Midway University', label: 'Midway University'}, 
{ value:'Midwestern State University', label: 'Midwestern State University'}, 
{ value:'Midwestern University', label: 'Midwestern University'}, 
{ value:'Miles College', label: 'Miles College'}, 
{ value:'Millersville University of Pennsylvania', label: 'Millersville University of Pennsylvania'}, 
{ value:'Milligan University', label: 'Milligan University'}, 
{ value:'Millikin University', label: 'Millikin University'}, 
{ value:'Millsaps College', label: 'Millsaps College'}, 
{ value:'Milwaukee Institute of Art & Design', label: 'Milwaukee Institute of Art & Design'}, 
{ value:'Milwaukee School of Engineering', label: 'Milwaukee School of Engineering'}, 
{ value:'Minneapolis College of Art and Design', label: 'Minneapolis College of Art and Design'}, 
{ value:'Minnesota State University Moorhead', label: 'Minnesota State University Moorhead'}, 
{ value:'Minnesota State University, Mankato', label: 'Minnesota State University, Mankato'}, 
{ value:'Minot State University', label: 'Minot State University'}, 
{ value:'Misericordia University', label: 'Misericordia University'}, 
{ value:'Mississippi College', label: 'Mississippi College'}, 
{ value:'Mississippi State University', label: 'Mississippi State University'}, 
{ value:'Mississippi University for Women', label: 'Mississippi University for Women'}, 
{ value:'Mississippi Valley State University', label: 'Mississippi Valley State University'}, 
{ value:'Missouri Baptist University', label: 'Missouri Baptist University'}, 
{ value:'Missouri Southern State University', label: 'Missouri Southern State University'}, 
{ value:'Missouri State University', label: 'Missouri State University'}, 
{ value:'Missouri University of Science and Technology', label: 'Missouri University of Science and Technology'}, 
{ value:'Missouri Valley College', label: 'Missouri Valley College'}, 
{ value:'Missouri Western State University', label: 'Missouri Western State University'}, 
{ value:'Mitchell College', label: 'Mitchell College'}, 
{ value:'Mitchell Hamline School of Law', label: 'Mitchell Hamline School of Law'}, 
{ value:'Molloy University', label: 'Molloy University'}, 
{ value:'Monmouth College', label: 'Monmouth College'}, 
{ value:'Monmouth University', label: 'Monmouth University'}, 
{ value:'Monroe College', label: 'Monroe College'}, 
{ value:'Montana State University', label: 'Montana State University'}, 
{ value:'Montana State University Billings', label: 'Montana State University Billings'}, 
{ value:'Montana State University-Northern', label: 'Montana State University-Northern'}, 
{ value:'Montana Tech', label: 'Montana Tech'}, 
{ value:'Montclair State University', label: 'Montclair State University'}, 
{ value:'Montreat College', label: 'Montreat College'}, 
{ value:'Montserrat College of Art', label: 'Montserrat College of Art'}, 
{ value:'Moody Bible Institute', label: 'Moody Bible Institute'}, 
{ value:'Moore College of Art and Design', label: 'Moore College of Art and Design'}, 
{ value:'Moravian University', label: 'Moravian University'}, 
{ value:'Morehead State University', label: 'Morehead State University'}, 
{ value:'Morehouse College', label: 'Morehouse College'}, 
{ value:'Morehouse School of Medicine', label: 'Morehouse School of Medicine'}, 
{ value:'Morgan State University', label: 'Morgan State University'}, 
{ value:'Morningside University', label: 'Morningside University'}, 
{ value:'Morris College', label: 'Morris College'}, 
{ value:'Morrisville State College', label: 'Morrisville State College'}, 
{ value:'Mount Aloysius College', label: 'Mount Aloysius College'}, 
{ value:'Mount Carmel College of Nursing', label: 'Mount Carmel College of Nursing'}, 
{ value:'Mount Holyoke College', label: 'Mount Holyoke College'}, 
{ value:'Mount Marty University', label: 'Mount Marty University'}, 
{ value:'Mount Mary University', label: 'Mount Mary University'}, 
{ value:'Mount Mercy University', label: 'Mount Mercy University'}, 
{ value:'Mount Saint Mary College', label: 'Mount Saint Mary College'}, 
{ value:'Mount Saint Marys University', label: 'Mount Saint Marys University'}, 
{ value:'Mount St. Joseph University', label: 'Mount St. Joseph University'}, 
{ value:'Mount St. Marys University', label: 'Mount St. Marys University'}, 
{ value:'Mount Vernon Nazarene University', label: 'Mount Vernon Nazarene University'}, 
{ value:'Muhlenberg College', label: 'Muhlenberg College'}, 
{ value:'Multnomah University', label: 'Multnomah University'}, 
{ value:'Murray State University', label: 'Murray State University'}, 
{ value:'Muskingum University', label: 'Muskingum University'}, 
{ value:'Naropa University', label: 'Naropa University'}, 
{ value:'National American University', label: 'National American University'}, 
{ value:'National Defense University', label: 'National Defense University'}, 
{ value:'National Louis University', label: 'National Louis University'}, 
{ value:'National University', label: 'National University'}, 
{ value:'National University of Health Sciences', label: 'National University of Health Sciences'}, 
{ value:'Naval Postgraduate School', label: 'Naval Postgraduate School'}, 
{ value:'Nazarene Bible College', label: 'Nazarene Bible College'}, 
{ value:'Nazareth University', label: 'Nazareth University'}, 
{ value:'Nebraska Methodist College', label: 'Nebraska Methodist College'}, 
{ value:'Nebraska Wesleyan University', label: 'Nebraska Wesleyan University'}, 
{ value:'Neumann University', label: 'Neumann University'}, 
{ value:'Nevada State University', label: 'Nevada State University'}, 
{ value:'New College of Florida', label: 'New College of Florida'}, 
{ value:'New England College', label: 'New England College'}, 
{ value:'New England College of Optometry', label: 'New England College of Optometry'}, 
{ value:'New England Conservatory', label: 'New England Conservatory'}, 
{ value:'New England Institute of Technology', label: 'New England Institute of Technology'}, 
{ value:'New England Law | Boston', label: 'New England Law | Boston'}, 
{ value:'New Hope Christian College', label: 'New Hope Christian College'}, 
{ value:'New Jersey City University', label: 'New Jersey City University'}, 
{ value:'New Jersey Institute of Technology', label: 'New Jersey Institute of Technology'}, 
{ value:'New Mexico Highlands University', label: 'New Mexico Highlands University'}, 
{ value:'New Mexico Institute of Mining and Technology', label: 'New Mexico Institute of Mining and Technology'}, 
{ value:'New Mexico State University', label: 'New Mexico State University'}, 
{ value:'New York Academy of Art', label: 'New York Academy of Art'}, 
{ value:'New York City College of Technology, CUNY', label: 'New York City College of Technology, CUNY'}, 
{ value:'New York College of Podiatric Medicine', label: 'New York College of Podiatric Medicine'}, 
{ value:'New York Institute of Technology', label: 'New York Institute of Technology'}, 
{ value:'New York Law School', label: 'New York Law School'}, 
{ value:'New York Medical College', label: 'New York Medical College'}, 
{ value:'New York School of Interior Design', label: 'New York School of Interior Design'}, 
{ value:'New York University', label: 'New York University'}, 
{ value:'Newberry College', label: 'Newberry College'}, 
{ value:'Newman University', label: 'Newman University'}, 
{ value:'NewSchool of Architecture and Design', label: 'NewSchool of Architecture and Design'}, 
{ value:'Niagara University', label: 'Niagara University'}, 
{ value:'Nicholls State University', label: 'Nicholls State University'}, 
{ value:'Nichols College', label: 'Nichols College'}, 
{ value:'Norfolk State University', label: 'Norfolk State University'}, 
{ value:'North Carolina Agricultural and Technical State University', label: 'North Carolina Agricultural and Technical State University'}, 
{ value:'North Carolina Central University', label: 'North Carolina Central University'}, 
{ value:'North Carolina State University', label: 'North Carolina State University'}, 
{ value:'North Carolina Wesleyan University', label: 'North Carolina Wesleyan University'}, 
{ value:'North Central College', label: 'North Central College'}, 
{ value:'North Central University', label: 'North Central University'}, 
{ value:'North Dakota State University', label: 'North Dakota State University'}, 
{ value:'North Greenville University', label: 'North Greenville University'}, 
{ value:'North Park University', label: 'North Park University'}, 
{ value:'Northeast Ohio Medical University', label: 'Northeast Ohio Medical University'}, 
{ value:'Northeastern Illinois University', label: 'Northeastern Illinois University'}, 
{ value:'Northeastern State University', label: 'Northeastern State University'}, 
{ value:'Northeastern University', label: 'Northeastern University'}, 
{ value:'Northern Arizona University', label: 'Northern Arizona University'}, 
{ value:'Northern Illinois University', label: 'Northern Illinois University'}, 
{ value:'Northern Kentucky University', label: 'Northern Kentucky University'}, 
{ value:'Northern Michigan University', label: 'Northern Michigan University'}, 
{ value:'Northern New Mexico College', label: 'Northern New Mexico College'}, 
{ value:'Northern State University', label: 'Northern State University'}, 
{ value:'Northland College', label: 'Northland College'}, 
{ value:'Northpoint Bible College', label: 'Northpoint Bible College'}, 
{ value:'Northwest Florida State College', label: 'Northwest Florida State College'}, 
{ value:'Northwest Missouri State University', label: 'Northwest Missouri State University'}, 
{ value:'Northwest Nazarene University', label: 'Northwest Nazarene University'}, 
{ value:'Northwest University', label: 'Northwest University'}, 
{ value:'Northwestern College', label: 'Northwestern College'}, 
{ value:'Northwestern Health Sciences University', label: 'Northwestern Health Sciences University'}, 
{ value:'Northwestern Oklahoma State University', label: 'Northwestern Oklahoma State University'}, 
{ value:'Northwestern State University of Louisiana', label: 'Northwestern State University of Louisiana'}, 
{ value:'Northwestern University', label: 'Northwestern University'}, 
{ value:'Northwood University', label: 'Northwood University'}, 
{ value:'Norwich University', label: 'Norwich University'}, 
{ value:'Notre Dame College', label: 'Notre Dame College'}, 
{ value:'Notre Dame de Namur University', label: 'Notre Dame de Namur University'}, 
{ value:'Notre Dame of Maryland University', label: 'Notre Dame of Maryland University'}, 
{ value:'Nova Southeastern University', label: 'Nova Southeastern University'}, 
{ value:'Oak Hills Christian College', label: 'Oak Hills Christian College'}, 
{ value:'Oak Point University', label: 'Oak Point University'}, 
{ value:'Oakland City University', label: 'Oakland City University'}, 
{ value:'Oakland University', label: 'Oakland University'}, 
{ value:'Oakwood University', label: 'Oakwood University'}, 
{ value:'Oberlin College', label: 'Oberlin College'}, 
{ value:'Occidental College', label: 'Occidental College'}, 
{ value:'Oglethorpe University', label: 'Oglethorpe University'}, 
{ value:'Ohio Christian University', label: 'Ohio Christian University'}, 
{ value:'Ohio Dominican University', label: 'Ohio Dominican University'}, 
{ value:'Ohio Northern University', label: 'Ohio Northern University'}, 
{ value:'Ohio University', label: 'Ohio University'}, 
{ value:'Ohio University Southern', label: 'Ohio University Southern'}, 
{ value:'Ohio University-Chillicothe', label: 'Ohio University-Chillicothe'}, 
{ value:'Ohio Wesleyan University', label: 'Ohio Wesleyan University'}, 
{ value:'Oklahoma Baptist University', label: 'Oklahoma Baptist University'}, 
{ value:'Oklahoma Christian University', label: 'Oklahoma Christian University'}, 
{ value:'Oklahoma City University', label: 'Oklahoma City University'}, 
{ value:'Oklahoma Panhandle State University', label: 'Oklahoma Panhandle State University'}, 
{ value:'Oklahoma State University', label: 'Oklahoma State University'}, 
{ value:'Oklahoma Wesleyan University', label: 'Oklahoma Wesleyan University'}, 
{ value:'Old Dominion University', label: 'Old Dominion University'}, 
{ value:'Olivet Nazarene University', label: 'Olivet Nazarene University'}, 
{ value:'Oral Roberts University', label: 'Oral Roberts University'}, 
{ value:'Oregon Health & Science University', label: 'Oregon Health & Science University'}, 
{ value:'Oregon Institute of Technology', label: 'Oregon Institute of Technology'}, 
{ value:'Oregon State University', label: 'Oregon State University'}, 
{ value:'Otis College of Art and Design', label: 'Otis College of Art and Design'}, 
{ value:'Ottawa University', label: 'Ottawa University'}, 
{ value:'Otterbein University', label: 'Otterbein University'}, 
{ value:'Ouachita Baptist University', label: 'Ouachita Baptist University'}, 
{ value:'Our Lady of the Lake University', label: 'Our Lady of the Lake University'}, 
{ value:'Ozark Christian College', label: 'Ozark Christian College'}, 
{ value:'Pace University', label: 'Pace University'}, 
{ value:'Pacific Lutheran University', label: 'Pacific Lutheran University'}, 
{ value:'Pacific Oaks College', label: 'Pacific Oaks College'}, 
{ value:'Pacific Union College', label: 'Pacific Union College'}, 
{ value:'Pacific University', label: 'Pacific University'}, 
{ value:'Pacifica Graduate Institute', label: 'Pacifica Graduate Institute'}, 
{ value:'Paine College', label: 'Paine College'}, 
{ value:'Palm Beach Atlantic University', label: 'Palm Beach Atlantic University'}, 
{ value:'Palm Beach State College', label: 'Palm Beach State College'}, 
{ value:'Palo Alto University', label: 'Palo Alto University'}, 
{ value:'Pardee RAND Graduate School', label: 'Pardee RAND Graduate School'}, 
{ value:'Park University', label: 'Park University'}, 
{ value:'Parker University', label: 'Parker University'}, 
{ value:'Paul Quinn College', label: 'Paul Quinn College'}, 
{ value:'Paul Smiths College', label: 'Paul Smiths College'}, 
{ value:'Peirce College', label: 'Peirce College'}, 
{ value:'Penn State College of Medicine', label: 'Penn State College of Medicine'}, 
{ value:'Penn State University', label: 'Penn State University'}, 
{ value:'Pennsylvania Academy of the Fine Arts', label: 'Pennsylvania Academy of the Fine Arts'}, 
{ value:'Pennsylvania College of Art and Design', label: 'Pennsylvania College of Art and Design'}, 
{ value:'Pennsylvania College of Health Sciences', label: 'Pennsylvania College of Health Sciences'}, 
{ value:'Pennsylvania College of Technology', label: 'Pennsylvania College of Technology'}, 
{ value:'PennWest California', label: 'PennWest California'}, 
{ value:'PennWest Clarion', label: 'PennWest Clarion'}, 
{ value:'PennWest Edinboro', label: 'PennWest Edinboro'}, 
{ value:'Pensacola State College', label: 'Pensacola State College'}, 
{ value:'Pepperdine University', label: 'Pepperdine University'}, 
{ value:'Peru State College', label: 'Peru State College'}, 
{ value:'Pfeiffer University', label: 'Pfeiffer University'}, 
{ value:'Philander Smith University', label: 'Philander Smith University'}, 
{ value:'Piedmont College', label: 'Piedmont College'}, 
{ value:'Pillar College', label: 'Pillar College'}, 
{ value:'Pittsburg State University', label: 'Pittsburg State University'}, 
{ value:'Pitzer College', label: 'Pitzer College'}, 
{ value:'Plaza College', label: 'Plaza College'}, 
{ value:'Plymouth State University', label: 'Plymouth State University'}, 
{ value:'Point Loma Nazarene University', label: 'Point Loma Nazarene University'}, 
{ value:'Point Park University', label: 'Point Park University'}, 
{ value:'Point University', label: 'Point University'}, 
{ value:'Polk State College', label: 'Polk State College'}, 
{ value:'Pomona College', label: 'Pomona College'}, 
{ value:'Pontifical Faculty of the Immaculate Conception', label: 'Pontifical Faculty of the Immaculate Conception'}, 
{ value:'Portland State University', label: 'Portland State University'}, 
{ value:'Post University', label: 'Post University'}, 
{ value:'Prairie View A&M University', label: 'Prairie View A&M University'}, 
{ value:'Pratt Institute', label: 'Pratt Institute'}, 
{ value:'Presbyterian College', label: 'Presbyterian College'}, 
{ value:'Prescott College', label: 'Prescott College'}, 
{ value:'Presentation College', label: 'Presentation College'}, 
{ value:'Princeton University', label: 'Princeton University'}, 
{ value:'Principia College', label: 'Principia College'}, 
{ value:'Providence Christian College', label: 'Providence Christian College'}, 
{ value:'Providence College', label: 'Providence College'}, 
{ value:'Purchase College, State University of New York', label: 'Purchase College, State University of New York'}, 
{ value:'Purdue University', label: 'Purdue University'}, 
{ value:'Purdue University Fort Wayne', label: 'Purdue University Fort Wayne'}, 
{ value:'Purdue University Northwest', label: 'Purdue University Northwest'}, 
{ value:'Queens College, City University of New York', label: 'Queens College, City University of New York'}, 
{ value:'Queens University of Charlotte', label: 'Queens University of Charlotte'}, 
{ value:'Quincy University', label: 'Quincy University'}, 
{ value:'Quinnipiac University', label: 'Quinnipiac University'}, 
{ value:'Radford University', label: 'Radford University'}, 
{ value:'Ramapo College of New Jersey', label: 'Ramapo College of New Jersey'}, 
{ value:'Randolph College', label: 'Randolph College'}, 
{ value:'Randolph-Macon College', label: 'Randolph-Macon College'}, 
{ value:'Rasmussen University', label: 'Rasmussen University'}, 
{ value:'Reed College', label: 'Reed College'}, 
{ value:'Regent University', label: 'Regent University'}, 
{ value:'Regis College', label: 'Regis College'}, 
{ value:'Regis University', label: 'Regis University'}, 
{ value:'Reinhardt University', label: 'Reinhardt University'}, 
{ value:'Relay Graduate School of Education', label: 'Relay Graduate School of Education'}, 
{ value:'Rensselaer Polytechnic Institute', label: 'Rensselaer Polytechnic Institute'}, 
{ value:'Research College of Nursing', label: 'Research College of Nursing'}, 
{ value:'Rhode Island College', label: 'Rhode Island College'}, 
{ value:'Rhode Island School of Design', label: 'Rhode Island School of Design'}, 
{ value:'Rhodes College', label: 'Rhodes College'}, 
{ value:'Richmont Graduate University', label: 'Richmont Graduate University'}, 
{ value:'Rider University', label: 'Rider University'}, 
{ value:'Ringling College of Art and Design', label: 'Ringling College of Art and Design'}, 
{ value:'Ripon College', label: 'Ripon College'}, 
{ value:'Rivier University', label: 'Rivier University'}, 
{ value:'Roanoke College', label: 'Roanoke College'}, 
{ value:'Robert Morris University', label: 'Robert Morris University'}, 
{ value:'Roberts Wesleyan University', label: 'Roberts Wesleyan University'}, 
{ value:'Rochester Institute of Technology', label: 'Rochester Institute of Technology'}, 
{ value:'Rochester University', label: 'Rochester University'}, 
{ value:'Rockford University', label: 'Rockford University'}, 
{ value:'Rockhurst University', label: 'Rockhurst University'}, 
{ value:'Rocky Mountain College', label: 'Rocky Mountain College'}, 
{ value:'Rocky Mountain College of Art and Design', label: 'Rocky Mountain College of Art and Design'}, 
{ value:'Rocky Mountain University of Health Professions', label: 'Rocky Mountain University of Health Professions'}, 
{ value:'Rocky Vista University', label: 'Rocky Vista University'}, 
{ value:'Roger Williams University', label: 'Roger Williams University'}, 
{ value:'Rogers State University', label: 'Rogers State University'}, 
{ value:'Rollins College', label: 'Rollins College'}, 
{ value:'Roosevelt University', label: 'Roosevelt University'}, 
{ value:'Rosalind Franklin University of Medicine and Science', label: 'Rosalind Franklin University of Medicine and Science'}, 
{ value:'Rose-Hulman Institute of Technology', label: 'Rose-Hulman Institute of Technology'}, 
{ value:'Roseman University of Health Sciences', label: 'Roseman University of Health Sciences'}, 
{ value:'Rosemont College', label: 'Rosemont College'}, 
{ value:'Rowan University', label: 'Rowan University'}, 
{ value:'Rush University', label: 'Rush University'}, 
{ value:'Rust College', label: 'Rust College'}, 
{ value:'Rutgers, The State University of New Jersey', label: 'Rutgers, The State University of New Jersey'}, 
{ value:'Sacred Heart University', label: 'Sacred Heart University'}, 
{ value:'Saginaw Valley State University', label: 'Saginaw Valley State University'}, 
{ value:'Saint Anselm College', label: 'Saint Anselm College'}, 
{ value:'Saint Anthony College of Nursing', label: 'Saint Anthony College of Nursing'}, 
{ value:'Saint Augustine College', label: 'Saint Augustine College'}, 
{ value:'Saint Augustines University', label: 'Saint Augustines University'}, 
{ value:'Saint Elizabeth University', label: 'Saint Elizabeth University'}, 
{ value:'Saint Francis Medical Center College of Nursing', label: 'Saint Francis Medical Center College of Nursing'}, 
{ value:'Saint Francis University', label: 'Saint Francis University'}, 
{ value:'Saint Josephs College of Maine', label: 'Saint Josephs College of Maine'}, 
{ value:'Saint Josephs University', label: 'Saint Josephs University'}, 
{ value:'Saint Leo University', label: 'Saint Leo University'}, 
{ value:'Saint Louis University', label: 'Saint Louis University'}, 
{ value:'Saint Martins University', label: 'Saint Martins University'}, 
{ value:'Saint Mary-of-the-Woods College', label: 'Saint Mary-of-the-Woods College'}, 
{ value:'Saint Marys College', label: 'Saint Marys College'}, 
{ value:'Saint Marys College of California', label: 'Saint Marys College of California'}, 
{ value:'Saint Marys University of Minnesota', label: 'Saint Marys University of Minnesota'}, 
{ value:'Saint Michaels College', label: 'Saint Michaels College'}, 
{ value:'Saint Peters University', label: 'Saint Peters University'}, 
{ value:'Saint Vincent College', label: 'Saint Vincent College'}, 
{ value:'Saint Xavier University', label: 'Saint Xavier University'}, 
{ value:'Salem College', label: 'Salem College'}, 
{ value:'Salem State University', label: 'Salem State University'}, 
{ value:'Salem University', label: 'Salem University'}, 
{ value:'Salisbury University', label: 'Salisbury University'}, 
{ value:'Salus University', label: 'Salus University'}, 
{ value:'Salve Regina University', label: 'Salve Regina University'}, 
{ value:'Sam Houston State University', label: 'Sam Houston State University'}, 
{ value:'Samford University', label: 'Samford University'}, 
{ value:'Samuel Merritt University', label: 'Samuel Merritt University'}, 
{ value:'San Diego Christian College', label: 'San Diego Christian College'}, 
{ value:'San Diego State University', label: 'San Diego State University'}, 
{ value:'San Francisco Art Institute', label: 'San Francisco Art Institute'}, 
{ value:'San Francisco Conservatory of Music', label: 'San Francisco Conservatory of Music'}, 
{ value:'San Francisco State University', label: 'San Francisco State University'}, 
{ value:'San Joaquin College of Law', label: 'San Joaquin College of Law'}, 
{ value:'San José State University', label: 'San José State University'}, 
{ value:'Santa Clara University', label: 'Santa Clara University'}, 
{ value:'Santa Fe College', label: 'Santa Fe College'}, 
{ value:'Sarah Lawrence College', label: 'Sarah Lawrence College'}, 
{ value:'Savannah College of Art and Design', label: 'Savannah College of Art and Design'}, 
{ value:'Savannah State University', label: 'Savannah State University'}, 
{ value:'Saybrook University', label: 'Saybrook University'}, 
{ value:'School of the Art Institute of Chicago', label: 'School of the Art Institute of Chicago'}, 
{ value:'School of Visual Arts', label: 'School of Visual Arts'}, 
{ value:'Schreiner University', label: 'Schreiner University'}, 
{ value:'Scripps College', label: 'Scripps College'}, 
{ value:'Seattle Central College', label: 'Seattle Central College'}, 
{ value:'Seattle Pacific University', label: 'Seattle Pacific University'}, 
{ value:'Seattle University', label: 'Seattle University'}, 
{ value:'Seminole State College of Florida', label: 'Seminole State College of Florida'}, 
{ value:'Seton Hall University', label: 'Seton Hall University'}, 
{ value:'Seton Hill University', label: 'Seton Hill University'}, 
{ value:'Sewanee: The University of the South', label: 'Sewanee: The University of the South'}, 
{ value:'Shaw University', label: 'Shaw University'}, 
{ value:'Shawnee State University', label: 'Shawnee State University'}, 
{ value:'Shenandoah University', label: 'Shenandoah University'}, 
{ value:'Shepherd University', label: 'Shepherd University'}, 
{ value:'Shippensburg University of Pennsylvania', label: 'Shippensburg University of Pennsylvania'}, 
{ value:'Shorter University', label: 'Shorter University'}, 
{ value:'Siena College', label: 'Siena College'}, 
{ value:'Siena Heights University', label: 'Siena Heights University'}, 
{ value:'Simmons University', label: 'Simmons University'}, 
{ value:'Simpson College', label: 'Simpson College'}, 
{ value:'Simpson University', label: 'Simpson University'}, 
{ value:'SIT Graduate Institute', label: 'SIT Graduate Institute'}, 
{ value:'Skidmore College', label: 'Skidmore College'}, 
{ value:'Slippery Rock University of Pennsylvania', label: 'Slippery Rock University of Pennsylvania'}, 
{ value:'Smith College', label: 'Smith College'}, 
{ value:'Snow College', label: 'Snow College'}, 
{ value:'Sofia University', label: 'Sofia University'}, 
{ value:'Soka University of America', label: 'Soka University of America'}, 
{ value:'Sonoma State University', label: 'Sonoma State University'}, 
{ value:'South Carolina State University', label: 'South Carolina State University'}, 
{ value:'South College', label: 'South College'}, 
{ value:'South Dakota School of Mines and Technology', label: 'South Dakota School of Mines and Technology'}, 
{ value:'South Dakota State University', label: 'South Dakota State University'}, 
{ value:'South Georgia State College', label: 'South Georgia State College'}, 
{ value:'South Texas College', label: 'South Texas College'}, 
{ value:'South Texas College of Law', label: 'South Texas College of Law'}, 
{ value:'South University', label: 'South University'}, 
{ value:'Southeast Missouri State University', label: 'Southeast Missouri State University'}, 
{ value:'Southeastern Baptist College', label: 'Southeastern Baptist College'}, 
{ value:'Southeastern Louisiana University', label: 'Southeastern Louisiana University'}, 
{ value:'Southeastern Oklahoma State University', label: 'Southeastern Oklahoma State University'}, 
{ value:'Southeastern University', label: 'Southeastern University'}, 
{ value:'Southern Adventist University', label: 'Southern Adventist University'}, 
{ value:'Southern Arkansas University', label: 'Southern Arkansas University'}, 
{ value:'Southern California Institute of Architecture', label: 'Southern California Institute of Architecture'}, 
{ value:'Southern College of Optometry', label: 'Southern College of Optometry'}, 
{ value:'Southern Connecticut State University', label: 'Southern Connecticut State University'}, 
{ value:'Southern Illinois University Carbondale', label: 'Southern Illinois University Carbondale'}, 
{ value:'Southern Illinois University Edwardsville', label: 'Southern Illinois University Edwardsville'}, 
{ value:'Southern Methodist University', label: 'Southern Methodist University'}, 
{ value:'Southern Nazarene University', label: 'Southern Nazarene University'}, 
{ value:'Southern New Hampshire University', label: 'Southern New Hampshire University'}, 
{ value:'Southern Oregon University', label: 'Southern Oregon University'}, 
{ value:'Southern University and A&M College', label: 'Southern University and A&M College'}, 
{ value:'Southern University at New Orleans', label: 'Southern University at New Orleans'}, 
{ value:'Southern University Law Center', label: 'Southern University Law Center'}, 
{ value:'Southern Utah University', label: 'Southern Utah University'}, 
{ value:'Southern Virginia University', label: 'Southern Virginia University'}, 
{ value:'Southern Wesleyan University', label: 'Southern Wesleyan University'}, 
{ value:'Southwest Baptist University', label: 'Southwest Baptist University'}, 
{ value:'Southwest Minnesota State University', label: 'Southwest Minnesota State University'}, 
{ value:'Southwestern Adventist University', label: 'Southwestern Adventist University'}, 
{ value:'Southwestern Assemblies of God University', label: 'Southwestern Assemblies of God University'}, 
{ value:'Southwestern Christian University', label: 'Southwestern Christian University'}, 
{ value:'Southwestern College', label: 'Southwestern College'}, 
{ value:'Southwestern College, Santa Fe', label: 'Southwestern College, Santa Fe'}, 
{ value:'Southwestern Law School', label: 'Southwestern Law School'}, 
{ value:'Southwestern Oklahoma State University', label: 'Southwestern Oklahoma State University'}, 
{ value:'Southwestern University', label: 'Southwestern University'}, 
{ value:'Spalding University', label: 'Spalding University'}, 
{ value:'Spelman College', label: 'Spelman College'}, 
{ value:'Spring Arbor University', label: 'Spring Arbor University'}, 
{ value:'Spring Hill College', label: 'Spring Hill College'}, 
{ value:'Springfield College', label: 'Springfield College'}, 
{ value:'St Andrews University', label: 'St Andrews University'}, 
{ value:'St Johns College', label: 'St Johns College'}, 
{ value:'St Petersburg College', label: 'St Petersburg College'}, 
{ value:'St. Ambrose University', label: 'St. Ambrose University'}, 
{ value:'St. Bonaventure University', label: 'St. Bonaventure University'}, 
{ value:'St. Catherine University', label: 'St. Catherine University'}, 
{ value:'St. Cloud State University', label: 'St. Cloud State University'}, 
{ value:'St. Edwards University', label: 'St. Edwards University'}, 
{ value:'St. Francis College', label: 'St. Francis College'}, 
{ value:'St. John Fisher University', label: 'St. John Fisher University'}, 
{ value:'St. Johns River State College', label: 'St. Johns River State College'}, 
{ value:'St. Johns University', label: 'St. Johns University'}, 
{ value:'St. Josephs University', label: 'St. Josephs University'}, 
{ value:'St. Lawrence University', label: 'St. Lawrence University'}, 
{ value:'St. Louis Christian College', label: 'St. Louis Christian College'}, 
{ value:'St. Marys College of Maryland', label: 'St. Marys College of Maryland'}, 
{ value:'St. Marys University', label: 'St. Marys University'}, 
{ value:'St. Norbert College', label: 'St. Norbert College'}, 
{ value:'St. Olaf College', label: 'St. Olaf College'}, 
{ value:'St. Thomas Aquinas College', label: 'St. Thomas Aquinas College'}, 
{ value:'St. Thomas University', label: 'St. Thomas University'}, 
{ value:'Stanford University', label: 'Stanford University'}, 
{ value:'State College of Florida-Manatee-Sarasota', label: 'State College of Florida-Manatee-Sarasota'}, 
{ value:'State University of New York at Fredonia', label: 'State University of New York at Fredonia'}, 
{ value:'State University of New York at New Paltz', label: 'State University of New York at New Paltz'}, 
{ value:'State University of New York at Oswego', label: 'State University of New York at Oswego'}, 
{ value:'State University of New York College at Plattsburgh', label: 'State University of New York College at Plattsburgh'}, 
{ value:'Stephen F. Austin State University', label: 'Stephen F. Austin State University'}, 
{ value:'Stephens College', label: 'Stephens College'}, 
{ value:'Sterling College', label: 'Sterling College'}, 
{ value:'Sterling College, Vermont', label: 'Sterling College, Vermont'}, 
{ value:'Stetson University', label: 'Stetson University'}, 
{ value:'Stevens Institute of Technology', label: 'Stevens Institute of Technology'}, 
{ value:'Stevenson University', label: 'Stevenson University'}, 
{ value:'Stillman College', label: 'Stillman College'}, 
{ value:'Stockton University', label: 'Stockton University'}, 
{ value:'Stonehill College', label: 'Stonehill College'}, 
{ value:'Stony Brook University', label: 'Stony Brook University'}, 
{ value:'Strayer University', label: 'Strayer University'}, 
{ value:'Suffolk University', label: 'Suffolk University'}, 
{ value:'Sul Ross State University', label: 'Sul Ross State University'}, 
{ value:'Sullivan University', label: 'Sullivan University'}, 
{ value:'SUNY Brockport', label: 'SUNY Brockport'}, 
{ value:'SUNY Canton', label: 'SUNY Canton'}, 
{ value:'SUNY Cobleskill', label: 'SUNY Cobleskill'}, 
{ value:'SUNY College at Old Westbury', label: 'SUNY College at Old Westbury'}, 
{ value:'SUNY College at Oneonta', label: 'SUNY College at Oneonta'}, 
{ value:'SUNY College of Environmental Science and Forestry', label: 'SUNY College of Environmental Science and Forestry'}, 
{ value:'SUNY College of Optometry', label: 'SUNY College of Optometry'}, 
{ value:'SUNY Cortland', label: 'SUNY Cortland'}, 
{ value:'SUNY Delhi', label: 'SUNY Delhi'}, 
{ value:'SUNY Downstate Medical Center', label: 'SUNY Downstate Medical Center'}, 
{ value:'SUNY Empire State College', label: 'SUNY Empire State College'}, 
{ value:'SUNY Geneseo', label: 'SUNY Geneseo'}, 
{ value:'SUNY Maritime College', label: 'SUNY Maritime College'}, 
{ value:'SUNY Polytechnic Institute', label: 'SUNY Polytechnic Institute'}, 
{ value:'Susquehanna University', label: 'Susquehanna University'}, 
{ value:'Swarthmore College', label: 'Swarthmore College'}, 
{ value:'Sweet Briar College', label: 'Sweet Briar College'}, 
{ value:'Syracuse University', label: 'Syracuse University'}, 
{ value:'Tabor College', label: 'Tabor College'}, 
{ value:'Talladega College', label: 'Talladega College'}, 
{ value:'Tarleton State University', label: 'Tarleton State University'}, 
{ value:'Taylor University', label: 'Taylor University'}, 
{ value:'Temple University', label: 'Temple University'}, 
{ value:'Tennessee State University', label: 'Tennessee State University'}, 
{ value:'Tennessee Tech University', label: 'Tennessee Tech University'}, 
{ value:'Tennessee Wesleyan University', label: 'Tennessee Wesleyan University'}, 
{ value:'Texas A&M International University', label: 'Texas A&M International University'}, 
{ value:'Texas A&M University', label: 'Texas A&M University'}, 
{ value:'Texas A&M University at Galveston', label: 'Texas A&M University at Galveston'}, 
{ value:'Texas A&M University-Commerce', label: 'Texas A&M University-Commerce'}, 
{ value:'Texas A&M University-Corpus Christi', label: 'Texas A&M University-Corpus Christi'}, 
{ value:'Texas A&M University-Kingsville', label: 'Texas A&M University-Kingsville'}, 
{ value:'Texas A&M University-San Antonio', label: 'Texas A&M University-San Antonio'}, 
{ value:'Texas A&M University-Texarkana', label: 'Texas A&M University-Texarkana'}, 
{ value:'Texas Christian University', label: 'Texas Christian University'}, 
{ value:'Texas College', label: 'Texas College'}, 
{ value:'Texas Lutheran University', label: 'Texas Lutheran University'}, 
{ value:'Texas Southern University', label: 'Texas Southern University'}, 
{ value:'Texas State University', label: 'Texas State University'}, 
{ value:'Texas Tech University', label: 'Texas Tech University'}, 
{ value:'Texas Tech University Health Sciences Center', label: 'Texas Tech University Health Sciences Center'}, 
{ value:'Texas Wesleyan University', label: 'Texas Wesleyan University'}, 
{ value:'Texas Womans University', label: 'Texas Womans University'}, 
{ value:'The American College of Financial Services', label: 'The American College of Financial Services'}, 
{ value:'The Baptist College of Florida', label: 'The Baptist College of Florida'}, 
{ value:'The Catholic University of America', label: 'The Catholic University of America'}, 
{ value:'The Chicago School of Professional Psychology', label: 'The Chicago School of Professional Psychology'}, 
{ value:'The Citadel, The Military College of South Carolina', label: 'The Citadel, The Military College of South Carolina'}, 
{ value:'The City College of New York', label: 'The City College of New York'}, 
{ value:'The College of Idaho', label: 'The College of Idaho'}, 
{ value:'The College of New Jersey', label: 'The College of New Jersey'}, 
{ value:'The College of Saint Rose', label: 'The College of Saint Rose'}, 
{ value:'The College of St. Scholastica', label: 'The College of St. Scholastica'}, 
{ value:'The College of Westchester', label: 'The College of Westchester'}, 
{ value:'The College of Wooster', label: 'The College of Wooster'}, 
{ value:'The Cooper Union for the Advancement of Science and Art', label: 'The Cooper Union for the Advancement of Science and Art'}, 
{ value:'The Culinary Institute of America', label: 'The Culinary Institute of America'}, 
{ value:'The Evergreen State College', label: 'The Evergreen State College'}, 
{ value:'The Graduate Center, CUNY', label: 'The Graduate Center, CUNY'}, 
{ value:'The Institute of World Politics', label: 'The Institute of World Politics'}, 
{ value:'The Juilliard School', label: 'The Juilliard School'}, 
{ value:'The Kings College', label: 'The Kings College'}, 
{ value:'The Kings University', label: 'The Kings University'}, 
{ value:'The Masters University', label: 'The Masters University'}, 
{ value:'The New School', label: 'The New School'}, 
{ value:'The Ohio State University', label: 'The Ohio State University'}, 
{ value:'The Rockefeller University', label: 'The Rockefeller University'}, 
{ value:'The Sage Colleges', label: 'The Sage Colleges'}, 
{ value:'The School of Architecture', label: 'The School of Architecture'}, 
{ value:'The State University of New York at Potsdam', label: 'The State University of New York at Potsdam'}, 
{ value:'The University of Alabama', label: 'The University of Alabama'}, 
{ value:'The University of Alabama in Huntsville', label: 'The University of Alabama in Huntsville'}, 
{ value:'The University of Arizona', label: 'The University of Arizona'}, 
{ value:'The University of Maine', label: 'The University of Maine'}, 
{ value:'The University of Memphis', label: 'The University of Memphis'}, 
{ value:'The University of Montana Western', label: 'The University of Montana Western'}, 
{ value:'The University of Oklahoma', label: 'The University of Oklahoma'}, 
{ value:'The University of Oklahoma Health Sciences Center', label: 'The University of Oklahoma Health Sciences Center'}, 
{ value:'The University of Olivet', label: 'The University of Olivet'}, 
{ value:'The University of Tampa', label: 'The University of Tampa'}, 
{ value:'The University of Tennessee at Chattanooga', label: 'The University of Tennessee at Chattanooga'}, 
{ value:'The University of Tennessee at Martin', label: 'The University of Tennessee at Martin'}, 
{ value:'The University of Tennessee Health Science Center', label: 'The University of Tennessee Health Science Center'}, 
{ value:'The University of Tennessee Southern', label: 'The University of Tennessee Southern'}, 
{ value:'The University of Tennessee, Knoxville', label: 'The University of Tennessee, Knoxville'}, 
{ value:'The University of Texas at Arlington', label: 'The University of Texas at Arlington'}, 
{ value:'The University of Texas at Austin', label: 'The University of Texas at Austin'}, 
{ value:'The University of Texas at Dallas', label: 'The University of Texas at Dallas'}, 
{ value:'The University of Texas at El Paso', label: 'The University of Texas at El Paso'}, 
{ value:'The University of Texas at San Antonio', label: 'The University of Texas at San Antonio'}, 
{ value:'The University of Texas at Tyler', label: 'The University of Texas at Tyler'}, 
{ value:'The University of Texas Health Science Center at Houston', label: 'The University of Texas Health Science Center at Houston'}, 
{ value:'The University of Texas Health Science Center at San Antonio', label: 'The University of Texas Health Science Center at San Antonio'}, 
{ value:'The University of Texas Health Science Center at Tyler', label: 'The University of Texas Health Science Center at Tyler'}, 
{ value:'The University of Texas MD Anderson Cancer Center', label: 'The University of Texas MD Anderson Cancer Center'}, 
{ value:'The University of Texas Medical Branch at Galveston', label: 'The University of Texas Medical Branch at Galveston'}, 
{ value:'The University of Texas Permian Basin', label: 'The University of Texas Permian Basin'}, 
{ value:'The University of the Arts', label: 'The University of the Arts'}, 
{ value:'The University of Tulsa', label: 'The University of Tulsa'}, 
{ value:'The University of Utah', label: 'The University of Utah'}, 
{ value:'The University of Virginias College at Wise', label: 'The University of Virginias College at Wise'}, 
{ value:'The University of West Alabama', label: 'The University of West Alabama'}, 
{ value:'The University of West Los Angeles', label: 'The University of West Los Angeles'}, 
{ value:'The Wright Institute', label: 'The Wright Institute'}, 
{ value:'Thiel College', label: 'Thiel College'}, 
{ value:'Thomas Aquinas College', label: 'Thomas Aquinas College'}, 
{ value:'Thomas College', label: 'Thomas College'}, 
{ value:'Thomas Edison State University', label: 'Thomas Edison State University'}, 
{ value:'Thomas Jefferson School of Law', label: 'Thomas Jefferson School of Law'}, 
{ value:'Thomas Jefferson University', label: 'Thomas Jefferson University'}, 
{ value:'Thomas More College of Liberal Arts', label: 'Thomas More College of Liberal Arts'}, 
{ value:'Thomas More University', label: 'Thomas More University'}, 
{ value:'Thomas University', label: 'Thomas University'}, 
{ value:'Tiffin University', label: 'Tiffin University'}, 
{ value:'Toccoa Falls College', label: 'Toccoa Falls College'}, 
{ value:'Tougaloo College', label: 'Tougaloo College'}, 
{ value:'Touro University', label: 'Touro University'}, 
{ value:'Touro University California', label: 'Touro University California'}, 
{ value:'Towson University', label: 'Towson University'}, 
{ value:'Transylvania University', label: 'Transylvania University'}, 
{ value:'Trevecca Nazarene University', label: 'Trevecca Nazarene University'}, 
{ value:'Trine University', label: 'Trine University'}, 
{ value:'Trinity Bible College', label: 'Trinity Bible College'}, 
{ value:'Trinity Christian College', label: 'Trinity Christian College'}, 
{ value:'Trinity College', label: 'Trinity College'}, 
{ value:'Trinity College of Florida', label: 'Trinity College of Florida'}, 
{ value:'Trinity College of Nursing & Health Sciences', label: 'Trinity College of Nursing & Health Sciences'}, 
{ value:'Trinity International University', label: 'Trinity International University'}, 
{ value:'Trinity University', label: 'Trinity University'}, 
{ value:'Trinity Washington University', label: 'Trinity Washington University'}, 
{ value:'Tri-State Bible College', label: 'Tri-State Bible College'}, 
{ value:'Trocaire College', label: 'Trocaire College'}, 
{ value:'Troy University', label: 'Troy University'}, 
{ value:'Truett McConnell University', label: 'Truett McConnell University'}, 
{ value:'Truman State University', label: 'Truman State University'}, 
{ value:'Tufts University', label: 'Tufts University'}, 
{ value:'Tulane University', label: 'Tulane University'}, 
{ value:'Tusculum University', label: 'Tusculum University'}, 
{ value:'Tuskegee University', label: 'Tuskegee University'}, 
{ value:'UMass Chan Medical School', label: 'UMass Chan Medical School'}, 
{ value:'Uniformed Services University of the Health Sciences', label: 'Uniformed Services University of the Health Sciences'}, 
{ value:'Union College', label: 'Union College'}, 
{ value:'Union College, Kentucky', label: 'Union College, Kentucky'}, 
{ value:'Union College, Nebraska', label: 'Union College, Nebraska'}, 
{ value:'Union Institute & University', label: 'Union Institute & University'}, 
{ value:'Union University', label: 'Union University'}, 
{ value:'United States Air Force Academy', label: 'United States Air Force Academy'}, 
{ value:'United States Coast Guard Academy', label: 'United States Coast Guard Academy'}, 
{ value:'United States Merchant Marine Academy', label: 'United States Merchant Marine Academy'}, 
{ value:'United States Military Academy', label: 'United States Military Academy'}, 
{ value:'United States Naval Academy', label: 'United States Naval Academy'}, 
{ value:'United States Sports Academy', label: 'United States Sports Academy'}, 
{ value:'United States University', label: 'United States University'}, 
{ value:'Unity Environmental University', label: 'Unity Environmental University'}, 
{ value:'University at Albany, State University of New York', label: 'University at Albany, State University of New York'}, 
{ value:'University at Buffalo, State University of New York', label: 'University at Buffalo, State University of New York'}, 
{ value:'University of Advancing Technology', label: 'University of Advancing Technology'}, 
{ value:'University of Akron', label: 'University of Akron'}, 
{ value:'University of Alabama at Birmingham', label: 'University of Alabama at Birmingham'}, 
{ value:'University of Alaska Anchorage', label: 'University of Alaska Anchorage'}, 
{ value:'University of Alaska Fairbanks', label: 'University of Alaska Fairbanks'}, 
{ value:'University of Alaska Southeast', label: 'University of Alaska Southeast'}, 
{ value:'University of Arkansas', label: 'University of Arkansas'}, 
{ value:'University of Arkansas - Fort Smith', label: 'University of Arkansas - Fort Smith'}, 
{ value:'University of Arkansas at Little Rock', label: 'University of Arkansas at Little Rock'}, 
{ value:'University of Arkansas at Monticello', label: 'University of Arkansas at Monticello'}, 
{ value:'University of Arkansas at Pine Bluff', label: 'University of Arkansas at Pine Bluff'}, 
{ value:'University of Arkansas for Medical Sciences', label: 'University of Arkansas for Medical Sciences'}, 
{ value:'University of Baltimore', label: 'University of Baltimore'}, 
{ value:'University of Bridgeport', label: 'University of Bridgeport'}, 
{ value:'University of California College of the Law, San Francisco', label: 'University of California College of the Law, San Francisco'}, 
{ value:'University of California, Berkeley', label: 'University of California, Berkeley'}, 
{ value:'University of California, Davis', label: 'University of California, Davis'}, 
{ value:'University of California, Irvine', label: 'University of California, Irvine'}, 
{ value:'University of California, Los Angeles', label: 'University of California, Los Angeles'}, 
{ value:'University of California, Merced', label: 'University of California, Merced'}, 
{ value:'University of California, Riverside', label: 'University of California, Riverside'}, 
{ value:'University of California, San Diego', label: 'University of California, San Diego'}, 
{ value:'University of California, San Francisco', label: 'University of California, San Francisco'}, 
{ value:'University of California, Santa Barbara', label: 'University of California, Santa Barbara'}, 
{ value:'University of California, Santa Cruz', label: 'University of California, Santa Cruz'}, 
{ value:'University of Central Arkansas', label: 'University of Central Arkansas'}, 
{ value:'University of Central Florida', label: 'University of Central Florida'}, 
{ value:'University of Central Missouri', label: 'University of Central Missouri'}, 
{ value:'University of Central Oklahoma', label: 'University of Central Oklahoma'}, 
{ value:'University of Charleston', label: 'University of Charleston'}, 
{ value:'University of Chicago', label: 'University of Chicago'}, 
{ value:'University of Cincinnati', label: 'University of Cincinnati'}, 
{ value:'University of Colorado Boulder', label: 'University of Colorado Boulder'}, 
{ value:'University of Colorado Colorado Springs', label: 'University of Colorado Colorado Springs'}, 
{ value:'University of Colorado Denver', label: 'University of Colorado Denver'}, 
{ value:'University of Connecticut', label: 'University of Connecticut'}, 
{ value:'University of Dallas', label: 'University of Dallas'}, 
{ value:'University of Dayton', label: 'University of Dayton'}, 
{ value:'University of Delaware', label: 'University of Delaware'}, 
{ value:'University of Denver', label: 'University of Denver'}, 
{ value:'University of Detroit Mercy', label: 'University of Detroit Mercy'}, 
{ value:'University of Dubuque', label: 'University of Dubuque'}, 
{ value:'University of Evansville', label: 'University of Evansville'}, 
{ value:'University of Findlay', label: 'University of Findlay'}, 
{ value:'University of Florida', label: 'University of Florida'}, 
{ value:'University of Georgia', label: 'University of Georgia'}, 
{ value:'University of Hartford', label: 'University of Hartford'}, 
{ value:'University of Hawaii at Hilo', label: 'University of Hawaii at Hilo'}, 
{ value:'University of Hawaii at Manoa', label: 'University of Hawaii at Manoa'}, 
{ value:'University of Hawaii-West Oahu', label: 'University of Hawaii-West Oahu'}, 
{ value:'University of Health Sciences and Pharmacy in St. Louis', label: 'University of Health Sciences and Pharmacy in St. Louis'}, 
{ value:'University of Holy Cross', label: 'University of Holy Cross'}, 
{ value:'University of Houston', label: 'University of Houston'}, 
{ value:'University of Houston-Clear Lake', label: 'University of Houston-Clear Lake'}, 
{ value:'University of Houston-Downtown', label: 'University of Houston-Downtown'}, 
{ value:'University of Houston-Victoria', label: 'University of Houston-Victoria'}, 
{ value:'University of Idaho', label: 'University of Idaho'}, 
{ value:'University of Illinois at Springfield', label: 'University of Illinois at Springfield'}, 
{ value:'University of Illinois Chicago', label: 'University of Illinois Chicago'}, 
{ value:'University of Illinois Urbana-Champaign', label: 'University of Illinois Urbana-Champaign'}, 
{ value:'University of Indianapolis', label: 'University of Indianapolis'}, 
{ value:'University of Iowa', label: 'University of Iowa'}, 
{ value:'University of Jamestown', label: 'University of Jamestown'}, 
{ value:'University of Kansas', label: 'University of Kansas'}, 
{ value:'University of Kentucky', label: 'University of Kentucky'}, 
{ value:'University of La Verne', label: 'University of La Verne'}, 
{ value:'University of Louisiana at Lafayette', label: 'University of Louisiana at Lafayette'}, 
{ value:'University of Louisiana at Monroe', label: 'University of Louisiana at Monroe'}, 
{ value:'University of Louisville', label: 'University of Louisville'}, 
{ value:'University of Lynchburg', label: 'University of Lynchburg'}, 
{ value:'University of Maine at Augusta', label: 'University of Maine at Augusta'}, 
{ value:'University of Maine at Farmington', label: 'University of Maine at Farmington'}, 
{ value:'University of Maine at Fort Kent', label: 'University of Maine at Fort Kent'}, 
{ value:'University of Maine at Machias', label: 'University of Maine at Machias'}, 
{ value:'University of Maine at Presque Isle', label: 'University of Maine at Presque Isle'}, 
{ value:'University of Mary', label: 'University of Mary'}, 
{ value:'University of Mary Hardin-Baylor', label: 'University of Mary Hardin-Baylor'}, 
{ value:'University of Mary Washington', label: 'University of Mary Washington'}, 
{ value:'University of Maryland', label: 'University of Maryland'}, 
{ value:'University of Maryland Eastern Shore', label: 'University of Maryland Eastern Shore'}, 
{ value:'University of Maryland, Baltimore', label: 'University of Maryland, Baltimore'}, 
{ value:'University of Maryland, Baltimore County', label: 'University of Maryland, Baltimore County'}, 
{ value:'University of Massachusetts Amherst', label: 'University of Massachusetts Amherst'}, 
{ value:'University of Massachusetts Boston', label: 'University of Massachusetts Boston'}, 
{ value:'University of Massachusetts Dartmouth', label: 'University of Massachusetts Dartmouth'}, 
{ value:'University of Massachusetts Global', label: 'University of Massachusetts Global'}, 
{ value:'University of Massachusetts Lowell', label: 'University of Massachusetts Lowell'}, 
{ value:'University of Miami', label: 'University of Miami'}, 
{ value:'University of Michigan', label: 'University of Michigan'}, 
{ value:'University of Michigan-Dearborn', label: 'University of Michigan-Dearborn'}, 
{ value:'University of Michigan-Flint', label: 'University of Michigan-Flint'}, 
{ value:'University of Minnesota Twin Cities', label: 'University of Minnesota Twin Cities'}, 
{ value:'University of Mississippi', label: 'University of Mississippi'}, 
{ value:'University of Mississippi Medical Center', label: 'University of Mississippi Medical Center'}, 
{ value:'University of Missouri', label: 'University of Missouri'}, 
{ value:'University of Missouri-Kansas City', label: 'University of Missouri-Kansas City'}, 
{ value:'University of Missouri-St. Louis', label: 'University of Missouri-St. Louis'}, 
{ value:'University of Mobile', label: 'University of Mobile'}, 
{ value:'University of Montana', label: 'University of Montana'}, 
{ value:'University of Montevallo', label: 'University of Montevallo'}, 
{ value:'University of Mount Olive', label: 'University of Mount Olive'}, 
{ value:'University of Mount Union', label: 'University of Mount Union'}, 
{ value:'University of Nebraska at Kearney', label: 'University of Nebraska at Kearney'}, 
{ value:'University of Nebraska at Omaha', label: 'University of Nebraska at Omaha'}, 
{ value:'University of Nebraska Medical Center', label: 'University of Nebraska Medical Center'}, 
{ value:'University of Nebraska-Lincoln', label: 'University of Nebraska-Lincoln'}, 
{ value:'University of Nevada, Las Vegas', label: 'University of Nevada, Las Vegas'}, 
{ value:'University of Nevada, Reno', label: 'University of Nevada, Reno'}, 
{ value:'University of New England', label: 'University of New England'}, 
{ value:'University of New Hampshire', label: 'University of New Hampshire'}, 
{ value:'University of New Haven', label: 'University of New Haven'}, 
{ value:'University of New Mexico', label: 'University of New Mexico'}, 
{ value:'University of New Orleans', label: 'University of New Orleans'}, 
{ value:'University of North Alabama', label: 'University of North Alabama'}, 
{ value:'University of North Carolina at Asheville', label: 'University of North Carolina at Asheville'}, 
{ value:'University of North Carolina at Chapel Hill', label: 'University of North Carolina at Chapel Hill'}, 
{ value:'University of North Carolina at Charlotte', label: 'University of North Carolina at Charlotte'}, 
{ value:'University of North Carolina at Greensboro', label: 'University of North Carolina at Greensboro'}, 
{ value:'University of North Carolina at Pembroke', label: 'University of North Carolina at Pembroke'}, 
{ value:'University of North Carolina School of the Arts', label: 'University of North Carolina School of the Arts'}, 
{ value:'University of North Carolina Wilmington', label: 'University of North Carolina Wilmington'}, 
{ value:'University of North Dakota', label: 'University of North Dakota'}, 
{ value:'University of North Florida', label: 'University of North Florida'}, 
{ value:'University of North Georgia', label: 'University of North Georgia'}, 
{ value:'University of North Texas', label: 'University of North Texas'}, 
{ value:'University of North Texas Health Science Center', label: 'University of North Texas Health Science Center'}, 
{ value:'University of Northern Colorado', label: 'University of Northern Colorado'}, 
{ value:'University of Northern Iowa', label: 'University of Northern Iowa'}, 
{ value:'University of Northwestern - St. Paul', label: 'University of Northwestern - St. Paul'}, 
{ value:'University of Northwestern Ohio', label: 'University of Northwestern Ohio'}, 
{ value:'University of Notre Dame', label: 'University of Notre Dame'}, 
{ value:'University of Oregon', label: 'University of Oregon'}, 
{ value:'University of Pennsylvania', label: 'University of Pennsylvania'}, 
{ value:'University of Pikeville', label: 'University of Pikeville'}, 
{ value:'University of Pittsburgh', label: 'University of Pittsburgh'}, 
{ value:'University of Portland', label: 'University of Portland'}, 
{ value:'University of Providence', label: 'University of Providence'}, 
{ value:'University of Puget Sound', label: 'University of Puget Sound'}, 
{ value:'University of Redlands', label: 'University of Redlands'}, 
{ value:'University of Rhode Island', label: 'University of Rhode Island'}, 
{ value:'University of Richmond', label: 'University of Richmond'}, 
{ value:'University of Rio Grande', label: 'University of Rio Grande'}, 
{ value:'University of Rochester', label: 'University of Rochester'}, 
{ value:'University of Saint Francis', label: 'University of Saint Francis'}, 
{ value:'University of Saint Joseph', label: 'University of Saint Joseph'}, 
{ value:'University of Saint Mary', label: 'University of Saint Mary'}, 
{ value:'University of San Diego', label: 'University of San Diego'}, 
{ value:'University of San Francisco', label: 'University of San Francisco'}, 
{ value:'University of Science and Arts of Oklahoma', label: 'University of Science and Arts of Oklahoma'}, 
{ value:'University of Scranton', label: 'University of Scranton'}, 
{ value:'University of Silicon Valley', label: 'University of Silicon Valley'}, 
{ value:'University of Sioux Falls', label: 'University of Sioux Falls'}, 
{ value:'University of South Alabama', label: 'University of South Alabama'}, 
{ value:'University of South Carolina', label: 'University of South Carolina'}, 
{ value:'University of South Carolina-Aiken', label: 'University of South Carolina-Aiken'}, 
{ value:'University of South Carolina-Beaufort', label: 'University of South Carolina-Beaufort'}, 
{ value:'University of South Carolina-Upstate', label: 'University of South Carolina-Upstate'}, 
{ value:'University of South Dakota', label: 'University of South Dakota'}, 
{ value:'University of South Florida', label: 'University of South Florida'}, 
{ value:'University of Southern California', label: 'University of Southern California'}, 
{ value:'University of Southern Indiana', label: 'University of Southern Indiana'}, 
{ value:'University of Southern Maine', label: 'University of Southern Maine'}, 
{ value:'University of Southern Mississippi', label: 'University of Southern Mississippi'}, 
{ value:'University of St. Augustine for Health Sciences', label: 'University of St. Augustine for Health Sciences'}, 
{ value:'University of St. Francis', label: 'University of St. Francis'}, 
 { value:'University of St. Thomas', label: 'University of St. Thomas'}, 
{ value:'University of Texas Rio Grande Valley', label: 'University of Texas Rio Grande Valley'}, 
{ value:'University of Texas Southwestern Medical Center', label: 'University of Texas Southwestern Medical Center'}, 
{ value:'University of the Cumberlands', label: 'University of the Cumberlands'}, 
{ value:'University of the District of Columbia', label: 'University of the District of Columbia'}, 
{ value:'University of the Incarnate Word', label: 'University of the Incarnate Word'}, 
{ value:'University of the Ozarks', label: 'University of the Ozarks'}, 
{ value:'University of the Pacific', label: 'University of the Pacific'}, 
{ value:'University of the Potomac', label: 'University of the Potomac'}, 
{ value:'University of the Southwest', label: 'University of the Southwest'}, 
{ value:'University of the West', label: 'University of the West'}, 
{ value:'University of Toledo', label: 'University of Toledo'}, 
{ value:'University of Valley Forge', label: 'University of Valley Forge'}, 
{ value:'University of Vermont', label: 'University of Vermont'}, 
{ value:'University of Virginia', label: 'University of Virginia'}, 
{ value:'University of Washington', label: 'University of Washington'}, 
{ value:'University of West Florida', label: 'University of West Florida'}, 
{ value:'University of West Georgia', label: 'University of West Georgia'}, 
{ value:'University of Western States', label: 'University of Western States'}, 
{ value:'University of Wisconsin-Eau Claire', label: 'University of Wisconsin-Eau Claire'}, 
{ value:'University of Wisconsin-Green Bay', label: 'University of Wisconsin-Green Bay'}, 
{ value:'University of Wisconsin-La Crosse', label: 'University of Wisconsin-La Crosse'}, 
{ value:'University of Wisconsin-Madison', label: 'University of Wisconsin-Madison'}, 
{ value:'University of Wisconsin-Milwaukee', label: 'University of Wisconsin-Milwaukee'}, 
{ value:'University of Wisconsin-Oshkosh', label: 'University of Wisconsin-Oshkosh'}, 
{ value:'University of Wisconsin-Parkside', label: 'University of Wisconsin-Parkside'}, 
{ value:'University of Wisconsin-Platteville', label: 'University of Wisconsin-Platteville'}, 
{ value:'University of Wisconsin-River Falls', label: 'University of Wisconsin-River Falls'}, 
{ value:'University of Wisconsin-Stevens Point', label: 'University of Wisconsin-Stevens Point'}, 
{ value:'University of Wisconsin-Stout', label: 'University of Wisconsin-Stout'}, 
{ value:'University of Wisconsin-Superior', label: 'University of Wisconsin-Superior'}, 
{ value:'University of Wisconsin-Whitewater', label: 'University of Wisconsin-Whitewater'}, 
{ value:'University of Wyoming', label: 'University of Wyoming'}, 
{ value:'Upper Iowa University', label: 'Upper Iowa University'}, 
{ value:'Upstate Medical University', label: 'Upstate Medical University'}, 
{ value:'Ursinus College', label: 'Ursinus College'}, 
{ value:'Ursuline College', label: 'Ursuline College'}, 
{ value:'Utah State University', label: 'Utah State University'}, 
{ value:'Utah Tech University', label: 'Utah Tech University'}, 
{ value:'Utah Valley University', label: 'Utah Valley University'}, 
{ value:'Utica University', label: 'Utica University'}, 
{ value:'Valdosta State University', label: 'Valdosta State University'}, 
{ value:'Valencia College', label: 'Valencia College'}, 
{ value:'Valley City State University', label: 'Valley City State University'}, 
{ value:'Valparaiso University', label: 'Valparaiso University'}, 
{ value:'Vanderbilt University', label: 'Vanderbilt University'}, 
{ value:'VanderCook College of Music', label: 'VanderCook College of Music'}, 
{ value:'Vanguard University of Southern California', label: 'Vanguard University of Southern California'}, 
{ value:'Vassar College', label: 'Vassar College'}, 
{ value:'Vaughn College of Aeronautics and Technology', label: 'Vaughn College of Aeronautics and Technology'}, 
{ value:'Vermont College of Fine Arts', label: 'Vermont College of Fine Arts'}, 
{ value:'Vermont Law School', label: 'Vermont Law School'}, 
{ value:'Vermont State University', label: 'Vermont State University'}, 
{ value:'Villa Maria College', label: 'Villa Maria College'}, 
{ value:'Villanova University', label: 'Villanova University'}, 
{ value:'Vincennes University', label: 'Vincennes University'}, 
{ value:'Virginia Commonwealth University', label: 'Virginia Commonwealth University'}, 
{ value:'Virginia Military Institute', label: 'Virginia Military Institute'}, 
{ value:'Virginia Polytechnic Institute and State University', label: 'Virginia Polytechnic Institute and State University'}, 
{ value:'Virginia State University', label: 'Virginia State University'}, 
{ value:'Virginia Union University', label: 'Virginia Union University'}, 
{ value:'Virginia Wesleyan University', label: 'Virginia Wesleyan University'}, 
{ value:'Viterbo University', label: 'Viterbo University'}, 
{ value:'Voorhees University', label: 'Voorhees University'}, 
{ value:'Wabash College', label: 'Wabash College'}, 
{ value:'Wade College', label: 'Wade College'}, 
{ value:'Wagner College', label: 'Wagner College'}, 
{ value:'Wake Forest University', label: 'Wake Forest University'}, 
{ value:'Waldorf University', label: 'Waldorf University'}, 
{ value:'Walla Walla University', label: 'Walla Walla University'}, 
{ value:'Walsh College', label: 'Walsh College'}, 
{ value:'Walsh University', label: 'Walsh University'}, 
{ value:'Warner Pacific University', label: 'Warner Pacific University'}, 
{ value:'Warner University', label: 'Warner University'}, 
{ value:'Warren Wilson College', label: 'Warren Wilson College'}, 
{ value:'Wartburg College', label: 'Wartburg College'}, 
{ value:'Washburn University', label: 'Washburn University'}, 
{ value:'Washington & Jefferson College', label: 'Washington & Jefferson College'}, 
{ value:'Washington Adventist University', label: 'Washington Adventist University'}, 
{ value:'Washington and Lee University', label: 'Washington and Lee University'}, 
{ value:'Washington College', label: 'Washington College'}, 
{ value:'Washington State University', label: 'Washington State University'}, 
{ value:'Washington University in St. Louis', label: 'Washington University in St. Louis'}, 
{ value:'Wayland Baptist University', label: 'Wayland Baptist University'}, 
{ value:'Wayne State College', label: 'Wayne State College'}, 
{ value:'Wayne State University', label: 'Wayne State University'}, 
{ value:'Waynesburg University', label: 'Waynesburg University'}, 
{ value:'Webb Institute', label: 'Webb Institute'}, 
{ value:'Webber International University', label: 'Webber International University'}, 
{ value:'Weber State University', label: 'Weber State University'}, 
{ value:'Webster University', label: 'Webster University'}, 
{ value:'Welch College', label: 'Welch College'}, 
{ value:'Wellesley College', label: 'Wellesley College'}, 
{ value:'Wells College', label: 'Wells College'}, 
{ value:'Wentworth Institute of Technology', label: 'Wentworth Institute of Technology'}, 
{ value:'Wesleyan College', label: 'Wesleyan College'}, 
{ value:'Wesleyan University', label: 'Wesleyan University'}, 
{ value:'West Chester University of Pennsylvania', label: 'West Chester University of Pennsylvania'}, 
{ value:'West Coast University-Los Angeles', label: 'West Coast University-Los Angeles'}, 
{ value:'West Liberty University', label: 'West Liberty University'}, 
{ value:'West Texas A&M University', label: 'West Texas A&M University'}, 
{ value:'West Virginia School of Osteopathic Medicine', label: 'West Virginia School of Osteopathic Medicine'}, 
{ value:'West Virginia State University', label: 'West Virginia State University'}, 
{ value:'West Virginia University', label: 'West Virginia University'}, 
{ value:'West Virginia University at Parkersburg', label: 'West Virginia University at Parkersburg'}, 
{ value:'West Virginia Wesleyan College', label: 'West Virginia Wesleyan College'}, 
{ value:'Western Carolina University', label: 'Western Carolina University'}, 
{ value:'Western Colorado University', label: 'Western Colorado University'}, 
{ value:'Western Connecticut State University', label: 'Western Connecticut State University'}, 
{ value:'Western Illinois University', label: 'Western Illinois University'}, 
{ value:'Western Kentucky University', label: 'Western Kentucky University'}, 
{ value:'Western Michigan University', label: 'Western Michigan University'}, 
{ value:'Western Nevada College', label: 'Western Nevada College'}, 
{ value:'Western New England University', label: 'Western New England University'}, 
{ value:'Western New Mexico University', label: 'Western New Mexico University'}, 
{ value:'Western Oregon University', label: 'Western Oregon University'}, 
{ value:'Western University of Health Sciences', label: 'Western University of Health Sciences'}, 
{ value:'Western Washington University', label: 'Western Washington University'}, 
{ value:'Westfield State University', label: 'Westfield State University'}, 
{ value:'Westminster College', label: 'Westminster College'}, 
{ value:'Westminster College, Missouri', label: 'Westminster College, Missouri'}, 
{ value:'Westminster College, Pennsylvania', label: 'Westminster College, Pennsylvania'}, 
{ value:'Westmont College', label: 'Westmont College'}, 
{ value:'Wheaton College', label: 'Wheaton College'}, 
{ value:'Wheaton College, Massachusetts', label: 'Wheaton College, Massachusetts'}, 
{ value:'Wheeling University', label: 'Wheeling University'}, 
{ value:'Whitman College', label: 'Whitman College'}, 
{ value:'Whittier College', label: 'Whittier College'}, 
{ value:'Whitworth University', label: 'Whitworth University'}, 
{ value:'Wichita State University', label: 'Wichita State University'}, 
{ value:'Widener University', label: 'Widener University'}, 
{ value:'Wilberforce University', label: 'Wilberforce University'}, 
{ value:'Wiley College', label: 'Wiley College'}, 
{ value:'Wilkes University', label: 'Wilkes University'}, 
{ value:'Willamette University', label: 'Willamette University'}, 
{ value:'William Carey University', label: 'William Carey University'}, 
{ value:'William James College', label: 'William James College'}, 
{ value:'William Jewell College', label: 'William Jewell College'}, 
{ value:'William Marsh Rice University', label: 'William Marsh Rice University'}, 
{ value:'William Paterson University', label: 'William Paterson University'}, 
{ value:'William Peace University', label: 'William Peace University'}, 
{ value:'William Penn University', label: 'William Penn University'}, 
{ value:'William Woods University', label: 'William Woods University'}, 
{ value:'Williams Baptist University', label: 'Williams Baptist University'}, 
{ value:'Williams College', label: 'Williams College'}, 
{ value:'Wilmington College', label: 'Wilmington College'}, 
{ value:'Wilmington University', label: 'Wilmington University'}, 
{ value:'Wilson College', label: 'Wilson College'}, 
{ value:'Wingate University', label: 'Wingate University'}, 
{ value:'Winona State University', label: 'Winona State University'}, 
{ value:'Winston-Salem State University', label: 'Winston-Salem State University'}, 
{ value:'Winthrop University', label: 'Winthrop University'}, 
{ value:'Wisconsin Lutheran College', label: 'Wisconsin Lutheran College'}, 
{ value:'Wisconsin School of Professional Psychology', label: 'Wisconsin School of Professional Psychology'}, 
{ value:'Wittenberg University', label: 'Wittenberg University'}, 
{ value:'Wofford College', label: 'Wofford College'}, 
{ value:'WON Institute of Graduate Studies', label: 'WON Institute of Graduate Studies'}, 
{ value:'Woodbury University', label: 'Woodbury University'}, 
{ value:'Worcester Polytechnic Institute', label: 'Worcester Polytechnic Institute'}, 
{ value:'Worcester State University', label: 'Worcester State University'}, 
{ value:'Wright State University', label: 'Wright State University'}, 
{ value:'Xavier University', label: 'Xavier University'}, 
{ value:'Xavier University of Louisiana', label: 'Xavier University of Louisiana'}, 
{ value:'Yale University', label: 'Yale University'}, 
{ value:'Yeshiva University', label: 'Yeshiva University'}, 
{ value:'York College of Pennsylvania', label: 'York College of Pennsylvania'}, 
{ value:'York College, City University of New York', label: 'York College, City University of New York'}, 
{ value:'York University', label: 'York University'}, 
{ value:'Young Harris College', label: 'Young Harris College'}, 
{ value:'Youngstown State University', label: 'Youngstown State University'}, 

]
const majorsss = [{ value:'Accounting', label: 'Accounting'}, 
  { value:'Actuarial Science', label: 'Actuarial Science'}, 
  { value:'Advertising Major', label: 'Advertising Major'}, 
  { value:'Aerospace Engineering', label: 'Aerospace Engineering'}, 
  { value:'African Languages, Literatures, and Linguistics', label: 'African Languages, Literatures, and Linguistics'}, 
  { value:'African Studies', label: 'African Studies'}, 
  { value:'African-American Studies', label: 'African-American Studies'}, 
  { value:'Agricultural Business and Management', label: 'Agricultural Business and Management'}, 
  { value:'Agricultural Economics', label: 'Agricultural Economics'}, 
  { value:'Agricultural Education', label: 'Agricultural Education'}, 
  { value:'Agricultural Journalism', label: 'Agricultural Journalism'}, 
  { value:'Agricultural Mechanization Major', label: 'Agricultural Mechanization Major'}, 
  { value:'Agricultural Technology Management', label: 'Agricultural Technology Management'}, 
  { value:'Agricultural/Biological Engineering and Bioengineering', label: 'Agricultural/Biological Engineering and Bioengineering'}, 
  { value:'Agriculture', label: 'Agriculture'}, 
  { value:'Agronomy and Crop Science', label: 'Agronomy and Crop Science'}, 
  { value:'Air Traffic Control', label: 'Air Traffic Control'}, 
  { value:'American History', label: 'American History'}, 
  { value:'American Literature', label: 'American Literature'}, 
  { value:'American Sign Language', label: 'American Sign Language'}, 
  { value:'American Studies', label: 'American Studies'}, 
  { value:'Anatomy', label: 'Anatomy'}, 
  { value:'Ancient Studies', label: 'Ancient Studies'}, 
  { value:'Animal Behavior and Ethology', label: 'Animal Behavior and Ethology'}, 
  { value:'Animal Science', label: 'Animal Science'}, 
  { value:'Animation and Special Effects', label: 'Animation and Special Effects'}, 
  { value:'Anthropology', label: 'Anthropology'}, 
  { value:'Applied Mathematics', label: 'Applied Mathematics'}, 
  { value:'Aquaculture', label: 'Aquaculture'}, 
  { value:'Aquatic Biology', label: 'Aquatic Biology'}, 
  { value:'Arabic', label: 'Arabic'}, 
  { value:'Archeology', label: 'Archeology'}, 
  { value:'Architectural Engineering', label: 'Architectural Engineering'}, 
  { value:'Architectural History', label: 'Architectural History'}, 
  { value:'Architecture', label: 'Architecture'}, 
  { value:'Art', label: 'Art'}, 
  { value:'Art Education', label: 'Art Education'}, 
  { value:'Art History', label: 'Art History'}, 
  { value:'Art Therapy', label: 'Art Therapy'}, 
  { value:'Artificial Intelligence and Robotics', label: 'Artificial Intelligence and Robotics'}, 
  { value:'Asian-American Studies', label: 'Asian-American Studies'}, 
  { value:'Astronomy', label: 'Astronomy'}, 
  { value:'Astrophysics', label: 'Astrophysics'}, 
  { value:'Athletic Training', label: 'Athletic Training'}, 
  { value:'Atmospheric Science', label: 'Atmospheric Science'}, 
  { value:'Automotive Engineering', label: 'Automotive Engineering'}, 
  { value:'Aviation', label: 'Aviation'}, 
  { value:'Bakery Science', label: 'Bakery Science'}, 
  { value:'Biblical Studies', label: 'Biblical Studies'}, 
  { value:'Biochemistry', label: 'Biochemistry'}, 
  { value:'Bioethics', label: 'Bioethics'}, 
  { value:'Biology', label: 'Biology'}, 
  { value:'Biomedical Engineering', label: 'Biomedical Engineering'}, 
  { value:'Biomedical Science', label: 'Biomedical Science'}, 
  { value:'Biopsychology', label: 'Biopsychology'}, 
  { value:'Biotechnology', label: 'Biotechnology'}, 
  { value:'Botany/Plant Biology', label: 'Botany/Plant Biology'}, 
  { value:'Business Administration/Management', label: 'Business Administration/Management'}, 
  { value:'Business Communications', label: 'Business Communications'}, 
  { value:'Business Education', label: 'Business Education'}, 
  { value:'Canadian Studies', label: 'Canadian Studies'}, 
  { value:'Caribbean Studies', label: 'Caribbean Studies'}, 
  { value:'Cell Biology Major', label: 'Cell Biology Major'}, 
  { value:'Ceramic Engineering', label: 'Ceramic Engineering'}, 
  { value:'Ceramics', label: 'Ceramics'}, 
  { value:'Chemical Engineering Major', label: 'Chemical Engineering Major'}, 
  { value:'Chemical Physics', label: 'Chemical Physics'}, 
  { value:'Chemistry Major', label: 'Chemistry Major'}, 
  { value:'Child Care', label: 'Child Care'}, 
  { value:'Child Development', label: 'Child Development'}, 
  { value:'Chinese', label: 'Chinese'}, 
  { value:'Chiropractic', label: 'Chiropractic'}, 
  { value:'Church Music', label: 'Church Music'}, 
  { value:'Cinematography and Film/Video Production', label: 'Cinematography and Film/Video Production'}, 
  { value:'Circulation Technology', label: 'Circulation Technology'}, 
  { value:'Civil Engineering', label: 'Civil Engineering'}, 
  { value:'Classics', label: 'Classics'}, 
  { value:'Clinical Psychology', label: 'Clinical Psychology'}, 
  { value:'Cognitive Psychology', label: 'Cognitive Psychology'}, 
  { value:'Communication Disorders', label: 'Communication Disorders'}, 
  { value:'Communications Studies/Speech Communication and Rhetoric', label: 'Communications Studies/Speech Communication and Rhetoric'}, 
  { value:'Comparative Literature', label: 'Comparative Literature'}, 
  { value:'Computer and Information Science', label: 'Computer and Information Science'}, 
  { value:'Computer Engineering', label: 'Computer Engineering'}, 
  { value:'Computer Graphics', label: 'Computer Graphics'}, 
  { value:'Computer Systems Analysis Major', label: 'Computer Systems Analysis Major'}, 
  { value:'Construction Management', label: 'Construction Management'}, 
  { value:'Counseling', label: 'Counseling'}, 
  { value:'Crafts', label: 'Crafts'}, 
  { value:'Creative Writing', label: 'Creative Writing'}, 
  { value:'Criminal Science', label: 'Criminal Science'}, 
  { value:'Criminology', label: 'Criminology'}, 
  { value:'Culinary Arts', label: 'Culinary Arts'}, 
  { value:'Dance', label: 'Dance'}, 
  { value:'Data Processing', label: 'Data Processing'}, 
  { value:'Dental Hygiene', label: 'Dental Hygiene'}, 
  { value:'Developmental Psychology', label: 'Developmental Psychology'}, 
  { value:'Diagnostic Medical Sonography', label: 'Diagnostic Medical Sonography'}, 
  { value:'Dietetics', label: 'Dietetics'}, 
  { value:'Digital Communications and Media/Multimedia', label: 'Digital Communications and Media/Multimedia'}, 
  { value:'Drawing', label: 'Drawing'}, 
  { value:'Early Childhood Education', label: 'Early Childhood Education'}, 
  { value:'East Asian Studies', label: 'East Asian Studies'}, 
  { value:'East European Studies', label: 'East European Studies'}, 
  { value:'Ecology', label: 'Ecology'}, 
  { value:'Economics Major', label: 'Economics Major'}, 
  { value:'Education', label: 'Education'}, 
  { value:'Education Administration', label: 'Education Administration'}, 
  { value:'Education of the Deaf', label: 'Education of the Deaf'}, 
  { value:'Educational Psychology', label: 'Educational Psychology'}, 
  { value:'Electrical Engineering', label: 'Electrical Engineering'}, 
  { value:'Elementary Education', label: 'Elementary Education'}, 
  { value:'Engineering Mechanics', label: 'Engineering Mechanics'}, 
  { value:'Engineering Physics', label: 'Engineering Physics'}, 
  { value:'English', label: 'English'}, 
  { value:'English Composition', label: 'English Composition'}, 
  { value:'English Literature Major', label: 'English Literature Major'}, 
  { value:'Entomology', label: 'Entomology'}, 
  { value:'Entrepreneurship Major', label: 'Entrepreneurship Major'}, 
  { value:'Environmental Design/Architecture', label: 'Environmental Design/Architecture'}, 
  { value:'Environmental Science', label: 'Environmental Science'}, 
  { value:'Environmental/Environmental Health Engineering', label: 'Environmental/Environmental Health Engineering'}, 
  { value:'Epidemiology', label: 'Epidemiology'}, 
  { value:'Equine Studies', label: 'Equine Studies'}, 
  { value:'Ethnic Studies', label: 'Ethnic Studies'}, 
  { value:'European History', label: 'European History'}, 
  { value:'Experimental Pathology', label: 'Experimental Pathology'}, 
  { value:'Experimental Psychology', label: 'Experimental Psychology'}, 
  { value:'Fashion Design', label: 'Fashion Design'}, 
  { value:'Fashion Merchandising', label: 'Fashion Merchandising'}, 
  { value:'Feed Science', label: 'Feed Science'}, 
  { value:'Fiber, Textiles, and Weaving Arts', label: 'Fiber, Textiles, and Weaving Arts'}, 
  { value:'Film', label: 'Film'}, 
  { value:'Finance', label: 'Finance'}, 
  { value:'Floriculture', label: 'Floriculture'}, 
  { value:'Food Science', label: 'Food Science'}, 
  { value:'Forensic Science', label: 'Forensic Science'}, 
  { value:'Forestry', label: 'Forestry'}, 
  { value:'French', label: 'French'}, 
  { value:'Furniture Design', label: 'Furniture Design'}, 
  { value:'Game Design', label: 'Game Design'}, 
  { value:'Gay and Lesbian Studies', label: 'Gay and Lesbian Studies'}, 
  { value:'Genetics', label: 'Genetics'}, 
  { value:'Geography', label: 'Geography'}, 
  { value:'Geological Engineering', label: 'Geological Engineering'}, 
  { value:'Geology', label: 'Geology'}, 
  { value:'Geophysics', label: 'Geophysics'}, 
  { value:'German', label: 'German'}, 
  { value:'Gerontology', label: 'Gerontology'}, 
  { value:'Government Major', label: 'Government Major'}, 
  { value:'Graphic Design', label: 'Graphic Design'}, 
  { value:'Health Administration', label: 'Health Administration'}, 
  { value:'Hebrew', label: 'Hebrew'}, 
  { value:'Hispanic-American, Puerto Rican, and Chicano Studies', label: 'Hispanic-American, Puerto Rican, and Chicano Studies'}, 
  { value:'Historic Preservation', label: 'Historic Preservation'}, 
  { value:'History', label: 'History'}, 
  { value:'Home Economics', label: 'Home Economics'}, 
  { value:'Horticulture', label: 'Horticulture'}, 
  { value:'Hospitality', label: 'Hospitality'}, 
  { value:'Human Development', label: 'Human Development'}, 
  { value:'Human Resources Management Major', label: 'Human Resources Management Major'}, 
  { value:'Illustration', label: 'Illustration'}, 
  { value:'Industrial Design', label: 'Industrial Design'}, 
  { value:'Industrial Engineering', label: 'Industrial Engineering'}, 
  { value:'Industrial Management', label: 'Industrial Management'}, 
  { value:'Industrial Psychology', label: 'Industrial Psychology'}, 
  { value:'Information Technology', label: 'Information Technology'}, 
  { value:'Interior Architecture', label: 'Interior Architecture'}, 
  { value:'Interior Design', label: 'Interior Design'}, 
  { value:'International Agriculture', label: 'International Agriculture'}, 
  { value:'International Business', label: 'International Business'}, 
  { value:'International Relations', label: 'International Relations'}, 
  { value:'International Studies', label: 'International Studies'}, 
  { value:'Islamic Studies', label: 'Islamic Studies'}, 
  { value:'Italian', label: 'Italian'}, 
  { value:'Japanese', label: 'Japanese'}, 
  { value:'Jazz Studies', label: 'Jazz Studies'}, 
  { value:'Jewelry and Metalsmithing', label: 'Jewelry and Metalsmithing'}, 
  { value:'Jewish Studies', label: 'Jewish Studies'}, 
  { value:'Journalism', label: 'Journalism'}, 
  { value:'Kinesiology', label: 'Kinesiology'}, 
  { value:'Korean', label: 'Korean'}, 
  { value:'Land Use Planning and Management', label: 'Land Use Planning and Management'}, 
  { value:'Landscape Architecture', label: 'Landscape Architecture'}, 
  { value:'Landscape Horticulture', label: 'Landscape Horticulture'}, 
  { value:'Latin American Studies', label: 'Latin American Studies'}, 
  { value:'Library Science', label: 'Library Science'}, 
  { value:'Linguistics', label: 'Linguistics'}, 
  { value:'Logistics Management', label: 'Logistics Management'}, 
  { value:'Management Information Systems', label: 'Management Information Systems'}, 
  { value:'Managerial Economics', label: 'Managerial Economics'}, 
  { value:'Marine Biology Major', label: 'Marine Biology Major'}, 
  { value:'Marine Science', label: 'Marine Science'}, 
  { value:'Marketing Major', label: 'Marketing Major'}, 
  { value:'Mass Communication', label: 'Mass Communication'}, 
  { value:'Massage Therapy', label: 'Massage Therapy'}, 
  { value:'Materials Science', label: 'Materials Science'}, 
  { value:'Mathematics', label: 'Mathematics'}, 
  { value:'Mechanical Engineering', label: 'Mechanical Engineering'}, 
  { value:'Medical Technology', label: 'Medical Technology'}, 
  { value:'Medieval and Renaissance Studies', label: 'Medieval and Renaissance Studies'}, 
  { value:'Mental Health Services', label: 'Mental Health Services'}, 
  { value:'Merchandising and Buying Operations', label: 'Merchandising and Buying Operations'}, 
  { value:'Metallurgical Engineering', label: 'Metallurgical Engineering'}, 
  { value:'Microbiology', label: 'Microbiology'}, 
  { value:'Middle Eastern Studies', label: 'Middle Eastern Studies'}, 
  { value:'Military Science', label: 'Military Science'}, 
  { value:'Mineral Engineering', label: 'Mineral Engineering'}, 
  { value:'Missions', label: 'Missions'}, 
  { value:'Modern Greek', label: 'Modern Greek'}, 
  { value:'Molecular Biology', label: 'Molecular Biology'}, 
  { value:'Molecular Genetics', label: 'Molecular Genetics'}, 
  { value:'Mortuary Science', label: 'Mortuary Science'}, 
  { value:'Museum Studies', label: 'Museum Studies'}, 
  { value:'Music', label: 'Music'}, 
  { value:'Music Education', label: 'Music Education'}, 
  { value:'Music History Major', label: 'Music History Major'}, 
  { value:'Music Management', label: 'Music Management'}, 
  { value:'Music Therapy', label: 'Music Therapy'}, 
  { value:'Musical Theater', label: 'Musical Theater'}, 
  { value:'Native American Studies', label: 'Native American Studies'}, 
  { value:'Natural Resources Conservation', label: 'Natural Resources Conservation'}, 
  { value:'Naval Architecture', label: 'Naval Architecture'}, 
  { value:'Neurobiology', label: 'Neurobiology'}, 
  { value:'Neuroscience', label: 'Neuroscience'}, 
  { value:'Nuclear Engineering', label: 'Nuclear Engineering'}, 
  { value:'Nursing Major', label: 'Nursing Major'}, 
  { value:'Nutrition', label: 'Nutrition'}, 
  { value:'Occupational Therapy', label: 'Occupational Therapy'}, 
  { value:'Ocean Engineering', label: 'Ocean Engineering'}, 
  { value:'Oceanography', label: 'Oceanography'}, 
  { value:'Operations Management', label: 'Operations Management'}, 
  { value:'Organizational Behavior Studies', label: 'Organizational Behavior Studies'}, 
  { value:'Painting', label: 'Painting'}, 
  { value:'Paleontology', label: 'Paleontology'}, 
  { value:'Pastoral Studies', label: 'Pastoral Studies'}, 
  { value:'Peace Studies', label: 'Peace Studies'}, 
  { value:'Petroleum Engineering', label: 'Petroleum Engineering'}, 
  { value:'Pharmacology', label: 'Pharmacology'}, 
  { value:'Pharmacy', label: 'Pharmacy'}, 
  { value:'Philosophy', label: 'Philosophy'}, 
  { value:'Photography', label: 'Photography'}, 
  { value:'Photojournalism Major', label: 'Photojournalism Major'}, 
  { value:'Physical Education', label: 'Physical Education'}, 
  { value:'Physical Therapy', label: 'Physical Therapy'}, 
  { value:'Physician Assistant', label: 'Physician Assistant'}, 
  { value:'Physics', label: 'Physics'}, 
  { value:'Physiological Psychology', label: 'Physiological Psychology'}, 
  { value:'Piano', label: 'Piano'}, 
  { value:'Planetary Science', label: 'Planetary Science'}, 
  { value:'Plant Pathology', label: 'Plant Pathology'}, 
  { value:'Playwriting and Screenwriting', label: 'Playwriting and Screenwriting'}, 
  { value:'Political Communication', label: 'Political Communication'}, 
  { value:'Political Science Major', label: 'Political Science Major'}, 
  { value:'Portuguese', label: 'Portuguese'}, 
  { value:'Pre-Dentistry', label: 'Pre-Dentistry'}, 
  { value:'Pre-Law', label: 'Pre-Law'}, 
  { value:'Pre-Medicine', label: 'Pre-Medicine'}, 
  { value:'Pre-Optometry', label: 'Pre-Optometry'}, 
  { value:'Pre-Seminary', label: 'Pre-Seminary'}, 
  { value:'Pre-Veterinary Medicine', label: 'Pre-Veterinary Medicine'}, 
  { value:'Printmaking', label: 'Printmaking'}, 
  { value:'Psychology', label: 'Psychology'}, 
  { value:'Public Administration', label: 'Public Administration'}, 
  { value:'Public Health', label: 'Public Health'}, 
  { value:'Public Policy Analysis', label: 'Public Policy Analysis'}, 
  { value:'Public Relations Major', label: 'Public Relations Major'}, 
  { value:'Radio and Television', label: 'Radio and Television'}, 
  { value:'Radiologic Technology', label: 'Radiologic Technology'}, 
  { value:'Range Science and Management', label: 'Range Science and Management'}, 
  { value:'Real Estate', label: 'Real Estate'}, 
  { value:'Recording Arts Technology', label: 'Recording Arts Technology'}, 
  { value:'Recreation Management', label: 'Recreation Management'}, 
  { value:'Rehabilitation Services', label: 'Rehabilitation Services'}, 
  { value:'Religious Studies', label: 'Religious Studies'}, 
  { value:'Respiratory Therapy', label: 'Respiratory Therapy'}, 
  { value:'Risk Management', label: 'Risk Management'}, 
  { value:'Rural Sociology', label: 'Rural Sociology'}, 
  { value:'Russian', label: 'Russian'}, 
  { value:'Scandinavian Studies', label: 'Scandinavian Studies'}, 
  { value:'Sculpture', label: 'Sculpture'}, 
  { value:'Slavic Languages and Literatures', label: 'Slavic Languages and Literatures'}, 
  { value:'Social Psychology', label: 'Social Psychology'}, 
  { value:'Social Work', label: 'Social Work'}, 
  { value:'Sociology', label: 'Sociology'}, 
  { value:'Soil Science', label: 'Soil Science'}, 
  { value:'Sound Engineering', label: 'Sound Engineering'}, 
  { value:'South Asian Studies', label: 'South Asian Studies'}, 
  { value:'Southeast Asia Studies', label: 'Southeast Asia Studies'}, 
  { value:'Spanish Major', label: 'Spanish Major'}, 
  { value:'Special Education', label: 'Special Education'}, 
  { value:'Speech Pathology', label: 'Speech Pathology'}, 
  { value:'Sport and Leisure Studies', label: 'Sport and Leisure Studies'}, 
  { value:'Sports Management', label: 'Sports Management'}, 
  { value:'Statistics Major', label: 'Statistics Major'}, 
  { value:'Surveying', label: 'Surveying'}, 
  { value:'Sustainable Resource Management', label: 'Sustainable Resource Management'}, 
  { value:'Teacher Education', label: 'Teacher Education'}, 
  { value:'Teaching English as a Second Language', label: 'Teaching English as a Second Language'}, 
  { value:'Technical Writing', label: 'Technical Writing'}, 
  { value:'Technology Education', label: 'Technology Education'}, 
  { value:'Textile Engineering', label: 'Textile Engineering'}, 
  { value:'Theatre', label: 'Theatre'}, 
  { value:'Theology', label: 'Theology'}, 
  { value:'Tourism', label: 'Tourism'}, 
  { value:'Toxicology', label: 'Toxicology'}, 
  { value:'Turfgrass Science', label: 'Turfgrass Science'}, 
  { value:'Urban Planning', label: 'Urban Planning'}, 
  { value:'Urban Studies', label: 'Urban Studies'}, 
  { value:'Visual Communication', label: 'Visual Communication'}, 
  { value:'Voice', label: 'Voice'}, 
  { value:'Web Design', label: 'Web Design'}, 
  { value:'Webmaster and Web Management', label: 'Webmaster and Web Management'}, 
  { value:'Welding Engineering', label: 'Welding Engineering'}, 
  { value:'Wildlife Management', label: 'Wildlife Management'}, 
  { value:'Womens Studies', label: 'Womens Studies'}, 
  { value:'Youth Ministries', label: 'Youth Ministries'}, 
  { value:'Zoology', label: 'Zoology'}, 
 
 ];
 const customStylesUniversity = {
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      textAlign: 'left', // Ensure the placeholder text is aligned to the left
      color: '#999' // Optional: Adjust the placeholder text color if needed
    };
  },
  control: (styles, { isFocused }) => ({
    ...styles,
    borderColor: isFocused ? '#b694f542' : '#b694f542',
    boxShadow: isFocused ? '0 0 0 1px #b694f542' : styles.boxShadow,
    '&:hover': {
      borderColor: isFocused ? '#b694f542' : '#b694f542',
    },
    width: 500,
   
  }),
 
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#b694f542' : null,
      color: isFocused ? 'black' : 'black',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isFocused ? 'purple' : 'lightpurple',
      },
      width: 480,
      textAlign: 'left',
      marginLeft: '0px' 
     
  
     
    };
    
  },
  singleValue: (styles) => ({ ...styles, textAlign: 'left'}),
  menu: (styles) => ({
    ...styles,
    maxHeight: '150px', 
    overflow: 'hidden',
    maxHeight: '300px',
    overflowY: 'hidden', // Adjust this value to change the dropdown height
  }),
  groupHeading: (styles, state) => ({
    ...styles,
    textAlign: 'left', 
    marginLeft: '0px' // Align group labels to the left
  }),
};
  const navigate = useNavigate();
  const {guide} = useUser();
  const storage = getStorage();
  const [imageUrls, setImageUrls] = useState([]);
  const [profileImageUrls, setProfileImageUrls] = useState([]);
  
  
    const [user, setUser] = useState(null);
    
    useEffect(() => {
      
      getUser().then(user => {
        if (user) {
         // alert(user.uid);
          setUser(user);
         
        } else {
          // Handle user not signed in
        }
      }).catch(error => {
        // Handle any errors
      });
       // setUser(getUser());
       
       //read();
       
     }, []);
     useEffect(() => {
      if(user){
        setUserEmail(user.email);
        setUserUID(user.uid);
      read();
      read_new();
      fetchImages(setImageUrls);
    //  fetchProfileImages(setProfileImageUrls);
      fetchProfileImages_db(setProfileImageUrls);
      if(onboarded === "1" || onboarded == 1){
        updateOnboarded(user.uid);
        setAccountVal(2);
      document.getElementById("account2").classList.add("account_selected");
      document.getElementById("account0").classList.remove("account_selected");
      document.getElementById("account1").classList.remove("account_selected");
      }
      }
     }, [user])
    async function read(){
    // alert(user.uid);
        const docRef = doc(db, "tours", user.uid);
       // alert(user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUniversity(docSnap.data().university);
            setGraduationYear(docSnap.data().graduationYear);
            setMajors(docSnap.data().majors);
            setMinors(docSnap.data().minors);
            setEcs(docSnap.data().ecs);
            setBio(docSnap.data().bio);
            setName(docSnap.data().name);
            console.log("Document data:", docSnap.data());
           
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
    }

    const [db_name, set_db_name] = useState('');
  const [db_phone_number, set_db_phone_number] = useState('');
  const [db_graduation_year, set_db_graduation_year] = useState('');
  const [db_university, set_db_university] = useState(null);
  const [db_majors, set_db_majors] = useState([]);
  const [db_minors, set_db_minors] = useState([]);
  //added_categories
  const [db_bio, set_db_bio] = useState('');
  const [db_description, set_db_description] = useState('');
  const [db_location, set_db_location] = useState('');
    async function read_new(){
      // alert(user.uid);
          const docRef = doc(db, "guide_tours", user.uid);
         // alert(user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
              set_db_university(docSnap.data().university);
              set_db_graduation_year(docSnap.data().graduationYear);
              set_db_majors(docSnap.data().majors);
              set_db_minors(docSnap.data().minors);
              set_db_bio(docSnap.data().bio);
              set_db_name(docSnap.data().name);
              set_db_phone_number(docSnap.data().phoneNumber);
              set_db_description(docSnap.data().description);
              setHas_onboarded(docSnap.data().onboarded)
              set_db_location(docSnap.data().location);
              updateCategories(docSnap.data().ecs, docSnap.data().ecsCategories);
              setaId(docSnap.data().stripe_account_id);
              console.log("Document data:", docSnap.data());
            } else {
              // docSnap.data() will be undefined in this case
              console.log("No such document!");
            }
      }
      async function updateTour(){


        const array1 = [];
        const array2 = [];
        
        for (let i = 0; i < addedCategories.length; i++) {
          const row = addedCategories[i];
          
          // Push elements into the respective 1D arrays
          for (let j = 0; j < row.length; j++) {
            if (j === 0) {
              array1.push(row[j]);
            } else if (j === 1) {
              array2.push(row[j]);
            }
          }
        }
        await updateDoc(doc(db, "guide_tours", userUID), {
           university: db_university,
           graduationYear: db_graduation_year,
           majors: db_majors,
           minors: db_minors,
           ecs: array1,
           ecsCategories: array2,
           bio: db_bio,
           name: db_name,
           phoneNumber: db_phone_number,
           description: db_description,
           location: db_location,
         });
        
         navigate("/Account/0");
        
       }
   
    async function updateOnboarded(id){
      const washingtonRef = doc(db, "guide_tours", id);
      setHas_onboarded(true);
      await updateDoc(washingtonRef, {
        onboarded: true
      });
    }
      function updateCategories(ecs, ecsCategories){
        setAddedCategories([]);
        const arr = [];
        for(let i = 0; i < ecs.length; i++){
          arr.push([ecs[i], ecsCategories[i]]);
        }
        setAddedCategories(arr);
      }
  const [userUID, setUserUID] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [university, setUniversity] = useState('');
  const [graduationYear, setGraduationYear] = useState('');
  const [majors, setMajors] = useState('');
  const [minors, setMinors] = useState('');
  const [ecs, setEcs] = useState('');
  const [bio, setBio] = useState('');
  const [name, setName] = useState('');
  const [sunday, setSunday] = useState('not-selected');
  const [monday, setMonday] = useState('not-selected');
  const [tuesday, setTuesday] = useState('not-selected');
  const [wednesday, setWednesday] = useState('not-selected');
  const [thursday, setThursday] = useState('not-selected');
  const [friday, setFriday] = useState('not-selected');
  const [saturday, setSaturday] = useState('not-selected');

  const [daysArray, setDaysArray] = useState([]);
  function changeSunday(){
    if(sunday === 'selected'){
      setSunday('not-selected');
      const filteredDays = daysArray.filter(item => item !== 'sunday');
      setDaysArray(filteredDays);
    }
    else{
      setSunday('selected');
      setDaysArray([...daysArray, 'sunday']);
      
    }
  }
  function changeMonday(){
    if(monday === 'selected'){
      setMonday('not-selected');
      const filteredDays = daysArray.filter(item => item !== 'monday');
      setDaysArray(filteredDays);
    }
    else{
      setMonday('selected');
      setDaysArray([...daysArray, 'monday']);
    }
  }
  function changeTuesday(){
    if(tuesday === 'selected'){
      setTuesday('not-selected');
      const filteredDays = daysArray.filter(item => item !== 'tuesday');
      setDaysArray(filteredDays);
    }
    else{
      setTuesday('selected');
      setDaysArray([...daysArray, 'tuesday']);
    }
  }
  function changeWednesday(){
    if(wednesday === 'selected'){
      setWednesday('not-selected');
      const filteredDays = daysArray.filter(item => item !== 'wednesday');
      setDaysArray(filteredDays);
    }
    else{
      setWednesday('selected');
      setDaysArray([...daysArray, 'wednesday']);
    }
  }
  function changeThursday(){
    if(thursday === 'selected'){
      setThursday('not-selected');
      const filteredDays = daysArray.filter(item => item !== 'thursday');
      setDaysArray(filteredDays);
    }
    else{
      setThursday('selected');
      setDaysArray([...daysArray, 'thursday']);
    }
  }
  function changeFriday(){
    if(friday === 'selected'){
      setFriday('not-selected');
      const filteredDays = daysArray.filter(item => item !== 'friday');
      setDaysArray(filteredDays);
    }
    else{
      setFriday('selected');
      setDaysArray([...daysArray, 'friday']);
    }
  }
  function changeSaturday(){
    if(saturday === 'selected'){
      setSaturday('not-selected');
      const filteredDays = daysArray.filter(item => item !== 'saturday');
      setDaysArray(filteredDays);
    }
    else{
      setSaturday('selected');
      setDaysArray([...daysArray, 'saturday']);
    
    }
  }
  const handleUniversity = (event) => {
    setUniversity(event.target.value);
  }
  const handleGraduationYear = (event) => {
    setGraduationYear(event.target.value);
  }
  const handleMajors = (event) => {
    setMajors(event.target.value);
  }
  const handleMinors = (event) => {
    setMinors(event.target.value);
  }
  const handleEcs = (event) => {
    setEcs(event.target.value);
  }
  const handleBio = (event) => {
    setBio(event.target.value);
  }
  const handleName = (event) => {
    setName(event.target.value);
  }
 async function createTour(){
    const docRef = doc(db, "tours", user.uid);
    await updateDoc(docRef, {
      email: userEmail,
      uid: userUID,
      university: university,
      graduationYear: graduationYear,
      majors: majors,
      minors: minors,
      ecs: ecs,
      bio: bio,
      name: name

    });
    
  }
   

function uploadImages() {
  const files = document.getElementById('imageUpload').files;
  Array.from(files).forEach(file => {
    // Create a storage reference
    const storageRef = ref(storage, `${user.uid}/` + file.name);

    // Upload file
    uploadBytes(storageRef, file).then((snapshot) => {
      setImageUrls([]);
      fetchImages(setImageUrls);
      console.log('Uploaded a blob or file!', snapshot);
    }).catch(error => {
      console.error('Error uploading file:', error);
    });
  });
  
}
function uploadProfileImage() {
  const files = document.getElementById('profileImageUpload').files;
  Array.from(files).forEach(file => {
    // Create a storage reference
    const storageRef = ref(storage, `profile/${user.uid}/profile`);

    // Upload file
    uploadBytes(storageRef, file).then((snapshot) => {
      setProfileImageUrls([]);
      fetchProfileImages(setProfileImageUrls);
      console.log('Uploaded a blob or file!', snapshot);
    }).catch(error => {
      console.error('Error uploading file:', error);
    });
  });
  
}

function fetchImages(setImageUrls) {
  const imagesRef = ref(storage, `${user.uid}/`); // Adjust 'images/' to your specific path

  listAll(imagesRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        // Get the download URL for each image
        getDownloadURL(itemRef).then((url) => {
          setImageUrls(prevUrls => [...prevUrls, url]);
        });
      });
    }).catch((error) => {
      console.error("Error fetching images:", error);
    });
}
function fetchProfileImages(setProfileImageUrls) {
  const imagesRef = ref(storage, `profile/${user.uid}/`); // Adjust 'images/' to your specific path
  
  listAll(imagesRef)
    .then((res) => {
     
      res.items.forEach((itemRef) => {
        // Get the download URL for each image
        getDownloadURL(itemRef).then((url) => {
          setProfileImageUrls(prevUrls => [...prevUrls, url]);
        });
      });
    }).catch((error) => {
      console.error("Error fetching images:", error);
    });
}

function fetchProfileImages_db(setProfileImageUrls) {
  const imagesRef = ref(storage, `profile_images/${user.uid}/`); // Adjust 'images/' to your specific path
  
  listAll(imagesRef)
    .then((res) => {
     
      res.items.forEach((itemRef) => {
        // Get the download URL for each image
        getDownloadURL(itemRef).then((url) => {
          setProfileImageUrls(prevUrls => [...prevUrls, url]);
        });
      });
    }).catch((error) => {
      console.error("Error fetching images:", error);
    });
}

function out(){
  signOut(auth);
  navigate('/');
}
const [showTimes, setShowTimes] = useState(false);
function changeTimes(){
setShowTimes(!showTimes);
}
const [selectedPhoto, setSelectedPhoto] = useState(null);

// Function to handle file selection
const handleFileSelect = (event) => {
  const file = event.target.files[0];
  if (file) {
    // Use FileReader to read the file and set it as selectedPhoto
    const reader = new FileReader();
    reader.onload = (e) => {
      setSelectedPhoto(e.target.result);
    };
    reader.readAsDataURL(file);
  }
  const storageRef = ref(storage, `profile_images/${userUID}/profile`);

    // Upload file
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log('Uploaded a blob or file!', snapshot);
    }).catch(error => {
      console.error('Error uploading file:', error);
    });
}
const fileInputRef = useRef(null);
const handleUploadButtonClick = () => {
  fileInputRef.current.click();
};
const [accountVal, setAccountVal] = useState(0);
const handleAccountChange = (i) => {
 
 if(i == 0){
  if(i !== accountVal){
  document.getElementById("account0").classList.toggle("account_selected");
  }
  document.getElementById("account1").classList.remove("account_selected");
  document.getElementById("account2").classList.remove("account_selected");
 }
 else if(i == 1){
  if(i !== accountVal){
  document.getElementById("account1").classList.toggle("account_selected");
  }
  document.getElementById("account0").classList.remove("account_selected");
  document.getElementById("account2").classList.remove("account_selected");
 }
 else if(i == 2){
  if(i !== accountVal){
  document.getElementById("account2").classList.toggle("account_selected");
  }
  document.getElementById("account0").classList.remove("account_selected");
  document.getElementById("account1").classList.remove("account_selected");
 }
 setAccountVal(i);
}
const options = [
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' }
]
const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
   
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderColor: isFocused ? '#b694f542' : '#b694f542',
    boxShadow: isFocused ? '0 0 0 1px #b694f542' : styles.boxShadow,
    '&:hover': {
      borderColor: isFocused ? '#b694f542' : '#b694f542',
    },
  }),
  option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? '#b694f542' : null,
      color: isFocused ? 'black' : 'black',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isFocused ? 'purple' : 'lightpurple',
      },
    
  }),
 
};


const [currSelectedCategoriesId, setCurrSelectedCategoriesId] = useState(0);
const [categoryClick, setCategoryClicked] = useState('');
const [prevId, setPrevId] = useState('');
const [created, setCreated] = useState('');
const handleClick = (event) => {
  let id = event.target.id;
  let element = event.target;
  
  while(!id){
  id = element.parentElement.id;
  element = element.parentElement;
  }
  if(prevId !== id){
  document.getElementById(id).classList.add("categoryChoiceSelected");
  document.getElementById(id).classList.remove("categorySelected");
  setCategoryClicked(document.getElementById(id).firstChild.nextSibling.firstChild.innerHTML);
  }
  if(prevId !== '' && prevId !== id){
    document.getElementById(prevId).classList.add("categoryChoice");
  document.getElementById(prevId).classList.remove("categoryChoiceSelected");
  
  }
  if(prevId !== '' && prevId === id){
    document.getElementById(prevId).classList.add("categoryChoice");
  document.getElementById(prevId).classList.remove("categoryChoiceSelected");
  setPrevId('');
  }
  else{
  setPrevId(id);
  } 
  }

  const handleCategoryClick = (event) => {
    let str = event.target.id;
   
    let c = document.getElementById(event.target.id).parentElement.parentElement.parentElement.firstChild.firstChild.innerHTML;
   
    setAddedCategories([...addedCategories, [event.target.innerHTML, c]]);
   
   }
   const handleSearchedCategoryClick = (str) => {
    setCreated(str);
    // setAddedCategories([...addedCategories, str]);
   }
   const handleAlreadyCategory = (str, categ) => {
   
     setAddedCategories([...addedCategories, [str, categ]]);
   
    }

   const unSelect = (event) => {
     let t= event.target.innerHTML;
    // alert(t.slice(0, t.length - 14));
     //get the id then set your selected id to that
     setAddedCategories(addedCategories.filter(item => {
       // Assuming item is an array
       // Check the first dimension of each item
       return item[0] !== t.slice(0, t.length - 15);
   }));
    
   }
   const sports = [
    "Cross Country",
"Field Hockey",
"Football",
"Soccer",
"Volleyball ",
"Water Polo",
"Basketball",
"Bowling",
"Fencing",
"Field Hockey",
"Gymnastics",
"Ice Hockey",
"Skiing",
"Swimming",
"Track & Field",
"Wrestling",
"Baseball",
"Softball",
"Golf",
"Lacrosse",
"Rowing",
"Tennis",
"Volleyball"];
const volunteering_organizations = ["Tutoring/mentoring",
"Environmental conservation",
"Elderly care",
"Homelessness outreach",
"Animal welfare",
"Healthcare support",
"Youth development",
"Disaster relief",
"Community gardening",
"Literacy promotion",
"Civic engagement",
"Arts and culture",
"Veterans support",
"Hunger relief",
"Community infrastructure",
"Mental Health",
"LGBTQ+ Support",
"Refugee Assistance",
"Substance Abuse",
"Disability Rights",
"Racial Justice",
"Tech Literacy",
"Research Programs",
"Conflict Resolution",
"Global Outreach"
];


const [currStemId, setCurrStemId] = useState(0);
const stem_organizations = [
"Aerospace Engineering",
"Artificial Intelligence Society",
"Astronomy Society",
"Biology Association",
"Chemical Engineering",
"Chemistry Society",
"Civil Engineering",
"Computer Engineering",
"Computer Science",
"Data Science",
"Electrical Engineering",
"Electronics",
"Engineering Design",
"Environmental Engineering",
"Environmental Science",
"Forensic Science",
"Geology",
"Geotechnical Engineering",
"Industrial Engineering",
"Marine Biology Society",
"Materials Engineering",
"Materials Science",
"Mathematics Olympiad",
"Mathematics Society",
"Mechanical Engineering",
"Medical Technology",
"Nanotechnology Society",
"Nuclear Engineering",
"Petroleum Engineering",
"Physics Society",
"Renewable Energy",
"Robotics",
"Rocketry Team",
"Science Olympiad",
"STEM Outreach Program",
"STEM Research Society",
"Structural Engineering",
"Systems Engineering",
"Women in STEM (WiSTEM)"];
const arts_organizations = [
"Calligraphy",
"Ceramics",
"Choir or Chorus",
"Collage",
"Creative Writing",
"Cultural Dance",
"Culinary Arts",
"Dance",
"Fashion Design",
"Film",
"Fine Arts",
"Glassblowing",
"Graffiti/Street Art",
"Graphic Design",
"Improv Comedy",
"Installation Art",
"Jewelry Making",
"Land Art",
"Literary Magazine",
"Mosaic Art",
"Music",
"Painting",
"Performance Art",
"Photography",
"Poetry",
"Printmaking",
"Sculpture",
"Textiles",
"Theater",
"Video Art",
"Visual Arts",
"Woodworking"];
const health_organizations = ["Cycling",
"Dance Fitness",
"Exercise Science",
"Hiking",
"Jogging",
"Kickboxing",
"Meditation",
"Nutrition",
"Outdoor Fitness",
"Pilates",
"Qi Gong",
"Running",
"Skiing",
"Snowboarding",
"Surfing",
"Tai Chi",
"Vegan",
"Vegetarian",
"Weightlifting",
"Yoga",
"Zumba"];
const gaming_organizations = ["Esports",
"Video Games",
"Board Games",
"RPG Club",
"Card Games",
"Chess",
"LAN Party",
"Mobile Gaming",
"Retro Gaming",
"Virtual Reality (VR)",
"Game Development",
"Game Design",
"Game Journalism"];
const music_organizations = ["A Capella",
"Accordion",
"Banjo",
"Bass Guitar",
"Cello",
"Chamber Orchestra",
"Clarinet",
"Comedy",
"Dance",
"Drama",
"Double Bass",
"Drum Kit",
"Electric Guitar",
"Flute",
"French Horn",
"Harmonica",
"Harp",
"Hip Hop Dance",
"Improv Comedy",
"Instrumental",
"Jazz Band",
"Mandolin",
"Musical Theater",
"Music Ensemble",
"Oboe",
"Opera",
"Performance Art",
"Piano",
"Saxophone",
"Shakespearean Society",
"Show Choir",
"Spoken Word Poetry Club",
"Stage Crew",
"Tap Dance",
"Trombone",
"Trumpet",
"Ukulele",
"Violin"];
const leadership_organizations = ["4-H Club",
"Alpha Phi Omega (APO)",
"Beta Club",
"Circle K International",
"DECA",
"FCCLA",
"Phi Beta Lambda (FBLA-PBL)",
"Future Educators Association (FEA)",
"Future Farmers of America (FFA)",
"Future Leaders of America (FLA)",
"Future Problem Solvers (FPS)",
"Girl Scouts/Boy Scouts of America",
"Golden Key International Honour Society",
"HOSA - Future Health Professionals",
"Junior Achievement",
"Key Club International",
"Kiwanis International",
"Leadership Conferences and Workshops",
"Leadership Development Program (LDP)",
"Lions Clubs International",
"Model United Nations (MUN)",
"National Honor Society (NHS)",
"National Society of Collegiate Scholars (NSCS)",
"National Society of Leadership and Success (NSLS)",
"Order of Omega",
"Residence Hall Associations (RHA)",
"Rotary International",
"SkillsUSA",
"Student Activities Board (SAB)",
"Student Ambassador Programs",
"Student Council",
"Student Leadership Advisory Board (SLAB)",
"Student-led Consulting Groups",
"Toastmasters International",
"Youth Leadership America"];
const socialjustice_organizations = ["Amnesty International",
"Anti-Violence Coalition",
"Asian Student Union",
"Black Student Union",
"Disability Rights Advocates",
"Feminist Collective",
"Human Rights Watch Student Task Force",
"Immigration Justice Coalition",
"Indigenous Peoples Alliance",
"Interfaith Alliance",
"LGBTQ+ Alliance",
"LGBTQ+ Rights Advocacy Group",
"Mental Health Advocacy Group",
"Multicultural Student Organization",
"NAACP",
"Peace and Justice Club",
"Refugee Support Network",
"Students Against Racism",
"Students for Environmental Action",
"United Nations Association",
"Womens Rights"];
const diversity_organizations = ["Allies for Inclusion",
"Cultural Diversity Club",
"Disability Services Office",
"Diversity Ambassadors Program",
"Diversity Council",
"Diversity Leadership Retreat",
"Ethnic Student Association",
"First-Gen",
"Inclusion Task Force",
"Interfaith Diversity Group",
"International Student Association",
"LGBTQ+ Resource Center",
"Multicultural Affairs Office",
"Social Justice Institute",
"Women's Resource Center"];
const career_organizations = ["Alumni Networking",
"Business Professional Association",
"Career Coaching",
"Career Fairs and Expos",
"Career Panels and Guest Speakers",
"Career Services Center",
"Co-ops",
"Employer Information Sessions",
"Entrepreneurship",
"Financial Literacy",
"Graduate School Preparation",
"Human Resources",
"Internship Opportunities",
"Interview Preparation",
"Job Search Support",
"Job Shadowing",
"Leadership Conferences",
"Leadership Development",
"Mentorship Program",
"Networking Events",
"Online Career Resources",
"Professional Association Memberships",
"Professional Development Seminars",
"Resume Building Workshops",
"Skill-Building Workshops",
"Work-Study Program",
"Young Professionals Group"];
const religion_organizations = ["Baha'i Campus Association",
"Buddhist Student Association",
"Catholic Campus Ministry",
"Chabad Jewish Student Organization",
"Christian Fellowship",
"Episcopal Campus Ministry",
"Hindu Students Council",
"Interfaith Student Organization",
"Islamic Society",
"Jewish Student Union",
"Latter-day Saint Student Association (LDS)",
"Lutheran Campus Ministry",
"Muslim Student Association (MSA)",
"Newman Center (Catholic)",
"Orthodox Christian Fellowship",
"Presbyterian Campus Ministry",
"Sikh Student Association",
"Unitarian Universalist Campus Ministry",
"Wesley Foundation (United Methodist)",
"Zen Meditation Group"];

const continueClick = () => {
  setAddedCategories([...addedCategories, [created, categoryClick]]);
  setCreated('');

}
const scrollSelectedCategories = () => {
     
  /*    let element = document.getElementById("selected" + (currSelectedCategoriesId + 3));
      if(element){
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        setCurrSelectedCategoriesId(currSelectedCategoriesId + 3);
       
      }
      else{
        let element = document.getElementById("selected" + (currSelectedCategoriesId));
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        
      }
      */
      let element = document.getElementById("selected" + (currSelectedCategoriesId + 3));
      if(element){
        var leftPos = element.offsetLeft - 150;
        var container = element.parentElement;
      
        container.scrollTo({
          left: leftPos,
          behavior: 'smooth' // Optionally, use smooth scrolling
        });
        setCurrSelectedCategoriesId(currSelectedCategoriesId + 3);
      }
      else{
        var leftPos = element.offsetLeft - 150;
        let element = document.getElementById("selected" + (currSelectedCategoriesId));
        var container = element.parentElement;
        container.scrollTo({
          left:leftPos,
          behavior: 'smooth' // Optionally, use smooth scrolling
        });
        
      }
    
    }
    const scrollSelectedLeft = () => {
      let element = document.getElementById("selected" + (currSelectedCategoriesId - 3));
      if(element){
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        setCurrSelectedCategoriesId(currSelectedCategoriesId - 3);
      }
    }
    const handle_db_location = (event) => {
      set_db_location(event.target.value);
    }
    const handle_db_name = (event) => {
      set_db_name(event.target.value);
    }
    const handle_db_graduation_year = event => {
      set_db_graduation_year(event);
    }
    const handle_db_university = event => {
     set_db_university(event);
    };
    const handle_db_majors = event => {
      set_db_majors(event);
    }
    const handle_db_minors = event => {
      set_db_minors(event);
    }
    const handle_db_bio = (event) => {
      set_db_bio(event.target.value);
    }
    const handle_db_description = (event) => {
      set_db_description(event.target.value);
    }
    const [phoneNumber, setPhoneNumber] = useState(null);
    const handleChange = (e) => {
      let input = e.target.value.replace(/[^\d]/g, ''); // Remove non-digits
      // Format as the user types
      if (input.length > 3 && input.length <= 6) {
        input = `${input.slice(0, 3)}-${input.slice(3)}`;
      } else if (input.length > 6) {
        input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
      }
      setPhoneNumber(input);
      set_db_phone_number(input);
      
      
    };
    
    const handleDashInsertion = (e) => {
      let { value } = e.target;
      if (value.length === 3 || value.length === 7) {
        // Add dash after 3rd and 7th character (which is after 3rd and 6th digit)
        setPhoneNumber(value + '-');
      } else {
        handleChange(e);
      }
    };
    const [loading, setLoading] = useState(false);
    const [accountLink, setAccountLink] = useState(null); // State variable to store the account link
    const [loginLink, setLogInLink] = useState(null);
    const handleCreateAccount = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://us-central1-toouurs-f9210.cloudfunctions.net/createAccount');
        if (!response.ok) {
          throw new Error('Failed to create account link');
        }
        const data = await response.json();
        console.log(data);
        handleCreateAccountLink(data);
        // Store the generated account link in the state variable
      } catch (error) {
        console.error('Error generating account link:', error);
      } finally {
        setLoading(false);
      }
    };
    const handleCreateAccountLink = async (accountId) => {
      setAccountId(accountId);
      setaId(accountId);
      try {
        const response = await fetch('https://us-central1-toouurs-f9210.cloudfunctions.net/createAccountLinkkk', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify( {accountId} ),
        });
        if (!response.ok) {
          throw new Error('Failed to create account link');
        }
        const link = await response.json();
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          // true for mobile device

          setTimeout(function(){document.location.href = link.url;},250);
        }else{
          // false for not mobile device
          window.open(link.url, "_blank");
        }
        setAccountLink(link.url);
        updateAccountLink(link.url);
        // Store the generated account link in the state variable
      } catch (error) {
        console.error('Error generating account link:', error);
      } finally {
        setLoading(false);
      }
    };
    const handleCreateLoginLink = async () => {
      try {
        const response = await fetch('https://us-central1-toouurs-f9210.cloudfunctions.net/createLoginLink', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify( { aId } ),
        });
        if (!response.ok) {
          throw new Error('Failed to create account link');
        }
        const link = await response.json();
        window.open(link.url, "_blank");
     //   setLogInLink(link.url);
        // Store the generated account link in the state variable
      } catch (error) {
        console.error('Error generating account link:', error);
      } finally {
        setLoading(false);
      }
    };
   
    const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
    
    
  
    async function setAccountId(accountId){
      await updateDoc(doc(db, "guide_tours", userUID), {
        stripe_account_id: accountId
      });
    }
    async function updateAccountLink(account_link){
      await updateDoc(doc(db, "guide_tours", userUID), {
        stripe_account_link: account_link
      });
    }
    const [menu, setMenu] = useState(false);
  const explore = () => {
    document.body.style.overflowY = 'auto';
    navigate('/Feed');
   }
   const signup = () => {
    document.body.style.overflowY = 'auto';
    navigate('/JoinGuide');
   }
   const signin = () => {
    document.body.style.overflowY = 'auto';
    navigate('/Signin');
   }
   function openNav(){
    setMenu(true);
    document.body.style.overflow = 'hidden';
   }
   function setMenuFalse(){
    setMenu(false);
    document.body.style.overflowY = 'auto';
   }
   const dashboard = () => {
    document.body.style.overflowY = 'auto';
    navigate('/GuideTours');
   }
   const account = () => {
    document.body.style.overflowY = 'auto';
    navigate('/Account/0');
   }
  return (
    <div className="App11">
      <NavBar page="account" border={false} color={false} onClick={openNav}></NavBar>
      
      <div className='account_holder'>
      <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svgg'>
        {/* Define linear gradient for the background */}
        <defs>
          {/* Define linear gradient animation */}
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#4158D0">
              <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="46%" stopColor="#C850C0">
              <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#FFCC70">
              <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
       
        {/* Draw the paths with their respective gradients */}
        <path d="M 0 50 C 50 50 300 0 500 150 L 500 0 L 0 0" fill="url(#gradient1)" />
      </svg>
      {menu && <div className='menu_nav'>
    <div className='menu_nav_inner'>
      
      <img onClick={setMenuFalse} src={require('./exit.png')} alt="exit" className='menu_nav_exit'></img>

      <div className='nav_inner_holder'>
      <p onClick={explore}>Explore</p>
      <p onClick={dashboard}>Dashboard</p>
      <p onClick={account}>My Account</p>
      </div>
    </div>
    </div> }
        <div className='account_holder_inner'>
        <div className='account_nav'>
         
          <div className='account_nav_inner'>
        <h1 className='account_nav_text'>My Account</h1>
        <h2 className='account_nav_text account_selected' id="account0" onClick={() => handleAccountChange(0)}>My Profile</h2>
        <h2 className='account_nav_text' id="account1" onClick={() => handleAccountChange(1)}>Tour information</h2>
        <h2 className='account_nav_text' id="account2" onClick={() => handleAccountChange(2)}>Payment</h2>
        </div>

      </div>
      {accountVal == 0 && 
      <div className='account_content'>

        <div className='account_content_inner'>
          <h1 className='account_content_header'>My Profile</h1>
          <p className='account_header'>General Information</p>
          <div className='profile1'>
          <div className='profile_holder1'>
        <div className='profile_bg1'>
        {selectedPhoto && (
        
          <img src={selectedPhoto} alt="Uploaded" />
       
      )}
          {!selectedPhoto && profileImageUrls &&
          
          <img src={profileImageUrls[0]}alt="profile image" /> 
}
{!selectedPhoto && !profileImageUrls[0] &&
          
          <img src={require('./circle.png')}alt="profile image" /> 
}
        </div>
        <div className='profile_bottom1' onClick={handleUploadButtonClick} >
          <p>Edit profile image</p>
          </div>
        </div>
        
        <input
        id="fileInput"
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        style={{ display: 'none' }} // Hide the default file input button
      />
      <div className='account_profile_right'>
      <div className='names'>
      <div className='inputTextHolder_account'>
          <div className='inputTextHolderTop'>
          <p>Name</p>
          </div>
          <input type="text" className='inputText' onChange={handle_db_name} value={db_name} placeholder='Full name'></input>
        </div>
        <div className='inputTextHolder_account'>
          <div className='inputTextHolderTop'>
          <p>Phone</p>
          </div>
          <input type="text"  
     
        className='inputText' onChange={handleDashInsertion} placeholder='Phone number' value={db_phone_number}></input>
        </div>
      </div>
       <div className='inputBoxTextHolder1_account'>
          
          <div className='inputBoxTextHolderTop1'>
          <p>Bio</p>
          </div>
          <textarea  className='inputBoxText1_1_1' onChange={handle_db_bio} value={db_bio} placeholder='In one or two sentences describe who you are - your hook!'></textarea>
        </div>
        </div>
          </div>

          <p className='account_header'>Academic Information</p>
          <div className='profile2'>
          <div className='inputTextHolder11'>
          <div className='inputTextHolderTop'>
          <p>University</p>
          </div>
          <Select options={universitiess} onChange={handle_db_university} value={db_university} styles={customStylesUniversity}  placeholder="Select your university"/>
        </div>
        <div className='inputTextHolder11'>
          <div className='inputTextHolderTop'>
          <p>Major</p>
          </div>
          <Select options={majorsss} value={db_majors} onChange={handle_db_majors} styles={customStylesUniversity}  placeholder="Select your majors" isMulti/>
        </div>
        <div className='inputTextHolder11'>
          <div className='inputTextHolderTop'>
          <p>Minor</p>
          </div>
          <Select options={majorsss} value={db_minors} onChange={handle_db_minors} styles={customStylesUniversity}  placeholder="Take any minors?" isMulti/>
        </div>

        <div className='inputTextHolder11'>
        <div className='inputTextHolderTop'>
        <p>Graduation Year</p>
          </div>
        <Select options={options} onChange={handle_db_graduation_year}  styles={customStyles} value={db_graduation_year}/>
        </div>
      
          </div>


          <div className='guideContentHolder11'>
        {created !== '' && <div className='full'>
        <div className='blur'></div>
        <div className='categoryChoose1'>
          <p>Select the category to which '{created}' most closely relates to</p>
          <div className='categoryChooseScroll'>
            <div className='categoryRow'>
              <CategoryChoice src={require('./volunteer.png')} categoryName="volunteer" handleClick={handleClick} i="0"/>
              <CategoryChoice src={require('./stem1.png')} categoryName="STEM" handleClick={handleClick} i="1"/>
              <CategoryChoice src={require('./palette1.png')} categoryName="Art"handleClick={handleClick} i="2"/>
            
              </div>
              
              <div className='categoryRow'>
              <CategoryChoice src={require('./career.png')} categoryName="Career"handleClick={handleClick} i="3"/>
              <CategoryChoice src={require('./education.png')} categoryName="Academic"handleClick={handleClick} i="4"/>
              <CategoryChoice src={require('./leadership.png')} categoryName="Leadership" handleClick={handleClick}i="5"/>
              </div>
              <div className='categoryRow'>
              <CategoryChoice src={require('./religion.png')} categoryName="Religion" handleClick={handleClick}i="6"/>
              <CategoryChoice src={require('./culture.png')} categoryName="Culture" handleClick={handleClick}i="7"/>
              <CategoryChoice src={require('./music.png')} categoryName="Music" handleClick={handleClick}i="8"/>
              </div>
              <div className='categoryRow'>
              <CategoryChoice src={require('./sports.png')} categoryName="Sports" handleClick={handleClick}i="9"/>
              <CategoryChoice src={require('./dumbbell.png')} categoryName="Fitness"handleClick={handleClick} i="10"/>
              <CategoryChoice src={require('./socialjustice1.png')} categoryName="Social Justice" handleClick={handleClick}i="11"/>
              </div>
             
          </div>
          <div className='bottom'>
            <div className='bottomRight1'>
              {prevId !== '' && <div className='continue' onClick={continueClick}>
                <p>Select</p>
                </div> }
                {prevId === '' && <div className='continue1'>
                <p>Select</p>
                </div> }
              </div>
            </div>
        </div>
        </div>
}
<p className='account_header'>Your Interests</p>
        {addedCategories.length == 0 && <div className='selectedCategoriesHolder'> 
         <p className='addInterestsText'>Add some interests</p>
         </div>

}
        {addedCategories.length > 0 && 
        <div className='fixedSelectedCategories'>
            { currSelectedCategoriesId > 0 && <div className='scrollLeft' onClick={scrollSelectedLeft}>
               <img src={require('./ar1.png')} alt="more categories" ></img>
            </div>
          }
          {currSelectedCategoriesId> 0 &&  <div className='fadeLeft'></div>}
        <div className='selectedCategoriesHolder' id="categoriesSelected">
   {     addedCategories.map((categoryName, index) => {
             if(index === 0){
              return (
                <button className='categoryNameFirst' id="selected0" onClick={unSelect}>{categoryName[0]} <span>&#10005;</span></button>
              )
             }
             else{
              return (
                <button className='categoryName' key={index} id={"selected" + index} onClick={unSelect}>{categoryName[0]} <span>&#10005;</span></button>
              );
             }
                
             
              
})}
 
        </div>
        {currSelectedCategoriesId < addedCategories.length - 3 &&   <div className='fadeRight'></div> }
 {currSelectedCategoriesId < addedCategories.length - 3 &&  <div className='scroll' onClick={scrollSelectedCategories}>
               <img src={require('./ar1.png')} alt="more categories" ></img>
              </div>
            }
        </div>
}



        <div className='categoriesHolder'>
         
        
         <T items={sports} handleCategoryClick={handleCategoryClick} categoryName="Sports"/>
         <br></br>
         <T items={volunteering_organizations} handleCategoryClick={handleCategoryClick} categoryName="Volunteering"/>
         <br></br>
         <T items={stem_organizations} handleCategoryClick={handleCategoryClick} categoryName="STEM"/>
         <br></br>
         <T items={arts_organizations} handleCategoryClick={handleCategoryClick} categoryName="Arts"/>
         <br></br>
         <T items={music_organizations} handleCategoryClick={handleCategoryClick} categoryName="Music"/>
         <br></br>
         <T items={career_organizations} handleCategoryClick={handleCategoryClick} categoryName="Career/Pre-professional"/>
         <br></br>
         <T items={health_organizations} handleCategoryClick={handleCategoryClick} categoryName="Health/Fitness"/>
         <br></br>
         <T items={religion_organizations} handleCategoryClick={handleCategoryClick} categoryName="Religious"/>
         <br></br>
         <T items={diversity_organizations} handleCategoryClick={handleCategoryClick} categoryName="Diversity"/>
         <br></br>
         <T items={socialjustice_organizations} handleCategoryClick={handleCategoryClick} categoryName="Social Justice"/>
         <br></br>
         <T items={leadership_organizations} handleCategoryClick={handleCategoryClick} categoryName="Leadership"/>
         <br></br>
         <T items={gaming_organizations} handleCategoryClick={handleCategoryClick} categoryName="Gaming"/>   
          
         <div className='fade'></div>
        </div>
        
        <div className='inputTextHolder1'>
          <div className='inputTextHolderTop'>
          <p>Type an interest and press enter to add</p>
          </div>
          {/*<input type="text" className='interests' placeholder='Interests...'></input> */}
          <SearchComponent handleAlreadyCategory={handleAlreadyCategory} handleSearchedCategoryClick={handleSearchedCategoryClick}/>
        </div>
        
      </div>
      <button className='all-set-div' style={{width: '180px'}} onClick={updateTour}>Save Changes</button>
        </div>
        </div>
}
{accountVal == 1 && 
      <div className='account_content'>

        <div className='account_content_inner'>
          <h1 className='account_content_header'>My Tour Information</h1>
          <p className='account_header'>Tour Description</p>
          <div className='profile11'>
          <div className='inputBoxTextHolder111'>
          <div className='inputBoxTextHolderTop'>
          <p>Description</p>
          </div>
          <textarea  className='inputBoxText1234' onChange={handle_db_description} value={db_description} placeholder='Describe the tour that you plan on giving. Where are you going to go? What do you plan on doing? Please share anything that comes to mind'></textarea>
        </div>
        <div className='inputBoxTextHolder123'>
          <div className='inputBoxTextHolderTop1'>
          <p>Location</p>
          </div>
          <textarea  className='inputBoxText123' value={db_location} onChange={handle_db_location} placeholder='Describe where you would like your attendees to meet up with you (address, landmark, etc.). Please be as detailed as possible. You dont want your student geting lost!'></textarea>
        </div>
          </div>
          <p className='account_header'>Schedule</p>
          <div className='profile22'>
          <div className='dayHolderHolder11'>
 
 <DaySelector day='sunday' id={userUID}/>
 <DaySelector day='monday' id={userUID}/>
 <DaySelector day='tuesday' id={userUID}/>
 <DaySelector day='wednesday' id={userUID}/>
 <DaySelector day='thursday' id={userUID}/>
 <DaySelector day='friday' id={userUID}/>
 <DaySelector day='saturday' id={userUID}/>

 
 </div>
 </div>
         
        
 <button  id="asd" className='all-set-div' style={{width: '180px', padding: '20px', marginBottom: '20px'}} onClick={updateTour}>Save Changes</button>


       
        </div>
        </div>
}

{
  accountVal == 2 &&
  <div className='account_content1'>

        <div className='account_content_inner1'>
        <h1 className='account_content_header1'>Payment Information</h1>
        {!has_onboarded &&   <p style={{fontWeight: 'bold'}}>Note: You must create a stripe account in order to recieve payments from your tours</p> }
  {!has_onboarded && <button onClick={handleCreateAccount} disabled={loading} className='dashboard' >
       Create Stripe Account
      </button>
}
      { /* accountLink && ( // Render the account link if it exists
        <div>
          <h3>Generated Account Link:</h3>
          <a href={accountLink} target="_blank" rel="noopener noreferrer">{accountLink}</a>
        </div>
) */}
 {aId && <DashboardComponent aId={aId} /> }
      {has_onboarded && ( // Render the account link if it exists
        <div className='left'>
          <p className='cp'>Collect payments and manage your stripe account through your stripe dashboard</p>
          <button onClick={handleCreateLoginLink} className='dashboard'>Log in to dashboard</button>
        </div>
      )}
      
      </div>
      </div>
}
        </div>
     
        </div>
     


    </div>
    
  );
}

export default Account;
