import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { auth, db } from './init';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const Person = (props) => {
  const navigate = useNavigate();
    const storage = getStorage();
    const [imageUrls, setImageUrls] = useState([]);
    const [profileImageUrls, setProfileImageUrls] = useState([]);
    useEffect(() => {
        fetchProfileImages_db(setProfileImageUrls);
    }, [props.uid]);
    function fetchProfileImages_db(setProfileImageUrls) {
        const imagesRef = ref(storage, `profile_images/${props.uid}/`); // Adjust 'images/' to your specific path
        
        listAll(imagesRef)
          .then((res) => {
           
            res.items.forEach((itemRef) => {
              // Get the download URL for each image
              getDownloadURL(itemRef).then((url) => {
                setProfileImageUrls(prevUrls => [...prevUrls, url]);
              });
            });
          }).catch((error) => {
            console.error("Error fetching images:", error);
          });
      }
      let elements = document.querySelectorAll('.truncate');
      const navFocusTour = () => {
        navigate(`/FocusTour/${props.uid}`);
      }
// Iterate over each element
elements.forEach(element => {
    // Get the text content of the <p> element
    let text = element.textContent;
    // Set the maximum length of the text
    let maxLength = 100; // Adjust as needed

    // Check if the text length exceeds the maximum length
    if (text.length > maxLength) {
        // Truncate the text and add ellipsis
        let truncatedText = text.substring(0, maxLength) + '...';
        // Set the truncated text as the new content of the <p> element
        element.textContent = truncatedText;
    }
});

if(profileImageUrls[0]){
  return(
        
    <div className='person' onClick={navFocusTour}>
    <div className='person_img_holder'>
        {profileImageUrls && 
        <img src={profileImageUrls[0]}/>
        }
      
    </div>
    <div className='person_img_footer'>
      <h2 style={{ fontFamily: 'Roboto, sans-serif', textAlign: 'left', marginLeft: '10px' }}>{props.name}</h2>
      <p className='img_p truncate'>{props.bio}</p>
    </div>
    </div>
);
}
else{
  return(
    <div className='person1'>
    <Skeleton animation="wave" variant="rounded" height={170}/>
    <Skeleton animation="wave" variant="rounded"  height={40} sx={{marginTop: '10px'}}/>
    <Skeleton animation="wave" variant="rounded"  height={80} sx={{marginTop: '10px'}}/>
    </div>
  );
}
    

};

export default Person;