import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { useEffect, useState } from 'react';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth, db } from './init';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc, addDoc, collection, getDocs, getDoc, where, query, updateDoc } from "firebase/firestore";
import { CircularProgress } from '@mui/material';

function ManageTour() {
  const navigate = useNavigate();
  const [initiated, setInitiated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [code, setCode] = useState('');
  const [docId, setDocId] = useState(null);
  function cancelTour(){
    setInitiated(true);
    setLoading(true);

    setTimeout(() => {
        stopLoading();
    }, 2000);

  }
  function stopLoading(){
    setLoading(false);
    setDone(true);
    getGuideAccount();
  }
  async function getGuideAccount(){
    const q = query(collection(db, "all_bookings"), where("bookingId", "==", parseInt(code)));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setCancel(doc.data().guide_id, doc.data().docId, doc.data().guide_email);
      setCancelAllBookings(doc.id);
    });
    
  }
  async function setCancelAllBookings(docId){
    const docRef = doc(db, "all_bookings", docId);
    await updateDoc(docRef, {
        canceled: true
    });
}
  async function setCancel(guide_id, docId, email){
    const docRef = doc(db, "bookings" + guide_id, docId);
    await updateDoc(docRef, {
        canceled: true
    });
    const htmlBody = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Tour Cancellation Notification</title>
    </head>
    <body>
      <table cellpadding="0" cellspacing="0" width="100%">
        <tr>
          <td align="center">
            <table cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
              <!-- Header -->
              <tr>
                <td style="background-color: #6F42C1; padding: 20px; text-align: center;">
                  <h1 style="color: #ffffff;">Tour Cancellation Notification</h1>
                </td>
              </tr>
              <!-- Content -->
              <tr>
                <td style="padding: 20px;">
                  <p>Hello,</p>
                  <p>An upcoming tour has ben canceled. Check <a href="http://localhost:3000/GuideTours">your tours page</a> for more details</p>
                  <p>We apologize for any inconvenience this may cause.</p>
                  <p>If you have any questions or concerns, please feel free to contact us.</p>
                  <p>Thank you for your understanding.</p>
                  <p>Best regards,</p>
                  <p>The College Tours Team</p>
                </td>
              </tr>
              <!-- Footer -->
              <tr>
                <td style="background-color: #f5f5f5; padding: 20px; text-align: center;">
                  <p>&copy; 2024 College Tours. All rights reserved.</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
    </html>
    `;
    const docRef1 = await addDoc(collection(db, "mail"), {
        to: email,
        message: {
          subject: 'Tour Cancellation',
          html: htmlBody,
        }
      });
  }
  function home(){
    navigate("/");
  }
  const handleChange = (event) => {
    setCode(event.target.value);
  }
  const [menu, setMenu] = useState(false);
    const explore = () => {
      document.body.style.overflowY = 'auto';
      navigate('/Feed');
     }
     const signup = () => {
      document.body.style.overflowY = 'auto';
      navigate('/JoinGuide');
      
     }
     const signin = () => {
      document.body.style.overflowY = 'auto';
      navigate('/Signin');
     }
     function openNav(){
      setMenu(true);
      document.body.style.overflow = 'hidden';
     }
     function setMenuFalse(){
      setMenu(false);
      document.body.style.overflowY = 'auto';
     }
  return (
    <div className="App">
      
      <NavBar guide={false} onClick={openNav} ></NavBar>
      <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svggg'>
  {/* Define linear gradient for the background */}
  <defs>
    {/* Define linear gradient animation */}
    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#4158D0">
        <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="46%" stopColor="#C850C0">
        <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="100%" stopColor="#FFCC70">
        <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
      </stop>
    </linearGradient>
  </defs>

  {/* Rotate the entire SVG upside down */}
  <g transform="rotate(180 250 100)">
    {/* Draw the paths with their respective gradients */}
    <path d="M 0 120 C 100 100 200 00 500 0 L 0 0 L 0 0" fill="url(#gradient1)" />
  </g>
</svg>
{menu && <div className='menu_nav'>
    <div className='menu_nav_inner'>
      
      <img onClick={setMenuFalse} src={require('./exit.png')} alt="exit" className='menu_nav_exit'></img>
      <div className='nav_inner_holder'>
      <p onClick={explore}>Explore</p>
      <p onClick={signup}>Create a guide account</p>
      <p onClick={signin}>Sign in as a guide</p>
      </div> 
    </div>
    </div> }
      { /*!b && <div>
        <input type="text" placeholder='email' onChange={handleEmailChange}></input>
      <button onClick={join}>Join</button>
      <button onClick={googleSignIn}>Join with Google</button>
        </div>}

        {b &&
        <p>Link sent email to: {email}</p> */}
        {!initiated && 
    <div className='sign-in-holder'>
         <p className='sign-top1'>Cancel your tour</p>
         <div className='inputTextHolder2'>
          <div className='inputTextHolderTop'>
          <p>Tour Code</p>
          </div>
          <input type="text" className='inputText1c' onChange={handleChange} placeholder='Enter your personal tour code'></input>
        </div>
        <div className='sign-in' onClick={cancelTour}>
          <p>Cancel Tour</p>
          <img src={require('./rightArrow.png')} alt="hi"/>
        </div>
        </div>
}
{ loading && 
     <div className='c_center'>
   <CircularProgress color="inherit" size={100}/> 
   </div>
   }
   {done && 
    <div className='c_center'>
   
    <img src={require('./check-mark.png')} alt="check mark"/>
    <h1>Tour Canceled</h1>
    <p>Your tour has been canceled, you will be refunded shortly.</p>
    <button className='c_button' onClick={home}>Go to Home</button>

   {/* <h1 style={{fontWeight: '400'}}>Your tour is now booked! Your guide will be in touch shortly, either by email or phone</h1>
    <div className='cc'>
    <button onClick={navToHome} className='c_button'>Return Home</button>
    <button onClick={navToFeed} className='c_button_right'>Return to feed</button>
    </div>
  */}
    </div>
}
    </div>
  );
}

export default ManageTour;
