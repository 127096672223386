import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUser, userIsGuide } from './NavBar';
const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ( {children}) => {
 
  const [guide, setGuide] = useState(null);
  useEffect(() => {
    refreshUserData();

  }, []);

  const refreshUserData = async () => {
    if(getUser()){
        
        userIsGuide()
           .then((isGuide) => {
            
  setGuide(isGuide);
  
  } );
  }
};

const contextValue = {
    guide,
    refreshUserData, // Function to refresh the user data
};

  // You can add methods to log in/out here and change loggedIn

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};