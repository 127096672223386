import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import NavBar from './NavBar';
function Join() {
  return (
    <div className="app2">
   <NavBar guide={false} ></NavBar>
   <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svggg'>
  {/* Define linear gradient for the background */}
  <defs>
    {/* Define linear gradient animation */}
    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#4158D0">
        <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="46%" stopColor="#C850C0">
        <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="100%" stopColor="#FFCC70">
        <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
      </stop>
    </linearGradient>
  </defs>

  {/* Rotate the entire SVG upside down */}
  <g transform="rotate(180 250 100)">
    {/* Draw the paths with their respective gradients */}
    <path d="M 0 120 C 100 100 200 00 500 0 L 0 0 L 0 0" fill="url(#gradient1)" />
  </g>
</svg>
    <p className='jointext'><span className='g'>Sign up</span> for a EduGold account today</p>
    <div className='joincenter'>
      <div className='joincenterleft'>
      <Link className='joinlink1'to="/JoinGuide">Join as a guide</Link>
      </div>
      <div className='joincenterright'>
      <Link className='joinlink1'to="/JoinProspective">Join as prospective student</Link>
      </div>
    
    
     </div>
    </div>
  );
}

export default Join;
