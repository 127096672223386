import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import NavBar from './NavBar';
import { getDoc, doc, addDoc, collection, query, where, getDocs, updateDoc  } from 'firebase/firestore';
import { getUser } from './NavBar';
import { useEffect, useState } from 'react';
import GuideTour from './components/GuideTour';
import { db } from './init';
import SvgGradient from './SvgGradient';
import { useNavigate } from 'react-router-dom';
function GuideTours() {
  const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [studentInfo, setStudentInfo] = useState('');
    const [currDocument, setCurrDocument] = useState(null);
    const [rescheduleInformation, setRescheduleInformation] = useState('');
    useEffect(() => {
        
      getUser().then(user => {
        if (user) {
         // alert(user.uid);
          setUser(user);
         
        } else {
          // Handle user not signed in
        }
      }).catch(error => {
        // Handle any errors
      });
       // setUser(getUser());
       
       //read();
        
     }, []);

     useEffect(() => {
        getBookings();
     }, [user]);

     async function getBookings(){
      setDocuments([]);
        if(user){
            const q = query(collection(db, "bookings" + user.uid));
    const querySnapshot = await getDocs(q);
    const docsArray = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      docsArray.push({ id: doc.id, ...doc.data() });
    });
    setDocuments(docsArray);
        }
     }
      

     
     const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    function getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
          return day + "th";
      }
      switch (day % 10) {
          case 1:  return day + "st";
          case 2:  return day + "nd";
          case 3:  return day + "rd";
          default: return day + "th";
      }
  }
  const showInfoPopup = (str) => {
    setStudentInfo(str);
  }
  const closeInfoPopup = () => {
    setStudentInfo('');
  }
  const closeCancelPopup = () => {
   setCurrDocument(null);
  }
  const handleTryCancel = (document) => {
    setCurrDocument(document);
  }
  async function setCancelAllBookings(docId){
    const docRef = doc(db, "all_bookings", docId);
    await updateDoc(docRef, {
        canceled: true
    });
}
async function cancelTour(document){
  
    const docRef = doc(db, "bookings" + user.uid, document.id);
    const q = query(collection(db, "all_bookings"), where("docId", "==", document.id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setCancelAllBookings(doc.id);
    });

    const htmlBody = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Tour Cancellation Notification</title>
    </head>
    <body>
      <table cellpadding="0" cellspacing="0" width="100%">
        <tr>
          <td align="center">
            <table cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
              <!-- Header -->
              <tr>
                <td style="background-color: #6F42C1; padding: 20px; text-align: center;">
                  <h1 style="color: #ffffff;">Tour Cancellation Notification</h1>
                </td>
              </tr>
              <!-- Content -->
              <tr>
                <td style="padding: 20px;">
                  <p>Dear ${ document.student_name },</p>
                  <p>We regret to inform you that the tour scheduled for <strong>${monthNames[document.date.toDate().getMonth()]} ${getDayWithSuffix(document.date.toDate().getDate())}</strong> from <strong> ${ document.time }</strong> has been canceled.</p>
                  <p>We apologize for any inconvenience this may cause. Our team will reach out to you shortly and provide you with a refund.</p>
                  <p>If you have any questions or concerns, please feel free to contact us.</p>
                  <p>Thank you for your understanding.</p>
                  <p>Best regards,</p>
                  <p>The College Tours Team</p>
                </td>
              </tr>
              <!-- Footer -->
              <tr>
                <td style="background-color: #f5f5f5; padding: 20px; text-align: center;">
                  <p>&copy; 2024 College Tours. All rights reserved.</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
    </html>
    `;
    
    const htmlBodyInfo = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Tour Cancellation Notification</title>
    </head>
    <body>
      <table cellpadding="0" cellspacing="0" width="100%">
        <tr>
          <td align="center">
            <table cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
              <!-- Header -->
              <tr>
                <td style="background-color: #6F42C1; padding: 20px; text-align: center;">
                  <h1 style="color: #ffffff;">Tour Cancellation Notification</h1>
                </td>
              </tr>
              <!-- Content -->
              <tr>
                <td style="padding: 20px;">
                  <p>Dear ${ document.student_name },</p>
                  <p>We regret to inform you that the tour scheduled for <strong>${monthNames[document.date.toDate().getMonth()]} ${getDayWithSuffix(document.date.toDate().getDate())}</strong> from <strong> ${ document.time }</strong> has been canceled.</p>
                  <p>Your guide has provided the following reschedule details:</p>
                  <p>${rescheduleInformation}</p>
                  <p>You will be refunded shortly. If you have any questions or concerns, please feel free to contact us.</p>
                  <p>Thank you for your understanding.</p>
                  <p>Best regards,</p>
                  <p>The College Tours Team</p>
                </td>
              </tr>
              <!-- Footer -->
              <tr>
                <td style="background-color: #f5f5f5; padding: 20px; text-align: center;">
                  <p>&copy; 2024 College Tours. All rights reserved.</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
    </html>
    `;
// Set the "capital" field of the city 'DC'
await updateDoc(docRef, {
  canceled: true
});
if(rescheduleInformation === ''){
const docRef1 = await addDoc(collection(db, "mail"), {
  to: document.student_email,
  message: {
    subject: 'Tour Cancellation',
    html: htmlBody,
  }
});
}
else{
  const docRef1 = await addDoc(collection(db, "mail"), {
    to: document.student_email,
    message: {
      subject: 'Tour Cancellation',
      html: htmlBodyInfo,
    }
  });
}
setRescheduleInformation('');
setCurrDocument(null);
getBookings();
  }
  const handleInfo = (event) => {
    setRescheduleInformation(event.target.value);
  }
  const [menu, setMenu] = useState(false);
  const explore = () => {
    document.body.style.overflowY = 'auto';
    navigate('/Feed');
   }
   const signup = () => {
    document.body.style.overflowY = 'auto';
    navigate('/JoinGuide');
   }
   const signin = () => {
    document.body.style.overflowY = 'auto';
    navigate('/Signin');
   }
   function openNav(){
    setMenu(true);
    document.body.style.overflow = 'hidden';
   }
   function setMenuFalse(){
    setMenu(false);
    document.body.style.overflowY = 'auto';
   }
   const dashboard = () => {
    document.body.style.overflowY = 'auto';
    navigate('/GuideTours');
   }
   const account = () => {
    document.body.style.overflowY = 'auto';
    navigate('/Account/0');
   }

  return (
    <div className="App">
      <div className='tourCenter'>
      <NavBar border={false} color={false} page="tours" onClick={openNav}></NavBar>
      <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svgg'>
        {/* Define linear gradient for the background */}
        <defs>
          {/* Define linear gradient animation */}
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#4158D0">
              <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="46%" stopColor="#C850C0">
              <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#FFCC70">
              <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
  
        {/* Draw the paths with their respective gradients */}
        <path d="M 0 50 C 50 50 200 0 500 50 L 500 0 L 0 0" fill="url(#gradient1)" />
      </svg>
      {menu && <div className='menu_nav'>
    <div className='menu_nav_inner'>
      
      <img onClick={setMenuFalse} src={require('./exit.png')} alt="exit" className='menu_nav_exit'></img>

      <div className='nav_inner_holder'>
      <p onClick={explore}>Explore</p>
      <p onClick={dashboard}>Dashboard</p>
      <p onClick={account}>My Account</p>
      </div>
    </div>
    </div> }
     <h1 className='yct'>Your upcoming tours</h1>
     {documents.map(document => {
      let time;
      if(document.time === "8-10"){
        time = "8 - 10 am"
      }
      else if(document.time === "10-12"){
        time = "10 am - 12 pm"
      }
      else if(document.time === "12-2"){
        time = "12 - 2 pm"
      }
      else if(document.time === "2-4"){
        time = "2 - 4 pm"
      }
      else if(document.time === "4-6"){
        time = "4 - 6 pm"
      }
      else if(document.time === "6-8"){
        time = "6 - 8 pm"
      }
      return(
<div className='upcoming_tour_holder'>
      <div className='uth_1'>
        <div className='cal_holder'>
        <h1>{monthNames[document.date.toDate().getMonth()]} {getDayWithSuffix(document.date.toDate().getDate())}</h1>
        <h3>{time}</h3>
        </div>
      </div>
      <div className='uth_2'>
        <h1>{document.student_name}</h1>
        <h2>{document.student_email}</h2>
        <h2>{document.student_phone_number}</h2>
        {document.student_description !== '' && <button onClick={() => showInfoPopup(document.student_description)}>More Information</button>}
      </div>
      <div className='uth_3'>
    
      {!document.canceled && <button className='uth_3_1' onClick={() => handleTryCancel(document)}>Cancel</button> }
      {document.canceled && <button className='uth_3_11' style={{border: 'none'}}>Canceled</button> }
      </div>
     </div>
     
      );
     })}
     {/* <GuideTour key={document.id} prospectiveId={document.prospectiveId} date={document.date.toDate().toString()} time={document.time} /> */}

    {studentInfo !== '' &&
    <div className='abs_popup' onClick={closeInfoPopup}>
     <div className='abs_popup_center'>
      <div className='popup_holder' onClick={closeInfoPopup}>
        <div className='popup_holder_top'>
          <h1>Your student's information</h1>
          <img src={require('./exit.png')} />
        </div>
        
        <p className='po'>{studentInfo}</p>
       
      </div>
     </div>

     </div>
}
{currDocument &&
    <div className='abs_popup'>
     <div className='abs_popup_center'>
      <div className='popup_holder'>
        <div className='popup_holder_top'>
          <h1>Cancel Tour</h1>
          <img src={require('./exit.png')}  onClick={closeCancelPopup}/>
        </div>
        <div className='inputBoxTextHolderPopup'>
          <div className='inputBoxTextHolderTop1'>
          <p>Reschedule</p>
          </div>
          <textarea  className='inputBoxTextPopup' onChange={handleInfo} placeholder='If there are other times or dates that work for you, propose them here and the recipient will be emailed for a reschedule.'></textarea>
        </div>
        <button className='uth_3_1_button' onClick={() => cancelTour(currDocument)}>Cancel</button>
       
      </div>
     </div>

     </div>
}
     </div>
    
    </div>
  );
}

export default GuideTours;
