import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link, useParams} from "react-router-dom";
import NavBar from './NavBar';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
//import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useEffect, useState } from 'react';
import { db } from './init';
import { getDoc, doc, addDoc, collection, query, where, getDocs  } from 'firebase/firestore';
import { getUser } from './NavBar';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { CircularProgress } from '@mui/material';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import Checkout from './Checkout';
function Booking() {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const storage = getStorage();
  const [db_name, set_db_name] = useState('');
  const [db_graduation_year, set_db_graduation_year] = useState(null);
  const [db_university, set_db_university] = useState(null);
  const [profileImageUrls, setProfileImageUrls] = useState([]);
  const [guide_email, set_guide_email] = useState('');
  const [db_location, set_db_location] = useState('');
  const [db_phone_number, set_db_phone_number] = useState('');
  const [eight, setEight] = useState(false);
  const [ten, setTen] = useState(false);
  const [twelve, setTwelve] = useState(false);
  const [two, setTwo] = useState(false);
  const [four, setFour] = useState(false);
  const [six, setSix] = useState(false);
  const [finalData, setFinalDate] = useState(null);
  const [finalTime, setFinalTime] = useState(null);
  const [user, setUser] = useState(null);
  const [new_name, set_new_name] = useState('');
  const [new_email, set_new_email] = useState('');
  const [new_description, set_new_description] = useState('');
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [guide_stripe, set_guide_stripe] = useState('');
  const [savedBookingId, setSavedBookingId] = useState(Math.floor(Math.random() * 1000000000000));
  const handleChange = (e) => {
    let input = e.target.value.replace(/[^\d]/g, ''); // Remove non-digits
    // Format as the user types
    if (input.length > 3 && input.length <= 6) {
      input = `${input.slice(0, 3)}-${input.slice(3)}`;
    } else if (input.length > 6) {
      input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6, 10)}`;
    }
    setPhoneNumber(input);
    
    
  };
  
  const handleDashInsertion = (e) => {
    let { value } = e.target;
    if (value.length === 3 || value.length === 7) {
      // Add dash after 3rd and 7th character (which is after 3rd and 6th digit)
      setPhoneNumber(value + '-');
    } else {
      handleChange(e);
    }
  };
  useEffect(() => {
      
    getUser().then(user => {
      if (user) {
       // alert(user.uid);
        setUser(user);
       
      } else {
        // Handle user not signed in
      }
    }).catch(error => {
      // Handle any errors
    });
     // setUser(getUser());
     
     //read();
      
   }, []);
    let { id } = useParams();
    const [date, setDate] = useState(null);
    const onChange = (e) => {
      setDate(e.value);
      var element = document.getElementById("cal");
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
      
    };
    useEffect(() => {
      localStorage.setItem('guide_id', JSON.stringify(id));
      read_db();
      fetchProfileImages_db(setProfileImageUrls);
    }, [id]);
    async function read_db(){
      // alert(user.uid);
          const docRef = doc(db, "guide_tours", id);
         // alert(user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            set_db_name(docSnap.data().name);
              set_db_university(docSnap.data().university);
              set_db_graduation_year(docSnap.data().graduationYear);
              set_guide_email(docSnap.data().email);
              set_db_location(docSnap.data().location);
              set_db_phone_number(docSnap.data().phoneNumber);
              set_guide_stripe(docSnap.data().stripe_account_id);
              localStorage.setItem('db_name', JSON.stringify(docSnap.data().name));
              localStorage.setItem('db_email', JSON.stringify(docSnap.data().email));
              localStorage.setItem('db_location', JSON.stringify(docSnap.data().location));
              localStorage.setItem('stripe', JSON.stringify(docSnap.data().stripe_account_id));
              localStorage.setItem('db_phone_number', JSON.stringify(docSnap.data().phoneNumber));
              
              console.log("Document data:", docSnap.data());
            } else {
              // docSnap.data() will be undefined in this case
              console.log("No such document!");
            }
      }
      function fetchProfileImages_db(setProfileImageUrls) {
        const imagesRef = ref(storage, `profile_images/${id}/`); // Adjust 'images/' to your specific path
        
        listAll(imagesRef)
          .then((res) => {
           
            res.items.forEach((itemRef) => {
              // Get the download URL for each image
              getDownloadURL(itemRef).then((url) => {
                setProfileImageUrls(prevUrls => [...prevUrls, url]);
              });
            });
          }).catch((error) => {
            console.error("Error fetching images:", error);
          });
      }
    const docRef = doc(db, "times", id);
 useEffect(() => {
   
  setEight(false);
  setTen(false);
  setTwelve(false);
  setTwo(false);
  setFour(false);
  setSix(false);
  if(date !== null){
  setTimes();
  }
 }, [date]);
 async function setTimes(){
  const docSnap = await getDoc(docRef);
  
  let day = date.getDay();

  if(day === 0){
    if(docSnap.data().sunday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().sunday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().sunday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().sunday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().sunday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().sunday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 1){
    if(docSnap.data().monday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().monday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().monday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().monday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().monday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().monday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 2){
    if(docSnap.data().tuesday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().tuesday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().tuesday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().tuesday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().tuesday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().tuesday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 3){
    if(docSnap.data().wednesday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().wednesday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().wednesday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().wednesday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().wednesday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().wednesday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 4){
    if(docSnap.data().thursday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().thursday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().thursday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().thursday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().thursday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().thursday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 5){
    if(docSnap.data().friday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().friday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().friday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().friday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().friday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().friday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 6){
    if(docSnap.data().saturday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().saturday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().saturday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().saturday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().saturday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().saturday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }

 }
 async function bookEight(){
  setFinalDate(date);
  setFinalTime("8-10");
  if( document.getElementById("a")){
  document.getElementById("a").classList.add("book_time_selected");
  }
  if( document.getElementById("b")){
  document.getElementById("b").classList.remove("book_time_selected");
  }
  if( document.getElementById("c")){
  document.getElementById("c").classList.remove("book_time_selected");
  }
  if( document.getElementById("d")){
  document.getElementById("d").classList.remove("book_time_selected");
  }
  if( document.getElementById("e")){
  document.getElementById("e").classList.remove("book_time_selected");
  }
  if( document.getElementById("f")){
  document.getElementById("f").classList.remove("book_time_selected");
  }
 }
 async function bookTen(){
  setFinalDate(date);
  setFinalTime("10-12");
  if( document.getElementById("a")){
    document.getElementById("a").classList.remove("book_time_selected");
    }
    if( document.getElementById("b")){
    document.getElementById("b").classList.add("book_time_selected");
    }
    if( document.getElementById("c")){
    document.getElementById("c").classList.remove("book_time_selected");
    }
    if( document.getElementById("d")){
    document.getElementById("d").classList.remove("book_time_selected");
    }
    if( document.getElementById("e")){
    document.getElementById("e").classList.remove("book_time_selected");
    }
    if( document.getElementById("f")){
    document.getElementById("f").classList.remove("book_time_selected");
    }
 }
 async function bookTwelve(){
  setFinalDate(date);
  setFinalTime("12-2");
  if( document.getElementById("a")){
    document.getElementById("a").classList.remove("book_time_selected");
    }
    if( document.getElementById("b")){
    document.getElementById("b").classList.remove("book_time_selected");
    }
    if( document.getElementById("c")){
    document.getElementById("c").classList.add("book_time_selected");
    }
    if( document.getElementById("d")){
    document.getElementById("d").classList.remove("book_time_selected");
    }
    if( document.getElementById("e")){
    document.getElementById("e").classList.remove("book_time_selected");
    }
    if( document.getElementById("f")){
    document.getElementById("f").classList.remove("book_time_selected");
    }
  
 }
 async function bookTwo(){
  setFinalDate(date);
  setFinalTime("2-4");
  if( document.getElementById("a")){
    document.getElementById("a").classList.remove("book_time_selected");
    }
    if( document.getElementById("b")){
    document.getElementById("b").classList.remove("book_time_selected");
    }
    if( document.getElementById("c")){
    document.getElementById("c").classList.remove("book_time_selected");
    }
    if( document.getElementById("d")){
    document.getElementById("d").classList.add("book_time_selected");
    }
    if( document.getElementById("e")){
    document.getElementById("e").classList.remove("book_time_selected");
    }
    if( document.getElementById("f")){
    document.getElementById("f").classList.remove("book_time_selected");
    }
  
 }
 async function bookFour(){
  setFinalDate(date);
  setFinalTime("4-6");
  if( document.getElementById("a")){
    document.getElementById("a").classList.remove("book_time_selected");
    }
    if( document.getElementById("b")){
    document.getElementById("b").classList.remove("book_time_selected");
    }
    if( document.getElementById("c")){
    document.getElementById("c").classList.remove("book_time_selected");
    }
    if( document.getElementById("d")){
    document.getElementById("d").classList.remove("book_time_selected");
    }
    if( document.getElementById("e")){
    document.getElementById("e").classList.add("book_time_selected");
    }
    if( document.getElementById("f")){
    document.getElementById("f").classList.remove("book_time_selected");
    }
  
 }
 async function bookSix(){
  setFinalDate(date);
  setFinalTime("6-8");
  if( document.getElementById("a")){
    document.getElementById("a").classList.remove("book_time_selected");
    }
    if( document.getElementById("b")){
    document.getElementById("b").classList.remove("book_time_selected");
    }
    if( document.getElementById("c")){
    document.getElementById("c").classList.remove("book_time_selected");
    }
    if( document.getElementById("d")){
    document.getElementById("d").classList.remove("book_time_selected");
    }
    if( document.getElementById("e")){
    document.getElementById("e").classList.remove("book_time_selected");
    }
    if( document.getElementById("f")){
    document.getElementById("f").classList.add("book_time_selected");
    }
  
 }
async function finalBooking(){
  

if(finalTime === "8-10"){
  
 
  let the_collection = "bookings" + id;
  
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "8-10",
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_description: new_description,
    student_email: new_email,
    bookingId: savedBookingId,
    paid: false
    //prospectiveId: user.uid 
  });
  let col = "all_bookings"
  const allBookingsRef = await addDoc(collection(db, col), {
    date: date,
    time: finalTime,
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_email: new_email,
    bookingId: savedBookingId,
    guide_email: guide_email,
    guide_id: id,
    docId: docRef.id,
    stripe: guide_stripe,
    paid: false
  });
  localStorage.setItem('doc_id', JSON.stringify(docRef.id));
  localStorage.setItem('all_bookings_doc_id', JSON.stringify(allBookingsRef.id));
  /*let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  });
*/
}
else if(finalTime === "10-12"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "10-12",
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_description: new_description,
    student_email: new_email,
    bookingId: savedBookingId,
    paid: false
   // prospectiveId: user.uid 
  });
  let col = "all_bookings"
  const allBookingsRef = await addDoc(collection(db, col), {
    date: date,
    time: finalTime,
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_email: new_email,
    bookingId: savedBookingId,
    guide_email: guide_email,
    guide_id: id,
    docId: docRef.id,
    stripe: guide_stripe,
    paid: false
  });
  localStorage.setItem('doc_id', JSON.stringify(docRef.id));
  localStorage.setItem('all_bookings_doc_id', JSON.stringify(allBookingsRef.id));
  /*let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  }); */
}
else if(finalTime === "12-2"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "12-2",
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_description: new_description,
    student_email: new_email,
    bookingId: savedBookingId,
    stripe: guide_stripe,
    paid: false
    //prospectiveId: user.uid 
  });
  let col = "all_bookings"
  const allBookingsRef = await addDoc(collection(db, col), {
    date: date,
    time: finalTime,
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_email: new_email,
    bookingId: savedBookingId,
    guide_email: guide_email,
    guide_id: id,
    docId: docRef.id,
    stripe: guide_stripe,
    paid: false
  });
  localStorage.setItem('doc_id', JSON.stringify(docRef.id));
  localStorage.setItem('all_bookings_doc_id', JSON.stringify(allBookingsRef.id));
  /*let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  }); */
}
else if(finalTime === "2-4"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "2-4",
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_description: new_description,
    student_email: new_email,
    bookingId: savedBookingId,
    paid: false
   // prospectiveId: user.uid 
  });
  let col = "all_bookings"
  const allBookingsRef = await addDoc(collection(db, col), {
    date: date,
    time: finalTime,
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_email: new_email,
    bookingId: savedBookingId,
    guide_email: guide_email,
    guide_id: id,
    docId: docRef.id,
    stripe: guide_stripe,
    paid: false
  });
  localStorage.setItem('doc_id', JSON.stringify(docRef.id));
  localStorage.setItem('all_bookings_doc_id', JSON.stringify(allBookingsRef.id));
  /*let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  }); */
}
else if(finalTime === "4-6"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "4-6",
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_description: new_description,
    student_email: new_email,
    bookingId: savedBookingId,
    paid: false
    //prospectiveId: user.uid 
  });
  let col = "all_bookings"
  const allBookingsRef = await addDoc(collection(db, col), {
    date: date,
    time: finalTime,
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_email: new_email,
    bookingId: savedBookingId,
    guide_email: guide_email,
    guide_id: id,
    docId: docRef.id,
    stripe: guide_stripe,
    paid: false
  });
  localStorage.setItem('doc_id', JSON.stringify(docRef.id));
  localStorage.setItem('all_bookings_doc_id', JSON.stringify(allBookingsRef.id));
}
else if(finalTime === "6-8"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "6-8",
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_description: new_description,
    student_email: new_email,
    bookingId: savedBookingId,
    paid: false
    //prospectiveId: user.uid 
  });
  let col = "all_bookings"
  const allBookingsRef = await addDoc(collection(db, col), {
    date: date,
    time: finalTime,
    student_name: new_name,
    student_phone_number: phoneNumber,
    student_email: new_email,
    bookingId: savedBookingId,
    guide_email: guide_email,
    guide_id: id,
    docId: docRef.id,
    stripe: guide_stripe,
    paid: false
  });
  localStorage.setItem('doc_id', JSON.stringify(docRef.id));
  localStorage.setItem('all_bookings_doc_id', JSON.stringify(allBookingsRef.id));
  /*let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  }); */
}
const monthNames = [
  "January", "February", "March",
  "April", "May", "June", "July",
  "August", "September", "October",
  "November", "December"
];
function getDayWithSuffix(day) {
  if (day >= 11 && day <= 13) {
      return day + "th";
  }
  switch (day % 10) {
      case 1:  return day + "st";
      case 2:  return day + "nd";
      case 3:  return day + "rd";
      default: return day + "th";
  }
}
const htmlBody = `

<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>New Tour Booking Alert</title>
</head>
<body>
  <table cellpadding="0" cellspacing="0" width="100%">
    <tr>
      <td align="center">
        <table cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
          <!-- Header -->
          <tr>
            <td style="background-color: #6F42C1; padding: 20px; text-align: center;">
              <h1 style="color: #ffffff;">New Tour Booking Alert</h1>
            </td>
          </tr>
          <!-- Content -->
          <tr>
            <td style="padding: 20px;">
              <p>Hello!</p>
              <p>A new tour has been booked for ${monthNames[date.getMonth()]} ${getDayWithSuffix(date.getDate())}. Here are the details:</p>
              <ul>
                <li><strong>Time block:</strong> ${ finalTime }</li>
                <li><strong>Name:</strong> ${ new_name }</li>
                <li><strong>Email:</strong> ${ new_email }</li>
                <li><strong>Phone Number:</strong> ${ phoneNumber }</li>
              </ul>
              <p>Please reach out to the student as soon as possible to confirm the tour details and provide any necessary information.</p>
              <a href="http://localhost:3000/GuideTours">More details can be found here</a>
              <p>Thank you!</p>
            </td>
          </tr>
          <!-- Footer -->
          <tr>
            <td style="background-color: #f5f5f5; padding: 20px; text-align: center;">
              <p>&copy; 2024 EduGold. All rights reserved.</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
`;
const htmlBody1 = `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Confirmation: Tour Booking</title>
</head>
<body>
  <table cellpadding="0" cellspacing="0" width="100%">
    <tr>
      <td align="center">
        <table cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
          <!-- Header -->
          <tr>
            <td style="background-color: #6F42C1; padding: 20px; text-align: center;">
              <h1 style="color: #ffffff;">Tour Booking Confirmation</h1>
            </td>
          </tr>
          <!-- Content -->
          <tr>
            <td style="padding: 20px;">
              <p>Dear ${ new_name },</p>
              <p>Your tour on ${monthNames[date.getMonth()]} ${getDayWithSuffix(date.getDate())} from ${finalTime} has been booked!</p>
              <p>${ db_name } has provided the following location details for your tour:</p>
              <ul>
              <li><strong>Location:</strong> ${ db_location }</li>
              <li><strong>Email:</strong> ${ guide_email }</li>
              <li><strong>Phone Number:</strong> ${ db_phone_number }</li>
              </ul>
              <p>Please arrive at the specified location on time to meet your guide.</p>
              <br></br>
              <p>Thank you so much for scheduling a tour with us. If you have any questions or need further assistance, feel free to reach out to us at uncovercollegefinancial@gmail.com</p>
              <p>You can cancel your tour with a full refund up to 24 hours before. To do this, use your personal tour code: ${ savedBookingId } and follow the link:  <a href="http://localhost:3000/ManageTour">Manage tour</a></p>
              <p>Best regards,</p>
              <p>The College Tours Team</p>
            </td>
          </tr>
          <!-- Footer -->
          <tr>
            <td style="background-color: #f5f5f5; padding: 20px; text-align: center;">
              <p>&copy; 2024 EduGold. All rights reserved.</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
`
const docRef = await addDoc(collection(db, "mail"), {
  to: guide_email,
  message: {
    subject: 'New Tour Booking',
    html: htmlBody,
  }
});
const docRef1 = await addDoc(collection(db, "mail"), {
  to: new_email,
  message: {
    subject: 'Tour Booking Confirmation',
    html: htmlBody1,
  }
});
//navigate('/CompletedBooking');
}
const handleName = (event) => {
  set_new_name(event.target.value);
  
};
const handleEmail = (event) => {
  set_new_email(event.target.value);
};
const handleDescription = (event) => {
  set_new_description(event.target.value);
};
const [url, setUrl] = useState(null);
const handleCreateCheckoutLink = async () => {
  setLoading(true);
  localStorage.setItem('student_name', JSON.stringify(new_name));
  localStorage.setItem('student_phone_number', JSON.stringify(phoneNumber));
  localStorage.setItem('student_description', JSON.stringify(new_description));
  localStorage.setItem('student_email', JSON.stringify(new_email));
  localStorage.setItem('date', JSON.stringify(finalData));
  localStorage.setItem('time', JSON.stringify(finalTime));

 // navigate('/CompletedBooking');

  //finalBooking();
  try {
    const response = await fetch('https://us-central1-toouurs-f9210.cloudfunctions.net/checkoutLink', {
      method: 'POST',
    });
    if (!response.ok) {
      throw new Error('Failed to create account link');
    }
    const session = await response.json();
    setUrl(session);
    console.log(session);
    //window.open(session.url, "_blank");
    // Store the generated account link in the state variable
  } catch (error) {
    console.error('Error generating account link:', error);
  } finally {
   
  } 
};
useEffect(() => {
  if(url){
    console.log(url);
    window.location.href = url.session.url;
  //window.open(url.session.url, "_blank");
  }
}, [url])
  return (
    <div style={{width: '100%', height: '100%'}}>
    { !loading &&
    <div className="App_booking">
       
         <div className='guideTopNav'>
     <div className='guideTopNavHalf'>
      <h3>EduGold</h3>
     </div>
      <div className='guideTopNavHalf'>
      
      </div>
     </div>
     <div className='booking_container'>
        <div className='booking_left'>
        <div className='bl_1'>
        
          <p>Request to book</p>
        </div>
     {/*   <p>Booking Page: {id}</p> */}
     
    {!date && <div className='bl_2'>
          <p className='g'>Select a date to tour</p>
        </div> }
        {date && <div className='bl_2'>
          <p className='g'>Now select a time below</p>
        </div> }
     <div className='cal'>
     <Calendar value={date} onChange={onChange} id="cal" inline />
     </div>
     <div className='bl_3'>
          <div className='bl_3_top'>
          {(eight || ten || twelve || two || four || six)
     && date && <p>Select the two-hour tour time block</p>
     }
            
            {!eight && !ten && !twelve && !two && !four && !six
     && date && <p>No times available</p>
     }
          </div>
          <div className='bl_3_bottom'>
          {eight && <button onClick={bookEight} id="a" className='book_time'>8 - 10 am</button> }
     {ten && <button onClick={bookTen} id="b" className='book_time'>10 - 12 am</button> }
     {twelve && <button onClick={bookTwelve} id="c" className='book_time'>12 - 2 pm</button> }
     {two && <button onClick={bookTwo} id="d" className='book_time'>2 - 4 pm</button> }
     {four && <button onClick={bookFour} id="e" className='book_time'>4 - 6 pm</button> }
     {six && <button onClick={bookSix} id="f" className='book_time'>6 - 8 pm</button> }
          </div>
        </div>
        <div className='bl_4'>
        <h2>Your information</h2>
          <div className='bl4_holder'>
            
          <div className='bl4_inputTextHolder'>
          <div className='inputTextHolderTop'>
          <p>Name</p>
          </div>
          <input type="text" className='inputText' placeholder='Enter your full name' onChange={handleName}></input>
        </div>
        <div className='bl4_inputTextHolder'>
          <div className='inputTextHolderTop'>
          <p>Phone Number</p>
          </div>
          <input type="text" className='inputText' value={phoneNumber} onChange={handleDashInsertion} placeholder='Enter your phone number'></input>
        </div>
        <div className='bl4_inputTextHolder'>
          <div className='inputTextHolderTop'>
          <p>Email</p>
          </div>
          <input type="text" className='inputText' placeholder='Enter your email' onChange={handleEmail}></input>
        </div>
          </div>
          <div className='inputBoxTextHolder_bl4'>
          <div className='inputBoxTextHolderTop1'>
          <p>More details</p>
          </div>
          <textarea  className='inputBoxText_bl4' placeholder='Is there anything you would like the tour guide to know about the the student?' onChange={handleDescription}></textarea>
        </div>
        </div>
        
       
        <div className='bl_5'>
          <button onClick={handleCreateCheckoutLink}>Book Tour and Pay</button>
        </div>
      
   
   
   
        </div>
        <div className='booking_right'>
        <div className='price_container'>
     <div className='pc1'>
      <div className='pc1_left'>
       <img src={profileImageUrls[0]} alt="profile image"></img>
      </div>
      <div className='pc1_right'>
        <div className='pc1_right_center'>
        <h3>{db_name}</h3>
        <div className='pc1_right_uni'>
          {db_university && <p>{db_university.value}</p> }
         {db_graduation_year &&  <p>{db_graduation_year.value}</p> }
        </div>
      </div>
      </div>
     </div>
     <div className='pc2'>
      <h2>Pricing details</h2>
      <div className='pc3_price_container'>
        <div className='pc3_left'>
          <p className='pc3_text'>2 hour tour</p>
        </div>
        <div className='pc3_right'></div>
        <p className='pc3_text'>$50</p>
      </div>
      <div className='pc3_price_container'>
      <div className='pc3_left'>
      <p className='pc3_text'>Service fee</p>
      </div>
        <div className='pc3_right'>
        <p className='pc3_text'>$10</p>
        </div>
      </div>
     </div>
     <div className='pc3'>
     <div className='pc3_price_container'>
        <div className='pc3_left'>
          <p className='pc3_total'>Total</p>
        </div>
        <div className='pc3_right'></div>
        <p className='pc3_total'>$60</p>
      </div>
     </div>
</div>
        </div>
        </div>

    </div>
    
    }
    {
      loading &&  <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> <CircularProgress color="inherit" size={100} className='loadingCircle'/> </div>
    }
    </div>
  );
}

export default Booking;
