import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import SvgGradient from './SvgGradient';
import Confetti from 'react-confetti';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { getDoc, doc, addDoc, collection, query, where, getDocs, updateDoc  } from 'firebase/firestore';
import { db } from './init';
function CompletedBooking() {
  const [savedBookingId, setSavedBookingId] = useState(Math.floor(Math.random() * 1000000000000));
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    function navToProspectiveBookings(){
        navigate('/ProspectiveTours')
    }
    function navToFeed(){
        navigate('/Feed');
    }
    function navToHome(){
      navigate('/');
  }
  
  useEffect(() => {
    paid();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  async function paid(){
    const ids = localStorage.getItem('guide_id');
    const id = JSON.parse(ids);
    const doc_id = localStorage.getItem('doc_id');
    const all_bookings_doc_id = localStorage.getItem('all_bookings_doc_id');
    const finalTimeFirst = localStorage.getItem('time');
    const finalTime = JSON.parse(finalTimeFirst);
    const dateFirst = localStorage.getItem('date');
    const date = new Date(JSON.parse(dateFirst));
    const new_nameFirst = localStorage.getItem('student_name');
    const new_name = JSON.parse(new_nameFirst);
    const phoneNumberFirst = localStorage.getItem('student_phone_number');
    const phoneNumber = JSON.parse(phoneNumberFirst);
    const new_descriptionFirst = localStorage.getItem('student_description');
    const new_description = JSON.parse(new_descriptionFirst);
    const new_emailFirst = localStorage.getItem('student_email');
    const new_email = JSON.parse(new_emailFirst);
    const guide_stripeFirst = localStorage.getItem('stripe');
    const guide_stripe = JSON.parse(guide_stripeFirst);
    const guide_emailFirst = localStorage.getItem('db_email');
    const guide_email = JSON.parse(guide_emailFirst);
    const db_locationFirst = localStorage.getItem('db_location');
    const db_location = JSON.parse(db_locationFirst);
    const db_nameFirst = localStorage.getItem('db_name');
    const db_name = JSON.parse(db_nameFirst);
    const db_phone_numberFirst = localStorage.getItem('db_phone_number');
    const db_phone_number = JSON.parse(db_phone_numberFirst);
   // alert(date);
   //alert(JSON.parse(id) + JSON.parse(doc_id) + JSON.parse(all_bookings_doc_id));
   /*let the_collection = "bookings" + JSON.parse(id);
   const docRef = doc(db, the_collection, JSON.parse(doc_id));
   const allBookingsRef = doc(db, "all_bookings", JSON.parse(all_bookings_doc_id));
   await updateDoc(docRef, {
    paid: true
  });
  await updateDoc(allBookingsRef, {
    paid: true
  }); */
  if(finalTime === "8-10"){
  
 
    let the_collection = "bookings" + id;
    
    const docRef = await addDoc(collection(db, the_collection), {
      date: date,
      time: "8-10",
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_description: new_description,
      student_email: new_email,
      bookingId: savedBookingId,
       
      //prospectiveId: user.uid 
    });
    let col = "all_bookings"
    const allBookingsRef = await addDoc(collection(db, col), {
      date: date,
      time: finalTime,
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_email: new_email,
      bookingId: savedBookingId,
      guide_email: guide_email,
      guide_id: id,
      docId: docRef.id,
      stripe: guide_stripe,
       
    });
   
    /*let prospective_collection = "prospectivebookings" + user.uid;
    const docRef1 = await addDoc(collection(db, prospective_collection), {
      guideId: id
    });
  */
  }
  else if(finalTime === "10-12"){
    let the_collection = "bookings" + id;
    const docRef = await addDoc(collection(db, the_collection), {
      date: date,
      time: "10-12",
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_description: new_description,
      student_email: new_email,
      bookingId: savedBookingId,
       
     // prospectiveId: user.uid 
    });
    let col = "all_bookings"
    const allBookingsRef = await addDoc(collection(db, col), {
      date: date,
      time: finalTime,
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_email: new_email,
      bookingId: savedBookingId,
      guide_email: guide_email,
      guide_id: id,
      docId: docRef.id,
      stripe: guide_stripe,
       
    });
    
    /*let prospective_collection = "prospectivebookings" + user.uid;
    const docRef1 = await addDoc(collection(db, prospective_collection), {
      guideId: id
    }); */
  }
  else if(finalTime === "12-2"){
    let the_collection = "bookings" + id;
    const docRef = await addDoc(collection(db, the_collection), {
      date: date,
      time: "12-2",
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_description: new_description,
      student_email: new_email,
      bookingId: savedBookingId,
      stripe: guide_stripe,
      
      //prospectiveId: user.uid 
    });
    let col = "all_bookings"
    const allBookingsRef = await addDoc(collection(db, col), {
      date: date,
      time: finalTime,
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_email: new_email,
      bookingId: savedBookingId,
      guide_email: guide_email,
      guide_id: id,
      docId: docRef.id,
      stripe: guide_stripe,
      
    });
    
    /*let prospective_collection = "prospectivebookings" + user.uid;
    const docRef1 = await addDoc(collection(db, prospective_collection), {
      guideId: id
    }); */
  }
  else if(finalTime === "2-4"){
    let the_collection = "bookings" + id;
    const docRef = await addDoc(collection(db, the_collection), {
      date: date,
      time: "2-4",
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_description: new_description,
      student_email: new_email,
      bookingId: savedBookingId,
      
     // prospectiveId: user.uid 
    });
    let col = "all_bookings"
    const allBookingsRef = await addDoc(collection(db, col), {
      date: date,
      time: finalTime,
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_email: new_email,
      bookingId: savedBookingId,
      guide_email: guide_email,
      guide_id: id,
      docId: docRef.id,
      stripe: guide_stripe,
      
    });
    
    /*let prospective_collection = "prospectivebookings" + user.uid;
    const docRef1 = await addDoc(collection(db, prospective_collection), {
      guideId: id
    }); */
  }
  else if(finalTime === "4-6"){
    let the_collection = "bookings" + id;
    const docRef = await addDoc(collection(db, the_collection), {
      date: date,
      time: "4-6",
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_description: new_description,
      student_email: new_email,
      bookingId: savedBookingId,
      
      //prospectiveId: user.uid 
    });
    let col = "all_bookings"
    const allBookingsRef = await addDoc(collection(db, col), {
      date: date,
      time: finalTime,
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_email: new_email,
      bookingId: savedBookingId,
      guide_email: guide_email,
      guide_id: id,
      docId: docRef.id,
      stripe: guide_stripe,
      
    });
    
  }
  else if(finalTime === "6-8"){
    let the_collection = "bookings" + id;
    const docRef = await addDoc(collection(db, the_collection), {
      date: date,
      time: "6-8",
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_description: new_description,
      student_email: new_email,
      bookingId: savedBookingId,
       
      //prospectiveId: user.uid 
    });
    let col = "all_bookings"
    const allBookingsRef = await addDoc(collection(db, col), {
      date: date,
      time: finalTime,
      student_name: new_name,
      student_phone_number: phoneNumber,
      student_email: new_email,
      bookingId: savedBookingId,
      guide_email: guide_email,
      guide_id: id,
      docId: docRef.id,
      stripe: guide_stripe,
       
    });
   
    /*let prospective_collection = "prospectivebookings" + user.uid;
    const docRef1 = await addDoc(collection(db, prospective_collection), {
      guideId: id
    }); */
  }
  let time;
  if(finalTime === "8-10"){
    time = "8 to 10 am"
  }
  else if(finalTime === "10-12"){
    time = "10 am to 12 pm"
  }
  else if(finalTime === "12-2"){
    time = "12 to 2 pm"
  }
  else if(finalTime === "2-4"){
    time = "2 to 4 pm"
  }
  else if(finalTime === "4 - 6"){
    time = "4 to 6 pm"
  }
  else if(finalTime === "6-8"){
    time = "6 to 8 pm"
  }
  const htmlBody = `
  
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>New Tour Booking Alert</title>
  </head>
  <body>
    <table cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td align="center">
          <table cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
            <!-- Header -->
            <tr>
              <td style="background-color: #6F42C1; padding: 20px; text-align: center;">
                <h1 style="color: #ffffff;">New Tour Booking Alert</h1>
              </td>
            </tr>
            <!-- Content -->
            <tr>
              <td style="padding: 20px;">
                <p>Hello!</p>
                <p>A new tour has been booked for ${monthNames[date.getMonth()]} ${getDayWithSuffix(date.getDate())}. Here are the details:</p>
                <ul>
                  <li><strong>Time block:</strong> ${ time }</li>
                  <li><strong>Name:</strong> ${ new_name }</li>
                  <li><strong>Email:</strong> ${ new_email }</li>
                  <li><strong>Phone Number:</strong> ${ phoneNumber }</li>
                </ul>
                <p>Please reach out to the student as soon as possible to confirm the tour details and provide any necessary information.</p>
                <a href="http://localhost:3000/GuideTours">More details can be found here</a>
                <p>Thank you!</p>
              </td>
            </tr>
            <!-- Footer -->
            <tr>
              <td style="background-color: #f5f5f5; padding: 20px; text-align: center;">
                <p>&copy; 2024 EduGold. All rights reserved.</p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
  </html>
  `;
  
  const htmlBody1 = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Confirmation: Tour Booking</title>
  </head>
  <body>
    <table cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td align="center">
          <table cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
            <!-- Header -->
            <tr>
              <td style="background-color: #6F42C1; padding: 20px; text-align: center;">
                <h1 style="color: #ffffff;">Tour Booking Confirmation</h1>
              </td>
            </tr>
            <!-- Content -->
            <tr>
              <td style="padding: 20px;">
                <p>Dear ${ new_name },</p>
                <p>Your tour on ${monthNames[date.getMonth()]} ${getDayWithSuffix(date.getDate())} from ${time} has been booked!</p>
                <p style="color: #000000;">${ db_name } has provided the following location details for your tour:</p>
                <ul>
                <li style="color: #000000;"><strong>Location:</strong> ${ db_location }</li>
                <li style="color: #000000;"><strong>Email:</strong> ${ guide_email }</li>
                <li style="color: #000000;"><strong>Phone Number:</strong> ${ db_phone_number }</li>
                </ul>
                <p style="color: #000000;">Please arrive at the specified location on time to meet your guide.</p>
                <br></br>
                <p style="color: #000000;">Thank you so much for scheduling a tour with us. If you have any questions or need further assistance, feel free to reach out to us at uncovercollegefinancial@gmail.com</p>
                <p style="color: #000000;">You can cancel your tour with a full refund up to 24 hours before. To do this, use your personal tour code: ${ savedBookingId } and follow the link:  <a href="http://localhost:3000/ManageTour">Manage tour</a></p>
                <p>Best regards,</p>
                <p>The College Tours Team</p>
              </td>
            </tr>
            <!-- Footer -->
            <tr>
              <td style="background-color: #f5f5f5; padding: 20px; text-align: center;">
                <p>&copy; 2024 EduGold. All rights reserved.</p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
  </html>
  `
  const docRef = await addDoc(collection(db, "mail"), {
    to: guide_email,
    message: {
      subject: 'New Tour Booking',
      html: htmlBody,
    }
  });
  const docRef1 = await addDoc(collection(db, "mail"), {
    to: new_email,
    message: {
      subject: 'Tour Booking Confirmation',
      html: htmlBody1,
    }
  });
  
  
  }
  const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  function getDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
        return day + "th";
    }
    switch (day % 10) {
        case 1:  return day + "st";
        case 2:  return day + "nd";
        case 3:  return day + "rd";
        default: return day + "th";
    }
  }
  return (
    <div className="App">
      <div className='guideTopNav' style={{border: 'none'}}>
     <div className='guideTopNavHalf'>
      <h3 className='g' style={{fontSize: '30px'}}>EduGold</h3>
     </div>
      <div className='guideTopNavHalf'>
      
      </div>
     </div>
     { loading && 
     <div className='c_center'>
   <CircularProgress color="inherit" size={100}/> 
   </div>
   }
     {!loading && 
    <div className='c_center'>
   
    <img src={require('./check-mark.png')} alt="check mark"/>
    <h1>Congratulations!</h1>
    <p>Your tour is now booked. You'll receive an email shortly with more information about your guide and tour.</p>
    <button onClick={navToHome} className='c_button'>Return Home</button>

   {/* <h1 style={{fontWeight: '400'}}>Your tour is now booked! Your guide will be in touch shortly, either by email or phone</h1>
    <div className='cc'>
    <button onClick={navToHome} className='c_button'>Return Home</button>
    <button onClick={navToFeed} className='c_button_right'>Return to feed</button>
    </div>
  */}
    </div>
}
    </div>
  );
}

export default CompletedBooking;
