import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import NavBar from './NavBar';
import { getDoc, doc, addDoc, collection, query, where, getDocs, updateDoc  } from 'firebase/firestore';
import { getUser } from './NavBar';
import { useEffect, useState } from 'react';
import GuideTour from './components/GuideTour';
import { db } from './init';
import SvgGradient from './SvgGradient';
function AllBookings() {
    const [user, setUser] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [studentInfo, setStudentInfo] = useState('');
    const [currDocument, setCurrDocument] = useState(null);
    const [rescheduleInformation, setRescheduleInformation] = useState('');
     useEffect(() => {
        getBookings();
     }, []);

     async function getBookings(){
      setDocuments([]);
       
            const q = query(collection(db, "all_bookings"));
    const querySnapshot = await getDocs(q);
    console.log(q);
    const docsArray = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      docsArray.push({ id: doc.id, ...doc.data() });
      console.log(doc.data());
    });
    setDocuments(docsArray);
        
     }
      
   async function done(id){
    await updateDoc(doc(db, "all_bookings", id), {
      done: true
    });
    window.location.reload();
  }
     
     const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    function getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
          return day + "th";
      }
      switch (day % 10) {
          case 1:  return day + "st";
          case 2:  return day + "nd";
          case 3:  return day + "rd";
          default: return day + "th";
      }
  }


  return (
    <div className="App">
      <div className='tourCenter'>
     <h1 className='yct'>All bookings</h1>
     {documents.map(document => {
      if(document.canceled){
        return(
            <div className='upcoming_tour_holder' style={{backgroundColor: 'blue'}}>
                   <div className='uth_1'>
        <div className='cal_holder'>
        <h1>{monthNames[document.date.toDate().getMonth()]} {getDayWithSuffix(document.date.toDate().getDate())}</h1>
        <h1>{document.time}</h1>
        </div>
      </div>
      <div className='uth_2'>
        <p>Recipient name: {document.student_name}</p>
        <p>Recipient email: {document.student_email}</p>
        <p>Recipient phone number: {document.student_phone_number}</p>
        <p>Guide email: {document.guide_email}</p>
        <p>Guide id: {document.guide_id}</p>
        <p>Guide stripe Account number: {document.stripe}</p>
         
      
      </div>
      <div className='uth_3'>
    
      </div>
      {!document.done && <button className='doneButtonCancel' onClick={() => done(document.id)}>Done</button> }
      {document.done && <p>done</p>}
                 </div>
                 
                  );
      }
      else{
        console.log(document.id);
      return(
<div className='upcoming_tour_holder'>
      <div className='uth_1'>
        <div className='cal_holder'>
        <h1>{monthNames[document.date.toDate().getMonth()]} {getDayWithSuffix(document.date.toDate().getDate())}</h1>
        <h1>{document.time}</h1>
        </div>
      </div>
      <div className='uth_2'>
        <p>Recipient name: {document.student_name}</p>
        <p>Recipient email: {document.student_email}</p>
        <p>Recipient phone number: {document.student_phone_number}</p>
        <p>Guide email: {document.guide_email}</p>
        <p>Guide id: {document.guide_id}</p>
        <p>Guide stripe Account number: {document.stripe}</p>
       
      
      </div>
      <div className='uth_3'>
    
      </div>
    {!document.done && <button className='doneButton' onClick={() => done(document.id)}>Done</button> }
    {document.done && <p>done</p>}
     </div>
     
      );
      }
        
     })}
     {/* <GuideTour key={document.id} prospectiveId={document.prospectiveId} date={document.date.toDate().toString()} time={document.time} /> */}

    
     </div>
    
    </div>
  );
}

export default AllBookings;
