import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {doc, getDoc} from 'firebase/firestore';
import { db } from './init';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { useUser } from './UserContext';
function SpecificTour() {
  const navigate = useNavigate();
  const {guide} = useUser();
  const [imageId, setImageId] = useState(0);
  const [maxImage, setMaxImage] = useState(1);
  const [profileImageUrls, setProfileImageUrls] = useState([]);
  function prevImage(){
    setImageId(imageId - 1);
  }
  function nextImage(){
    
    setImageId(imageId + 1);
  }
 
  const storage = getStorage();
  const [imageUrls, setImageUrls] = useState([]);
  
    let { id } = useParams();
    function Book(){
   
      /* 1. Navigate to the Details route with params */
      navigate(`/Book/${id}`);
     
  }
    const [Prevuniversity, PrevsetUniversity] = useState('');
    const [PrevgraduationYear, PrevsetGraduationYear] = useState('');
    const [Prevmajors, PrevsetMajors] = useState('');
    const [Prevminors, PrevsetMinors] = useState('');
    const [Prevecs, PrevsetEcs] = useState('');
    const [Prevbio, PrevsetBio] = useState('');
    const [Name, setName] = useState('');
  
    

    useEffect(() => {
       
        read();
       fetchImages(setImageUrls);
      fetchProfileImages(setProfileImageUrls);
     }, []);

     function fetchImages(setImageUrls) {
      const imagesRef = ref(storage, `${id}/`); // Adjust 'images/' to your specific path
     
      listAll(imagesRef)
        .then((res) => {
          setMaxImage(res.items.length);
          res.items.forEach((itemRef) => {
            // Get the download URL for each image
            getDownloadURL(itemRef).then((url) => {
              setImageUrls(prevUrls => [...prevUrls, url]);
             
            });
          });
        }).catch((error) => {
          console.error("Error fetching images:", error);
        });
    }
    function fetchProfileImages(setProfileImageUrls) {
      const imagesRef = ref(storage, `profile/${id}/`); // Adjust 'images/' to your specific path
      
      listAll(imagesRef)
        .then((res) => {
         
          res.items.forEach((itemRef) => {
            // Get the download URL for each image
            getDownloadURL(itemRef).then((url) => {
              setProfileImageUrls(prevUrls => [...prevUrls, url]);
            });
          });
        }).catch((error) => {
          console.error("Error fetching images:", error);
        });
    }
    async function read(){
        const docRef = doc(db, "tours", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            PrevsetUniversity(docSnap.data().university);
            PrevsetGraduationYear(docSnap.data().graduationYear);
            PrevsetMajors(docSnap.data().majors);
            PrevsetMinors(docSnap.data().minors);
            PrevsetEcs(docSnap.data().ecs);
            PrevsetBio(docSnap.data().bio);
            setName(docSnap.data().name);
            console.log("Document data:", docSnap.data());
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
    }

  return (
    <div className="App">
      <NavBar loggedIn={guide}></NavBar>
     <p>SpecificTour</p>
    {profileImageUrls[0] && <img src={profileImageUrls[0]} alt="Gallery" style={{ width: '100px', height: '100px' }} /> || <img src={require('./unkown.png')} alt="Gallery" style={{ width: '100px', height: '100px' }} /> }
   
     <p>Name: {Name}</p>
     <p>University: {Prevuniversity}</p>
     <p>Ecs: {Prevecs}</p>
     <p>Year: {PrevgraduationYear}</p>
     <p>Major: {Prevmajors}</p>
     <p>Minor: {Prevminors}</p>
     <p>Bio: {Prevbio}</p>
     <img src={imageUrls[imageId]} alt="Gallery" style={{ width: '100px', height: '100px' }} />
     {imageId > 0 && 
     <button onClick={prevImage}>Previous Image</button>
}
{imageId < maxImage - 1 && 
     <button onClick={nextImage}>Next Image</button>
}
{ !guide &&
     <button onClick={Book}>Book now</button>
}

    </div>
  );
}

export default SpecificTour;
