import {Link, useNavigate} from "react-router-dom";
import { auth, db } from '../init';
import {isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { doc, setDoc, addDoc, collection, updateDoc, getDoc } from "firebase/firestore";
function NavBarStudent() {
 
  const [loggedIn, setLoggedIn] = useState();
 
  useEffect(() => {
  
    // This is the listener for auth state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        
        // User is signed in
        setLoggedIn(true);
      } else {
        // User is signed out
        setLoggedIn(false);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div>
    
    <Link to="/">Home</Link>
    {!loggedIn && 
    <div>
    <Link to="/Signin">Signin</Link>
    <Link to="/Join">Join</Link>
    </div>
}
    <Link to="/Feed">Feed</Link>
    {loggedIn &&
    <Link to="/Account">My nonguide Account</Link>
    }
   
   
    
   

  
    
    </div>
  
  );
}
export function getUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe(); // Unsubscribe after receiving the initial value
      if (user) {
        checkIfGuide(user.uid);

        resolve(user); // Resolve the promise with the user object
      } else {
        resolve(null); // Resolve with null if there is no user
      }
    }, reject); // Reject the promise on error
  });
}
export function userIsGuide() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe(); // Unsubscribe after receiving the initial value
      if (user) {
        resolve(checkIfGuide(user.uid));
        resolve(user); // Resolve the promise with the user object
      } else {
        resolve(false); // Resolve with null if there is no user
      }
    }, reject); // Reject the promise on error
  });
}
async function checkIfGuide(id){
  const docRef = doc(db, "users", id);
  // alert(user.uid);
   const docSnap = await getDoc(docRef);
   if (docSnap.exists()) {
    if(docSnap.data().guide){
      return true;
    }
    else{
      return false;
    }
   }
}
export default NavBarStudent;
