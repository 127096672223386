import React, { useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import "./App.css";

export default function DashboardComponent(props) {
 
    const aId = props.aId;
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await fetch('https://us-central1-toouurs-f9210.cloudfunctions.net/retrieveData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( {aId} ),
      });
      if (!response.ok) {
        // Handle errors on the client side here
        const {error} = await response.json();
        console.log('An error occurred: ', error);
        return undefined;
      } else {
        const {client_secret: clientSecret} = await response.json();
        return clientSecret;
      }
    };
    return loadConnectAndInitialize({
        // This is your test publishable API key.
        publishableKey: "pk_live_51OwusoDuRy8wqOuXRhM3C6kM7jGbCTGiy9R666XgP372ToLSKiiRLsJOE1sjR7Wh0mzESzAqO5vlQGzZjervDZj700ncJAVT0R",
        fetchClientSecret: fetchClientSecret,
        appearance: {
          overlays: 'dialog',
          variables: {
            colorPrimary: '#625afa',
          },
        },
      })
    });

  return (
    <div className="container1">
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPayments />
        </ConnectComponentsProvider>
    </div>
  )
}