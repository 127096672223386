import {Link, useNavigate} from "react-router-dom";
import { auth, db } from '../init';
import {isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { doc, setDoc, addDoc, collection, updateDoc, getDoc, getDocs, query } from "firebase/firestore";

function ProspectiveTour(props) {
 let id = props.id;
 const [time, setTime] = useState();
 const [name, setName] = useState();
 const [date, setDate] = useState();
 useEffect(() => {
  getData();
 }, []);
 
 async function getData(){
  const q = query(collection(db, "bookings" + id));
    const querySnapshot = await getDocs(q);
    
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
     if(doc.data().prospectiveId === props.currentUser){
      setTime(doc.data().time);
      setDate(doc.data().date.toDate().toString());
     }
    });
    const docRef = doc(db, "tours", id);
    // alert(user.uid);
     const docSnap = await getDoc(docRef);
     if (docSnap.exists()) {
      setName(docSnap.data().name);
     }
 }
  return (
    <div>
    
   <p>{id}</p>
   <p>{time}</p>
   <p>{name}</p>
   <p>{date}</p>
   <p>Click to chat</p>
    </div>
  
  );
}

export default ProspectiveTour;