import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import NavBar from './NavBar';
import { getUser } from './NavBar';
import { useState, useEffect } from 'react';
import { db } from './init';
import { getDoc, doc, addDoc, collection, query, where, getDocs  } from 'firebase/firestore';
import ProspectiveTour from './components/ProspectiveTour';
function ProspectiveTours() {
    const [user, setUser] = useState(null);
    const [docsId, setDocsId] = useState([]);
  useEffect(() => {
      setDocsId([]);
    getUser().then(user => {
      if (user) {
       // alert(user.uid);
        setUser(user);
       
      } else {
        // Handle user not signed in
      }
    }).catch(error => {
      // Handle any errors
    });
     // setUser(getUser());
     
     //read();
     
   }, []);
   useEffect(() => {
    if(user){
    getTours();
    }
   }, [user])
   async function getTours(){
    
      let the_collection = "prospectivebookings" + user.uid;
      const q = query(collection(db, the_collection));
      const querySnapshot = await getDocs(q);
      let docsArr = [];
      querySnapshot.forEach((doc) => {
       docsArr = [...docsArr, doc.data().guideId];
    // doc.data() is never undefined for query doc snapshots
     
      });
      setDocsId(docsArr);
   }
  return (
    <div className="App">
      <NavBar loggedIn={false}></NavBar>
     <p>Manage my booked prospective tours</p>
     {docsId.map((document, i) => (
     <ProspectiveTour key={i} id={document} currentUser={user.uid}/>
    ))}
    </div>
  );
}

export default ProspectiveTours;
