import {Link, useNavigate} from "react-router-dom";
import { auth, db } from '../init';
import '../App.css';
import {isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { doc, setDoc, addDoc, collection, updateDoc, getDoc } from "firebase/firestore";
function DaySelector(props) {
    const [eight, setEight] = useState('not-selected');
  const [ten, setTen] = useState('not-selected');
  const [twelve, setTwelve] = useState('not-selected');
  const [two, setTwo] = useState('not-selected');
  const [four, setFour] = useState('not-selected');
  const [six, setSix] = useState('not-selected');
  const [timesArray, setTimesArray] = useState([]);
  const timesRef = doc(db, "times", props.id);
  useEffect(() => {
    getData();
  }, [])
  async function getData(){
    const docSnap = await getDoc(timesRef);
    
if (docSnap.exists()) {
 
 if(props.day === "sunday"){
    if(docSnap.data().sunday_eight === false){
      setEight('not-selected');
    }
    else{
      setEight('selected');
    }
    if(docSnap.data().sunday_ten === false){
      setTen('not-selected');
    }
    else{
      setTen('selected');
    }
    if(docSnap.data().sunday_twelve === false){
      setTwelve('not-selected');
    }
    else{
      setTwelve('selected');
    }
    if(docSnap.data().sunday_two === false){
      setTwo('not-selected');
    }
    else{
      setTwo('selected');
    }
    if(docSnap.data().sunday_four === false){
      setFour('not-selected');
    }
    else{
      setFour('selected');
    }
    if(docSnap.data().sunday_six === false){
      setSix('not-selected');
    }
    else{
      setSix('selected');
    }
  }
    if(props.day === "monday"){
      if(docSnap.data().monday_eight === false){
        setEight('not-selected');
      }
      else{
        setEight('selected');
      }
      if(docSnap.data().monday_ten === false){
        setTen('not-selected');
      }
      else{
        setTen('selected');
      }
      if(docSnap.data().monday_twelve === false){
        setTwelve('not-selected');
      }
      else{
        setTwelve('selected');
      }
      if(docSnap.data().monday_two === false){
        setTwo('not-selected');
      }
      else{
        setTwo('selected');
      }
      if(docSnap.data().monday_four === false){
        setFour('not-selected');
      }
      else{
        setFour('selected');
      }
      if(docSnap.data().monday_six === false){
        setSix('not-selected');
      }
      else{
        setSix('selected');
      }
 }
 if(props.day === "tuesday"){
  if(docSnap.data().tuesday_eight === false){
    setEight('not-selected');
  }
  else{
    setEight('selected');
  }
  if(docSnap.data().tuesday_ten === false){
    setTen('not-selected');
  }
  else{
    setTen('selected')
  }
  if(docSnap.data().tuesday_twelve === false){
    setTwelve('not-selected');
  }
  else{
    setTwelve('selected');
  }
  if(docSnap.data().tuesday_two === false){
    setTwo('not-selected');
  }
  else{
    setTwo('selected');
  }
  if(docSnap.data().tuesday_four === false){
    setFour('not-selected');
  }
  else{
    setFour('selected');
  }
  if(docSnap.data().tuesday_six === false){
    setSix('not-selected');
  }
  else{
    setSix('selected');
  }
}
  if(props.day === "wednesday"){
    if(docSnap.data().wednesday_eight === false){
      setEight('not-selected');
    }
    else{
      setEight('selected');
    }
    if(docSnap.data().wednesday_ten === false){
      setTen('not-selected');
    }
    else{
      setTen('selected');
    }
    if(docSnap.data().wednesday_twelve === false){
      setTwelve('not-selected');
    }
    else{
      setTwelve('selected');
    }
    if(docSnap.data().wednesday_two === false){
      setTwo('not-selected');
    }
    else{
      setTwo('selected');
    }
    if(docSnap.data().wednesday_four === false){
      setFour('not-selected');
    }
    else{
      setFour('selected');
    }
    if(docSnap.data().wednesday_six === false){
      setSix('not-selected');
    }
    else{
      setSix('selected');
    }
  }
    if(props.day === "thursday"){
      if(docSnap.data().thursday_eight === false){
        setEight('not-selected');
      }
      else{
        setEight('selected');
      }
      if(docSnap.data().thursday_ten === false){
        setTen('not-selected');
      }
      else{
        setTen('selected');
      }
      if(docSnap.data().thursday_twelve === false){
        setTwelve('not-selected');
      }
      else{
        setTwelve('selected');
      }
      if(docSnap.data().thursday_two === false){
        setTwo('not-selected');
      }
      else{
        setTwo('selected');
      }
      if(docSnap.data().thursday_four === false){
        setFour('not-selected');
      }
      else{
        setFour('selected');
      }
      if(docSnap.data().thursday_six === false){
        setSix('not-selected');
      }
      else{
        setSix('selected');
      }
    }
    if(props.day === "friday"){
      if(docSnap.data().friday_eight === false){
        setEight('not-selected');
      }
      else{
        setEight('selected');
      }
      if(docSnap.data().friday_ten === false){
        setTen('not-selected');
      }
      else{
        setTen('selected');
      }
      if(docSnap.data().friday_twelve === false){
        setTwelve('not-selected');
      }
      else{
        setTwelve('selected');
      }
      if(docSnap.data().friday_two === false){
        setTwo('not-selected');
      }
      else{
        setTwo('selected');
      }
      if(docSnap.data().friday_four === false){
        setFour('not-selected');
      }
      else{
        setFour('selected');
      }
      if(docSnap.data().friday_six === false){
        setSix('not-selected');
      }
      else{
        setSix('selected');
      }
    }
    if(props.day === "saturday"){
      if(docSnap.data().saturday_eight === false){
        setEight('not-selected');
      }
      else{
        setEight('selected');
      }
      if(docSnap.data().saturday_ten === false){
        setTen('not-selected');
      }
      else{
        setTen('selected');
      }
      if(docSnap.data().saturday_twelve === false){
        setTwelve('not-selected');
      }
      else{
        setTwelve('selected');
      }
      if(docSnap.data().saturday_two === false){
        setTwo('not-selected');
      }
      else{
        setTwo('selected');
      }
      if(docSnap.data().saturday_four === false){
        setFour('not-selected');
      }
      else{
        setFour('selected');
      }
      if(docSnap.data().saturday_six === false){
        setSix('not-selected');
      }
      else{
        setSix('selected');
      }
    }
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}

  }
  async function changeEight(){
    if(eight === 'selected'){
      setEight('not-selected');
      const filteredTimes = timesArray.filter(item => item !== 'eight');
      setTimesArray(filteredTimes);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_eight: false
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_eight: false
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_eight: false
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_eight: false
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_eight: false
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_eight: false
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_eight: false
        });
      }
     
    }
    else{
      setEight('selected');
      setTimesArray([...timesArray, 'eight']);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_eight: true
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_eight: true
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_eight: true
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_eight: true
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_eight: true
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_eight: true
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_eight: true
        });
      }
    }
  }
  async function changeTen(){
    if(ten === 'selected'){
      setTen('not-selected');
      const filteredTimes = timesArray.filter(item => item !== 'ten');
      setTimesArray(filteredTimes);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_ten: false
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_ten: false
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_ten: false
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_ten: false
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_ten: false
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_ten: false
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_ten: false
        });
      }
     
    }
    else{
      setTen('selected');
      setTimesArray([...timesArray, 'ten']);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_ten: true
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_ten: true
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_ten: true
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_ten: true
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_ten: true
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_ten: true
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_ten: true
        });
      }
    }
  }
  async function changeTwelve(){
    if(twelve === 'selected'){
      setTwelve('not-selected');
      const filteredTimes = timesArray.filter(item => item !== 'twelve');
      setTimesArray(filteredTimes);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_twelve: false
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_twelve: false
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_twelve: false
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_twelve: false
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_twelve: false
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_twelve: false
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_twelve: false
        });
      }
    }
    else{
      setTwelve('selected');
      setTimesArray([...timesArray, 'twelve']);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_twelve: true
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_twelve: true
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_twelve: true
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_twelve: true
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_twelve: true
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_twelve: true
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_twelve: true
        });
      }
    }
  }
  async function changeTwo(){
    if(two === 'selected'){
      setTwo('not-selected');
      const filteredTimes = timesArray.filter(item => item !== 'two');
      setTimesArray(filteredTimes);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_two: false
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_two: false
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_two: false
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_two: false
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_two: false
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_two: false
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_two: false
        });
      }
    }
    else{
      setTwo('selected');
      setTimesArray([...timesArray, 'two']);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_two: true
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_two: true
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_two: true
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_two: true
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_two: true
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_two: true
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_two: true
        });
      }
    }
  }
  async function changeFour(){
    if(four === 'selected'){
      setFour('not-selected');
      const filteredTimes = timesArray.filter(item => item !== 'four');
      setTimesArray(filteredTimes);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_four: false
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_four: false
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_four: false
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_four: false
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_four: false
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_four: false
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_four: false
        });
      }
    }
    else{
      setFour('selected');
      setTimesArray([...timesArray, 'four']);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_four: true
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_four: true
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_four: true
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_four: true
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_four: true
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_four: true
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_four: true
        });
      }
    }
  }
  
 
  async function changeSix(){
    if(six === 'selected'){
      setSix('not-selected');
      const filteredTimes = timesArray.filter(item => item !== 'six');
      setTimesArray(filteredTimes);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_six: false
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_six: false
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_six: false
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_six: false
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_six: false
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_six: false
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_six: false
        });
      }
    }
    else{
      setSix('selected');
      setTimesArray([...timesArray, 'six']);
      if(props.day === 'sunday'){
        await updateDoc(timesRef, {
          sunday_six: true
        });
      }
      else if(props.day === 'monday'){
        await updateDoc(timesRef, {
          monday_six: true
        });
      }
      else if(props.day === 'tuesday'){
        await updateDoc(timesRef, {
          tuesday_six: true
        });
      }
      else if(props.day === 'wednesday'){
        await updateDoc(timesRef, {
          wednesday_six: true
        })
      }
      else if(props.day === 'thursday'){
        await updateDoc(timesRef, {
          thursday_six: true
        });
      }
      else if(props.day === 'friday'){
        await updateDoc(timesRef, {
          friday_six: true
        });
      }
      else if(props.day === 'saturday'){
        await updateDoc(timesRef, {
          saturday_six: true
        });
      }
    }
  }
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
return (
    <div className="dayHolder">  
    <div className="dayHolderTop">
     <p>{capitalizeFirstLetter(props.day)}</p>
   </div>
   <div className="dayHolderBottom">
    
    <button onClick={changeEight} className={eight}>8 - 10 am</button>
    <button onClick={changeTen} className={ten}>10 - 12 am</button>
    <button onClick={changeTwelve} className={twelve}>12 - 2 pm</button>
    <button onClick={changeTwo} className={two}>2 - 4 pm</button>
    <button onClick={changeFour} className={four}>4 - 6 pm</button>
    <button onClick={changeSix} className={six}>6 - 8 pm</button>
   
    
   </div>
    </div>
  );
}

export default DaySelector;
