import React, { useState, useEffect } from 'react';
import '../App.css';
const ProgressBar = ({ progress, height, bgColor, completedColor }) => {
  // Internal state to manage animated progress
  const [animatedProgress, setAnimatedProgress] = useState(progress);

  // useEffect to update internal state when prop changes, to animate changes
  useEffect(() => {
    setAnimatedProgress(progress);
  }, [progress]);

  const containerStyles = {
    height: height,
    backgroundColor: bgColor,
    width: '100%',
    borderRadius: '0px',
    
    overflow: 'hidden', // Ensure the filler does not overflow the container's rounded corners
  };

  const fillerStyles = {
    height: '100%',
    width: `${animatedProgress}%`,
    backgroundColor: completedColor,
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out', // Adjust timing here for faster/slower transitions
  };


  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
       
      </div>
    </div>
  );
};

export default ProgressBar;