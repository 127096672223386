import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent} from "firebase/analytics";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAJFUq5YxS0Ao7HHeJjwfbQsWqA2gE61fs",
  authDomain: "toouurs-f9210.firebaseapp.com",
  projectId: "toouurs-f9210",
  storageBucket: "toouurs-f9210.appspot.com",
  messagingSenderId: "385482857064",
  appId: "1:385482857064:web:72d93832991506909879dd",
  measurementId: "G-SP4D316LJE"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth();
logEvent(analytics, 'firebase_database_query', {
  queryType: 'read',
  timestamp: new Date().toISOString()
});
export {app, db, auth};
