import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { useState } from 'react';
import { sendSignInLinkToEmail, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from './init';
import { doc, setDoc, addDoc, collection } from "firebase/firestore";

function JoinProspective() {
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const [b, setB] = useState(false);
  const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const actionCodeSettings = {
      // URL you want to redirect back to
      url: 'http://localhost:3000/',
      handleCodeInApp: true,
    };
    async function sendEmail(){
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
  }
  async function googleSignIn(){
    signInWithPopup(auth, provider)
.then((result) => {
  // This gives you a Google Access Token. You can use it to access the Google API.
  const credential = GoogleAuthProvider.credentialFromResult(result);
  const token = credential.accessToken;
  // The signed-in user info.
  const user = result.user;
  setDoc(doc(db, "users", result.user.uid), {
    email: result.user.email,
    uid: result.user.uid,
    guide: false
    
  });
  navigate('/');
  
  // IdP data available using getAdditionalUserInfo(result)
  // ...
}).catch((error) => {
  // Handle Errors here.
  const errorCode = error.code;
  const errorMessage = error.message;
  // The email of the user's account used.
  const email = error.customData.email;
  // The AuthCredential type that was used.
  const credential = GoogleAuthProvider.credentialFromError(error);
  // ...
});
  }
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
   
    function join(){
        sendEmail();
        setB(!b);
    }
  return (
    <div className="App">
      <NavBar guide={false} ></NavBar>

      
      {/* <button onClick={googleSignIn}>join with Google</button> */}
      {/* !b && <div>
        <input type="text" placeholder='email' onChange={handleEmailChange}></input>
      <button onClick={join}>Join</button>
  </div> }

        {b &&
        <p>Link sent email to: {email}</p> */}
    <div className='sign-in-holder'>
         <p className='sign-top'><span className='g'>Become</span> a toouurs prospective student</p>
         <div className='google-button'>
          <div className='google-button-center' onClick={googleSignIn}>
            <div className='image-box'>
              <img src={require('./google.png')}></img>
            </div>
            <div className='text-box'>
              <p>Continue with Google</p>
            </div>
          </div>
         
         </div>
         <p className='or'>or</p>
         <div className='inputTextHolder2'>
          <div className='inputTextHolderTop'>
          <p>Email</p>
          </div>
          <input type="text" className='inputText1' placeholder='Enter your email' onChange={handleEmailChange}></input>
        </div>
        <div className='sign-in' onClick={join}>
          <p>Verify Email</p>
          <img src={require('./rightArrow.png')} alt="hi"/>
        </div>
        </div>

      
    </div>
  );
}

export default JoinProspective;
