import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { useUser } from './UserContext';
import { auth } from './init';
import { signOut } from 'firebase/auth';
function ProspectiveAccount() {
  const navigate = useNavigate();
    const {guide} = useUser();
    function out(){
      signOut(auth);
      navigate('/');
    }
  return (
    <div className="App">
      <NavBar loggedIn={guide}></NavBar>
     <p>Prospective Account</p>
     <button onClick={out}>Sign Out</button>
    </div>
  );
}

export default ProspectiveAccount;
