import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import SvgGradient from './SvgGradient';
import Confetti from 'react-confetti';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { getDoc, doc, addDoc, collection, query, where, getDocs, updateDoc  } from 'firebase/firestore';
import { db } from './init';
import { getUser } from './NavBar';
function CompleteSignUp() {
 
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  function navToAccount(){
    navigate("/Account/1");
  }
  useEffect(() => {
      
    getUser().then(user => {
      if (user) {
       // alert(user.uid);
        setUser(user);
       
      } else {
        // Handle user not signed in
      }
    }).catch(error => {
      // Handle any errors
    });

}, [])
  
 useEffect(() => {
    if(user){
        getEmail();
    }
 }, [user]);
 
 async function getEmail(){
    const docRef = doc(db, "guide_tours", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        setEmail(docSnap.data().email);
    }
 }

 useEffect(() => {
   if(email){
    sendWelcomeEmail();
   }
    
 }, [email]);

 async function sendWelcomeEmail(){
    if(email){
        setLoading(false);
        const htmlBody = `
  
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Welcome to Our College Tour Platform!</title>
            <style>
                /* Add your CSS styles here */
                body {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                    background-color: #f7f7f7;
                    padding: 20px;
                }
                .container {
                    max-width: 600px;
                    margin: auto;
                    background: #fff;
                    padding: 30px;
                    border-radius: 5px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
                h2 {
                    color: #333;
                }
                p {
                    margin-bottom: 20px;
                }
                .website-structure {
                    margin-top: 30px;
                }
                .website-structure h3 {
                    margin-top: 20px;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <h2>Welcome to Our College Tour Platform!</h2>
                <p>Dear Guide,</p>
                <p>Thank you for joining our community of college guides! We are thrilled to have you on board and look forward to your valuable contributions in providing personalized college tours to high school students.</p>
                <h3>How being a guide for EduGold:</h3>
                <p>Prospective college students will go onto the platform and schedule tours with guides who fit their needs. If they decide to book a tour with you, you wil receive an email. You can decline a tour request anytime through your dashboard. Once a tour is complete, you will receive your pay within 24 hours ($25 an hour -> $50 for 2 hours)</p>
                <h3>Tips for Being a Great Guide:</h3>
                <ul>
                    <li>Be knowledgeable about the college you're providing tours for. Research your campus, facilities, programs, and any notable landmarks or features.</li>
                    <li>Be enthusiastic and engaging. Your passion for the college experience will inspire the students you guide.</li>
                    <li>Be personable and approachable. Create a comfortable atmosphere where students feel free to ask questions.</li>
                    <li>Be adaptable. Tailor your tour to the interests and needs of each group of students.</li>
                    <li>Be punctual and organized. Arrive on time for scheduled tours and have a clear itinerary planned.</li>
                    <li>Be open to feedback. Continuous improvement is key to providing excellent tours.</li>
                </ul>
                <div class="website-structure">
                    <h3>Website Structure:</h3>
                    <p>Our platform is designed to be user-friendly and intuitive. Here's a brief overview of the website structure:</p>
                    <ul>
                        <li><strong>Dashboard:</strong> Upon logging in, you will land on your dashboard where you can view and manage upcoming tours</li>
                        <li><strong>My Account (Profile / Tour Information):</strong> Update your profile with relevant information about yourself and your tour expertise.</li>
                        <li><strong>Profile (Payment Dashboard):</strong>View your income from the platform and manage banking information</li>
                    </ul>
                </div>
                <p>If you have any further questions or need assistance, don't hesitate to reach out to us. Once again, welcome aboard!</p>
                <p>Best regards,</p>
                <p>The EduGold Team</p>
            </div>
        </body>
        </html>
  `;
  const docRef = await addDoc(collection(db, "mail"), {
    to: email,
    message: {
      subject: 'Welcome to Toours',
      html: htmlBody,
    }
  }); 
    } 
 }
  return (
    <div className="App">
      <div className='guideTopNav' style={{border: 'none'}}>
     <div className='guideTopNavHalf'>
      <h3 className='g' style={{fontSize: '30px'}}>EduGold</h3>
     </div>
      <div className='guideTopNavHalf'>
      
      </div>
     </div>
     { loading && 
     <div className='c_center'>
   <CircularProgress color="inherit" size={100}/> 
   </div>
   }
     {!loading && 
    <div className='c_center'>
   
    <img src={require('./check-mark.png')} alt="check mark"/>
    <h1>Congratulations!</h1>
    <p>You are now signed up as a guide. You will recieve an email shortly with detailed information on how the EduGold platform works as well as tips to being a great guide.</p>
    <button onClick={navToAccount} className='c_button'>Go to Account</button>

   {/* <h1 style={{fontWeight: '400'}}>Your tour is now booked! Your guide will be in touch shortly, either by email or phone</h1>
    <div className='cc'>
    <button onClick={navToHome} className='c_button'>Return Home</button>
    <button onClick={navToFeed} className='c_button_right'>Return to feed</button>
    </div>
  */}
    </div>
}
    </div>
  );
}
export default CompleteSignUp;
