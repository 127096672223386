import React, { useState, useRef, useEffect } from 'react';
import Fuse from 'fuse.js';

const SearchComponent = (props) => {
  const focusRef = useRef(null);
  useEffect(() => {
    // Focus on the element when it's rendered
    if (focusRef.current) {
      //focusRef.current.focus();
      focusRef.current.classList.add("offeredItemHighlighted");
    }
  }, []);
  const sports = [
    "Cross Country",
"Field Hockey",
"Football",
"Soccer",
"Volleyball ",
"Water Polo",
"Basketball",
"Bowling",
"Fencing",
"Field Hockey",
"Gymnastics",
"Ice Hockey",
"Skiing",
"Swimming",
"Track & Field",
"Wrestling",
"Baseball",
"Softball",
"Golf",
"Lacrosse",
"Rowing",
"Tennis",
"Volleyball"];
const volunteering_organizations = ["Tutoring/mentoring",
"Environmental conservation",
"Elderly care",
"Homelessness outreach",
"Animal welfare",
"Healthcare support",
"Youth development",
"Disaster relief",
"Community gardening",
"Literacy promotion",
"Civic engagement",
"Arts and culture",
"Veterans support",
"Hunger relief",
"Community infrastructure",
"Mental Health",
"LGBTQ+ Support",
"Refugee Assistance",
"Substance Abuse",
"Disability Rights",
"Racial Justice",
"Tech Literacy",
"Research Programs",
"Conflict Resolution",
"Global Outreach"
];


const [currStemId, setCurrStemId] = useState(0);
const stem_organizations = [
"Aerospace Engineering",
"Artificial Intelligence Society",
"Astronomy Society",
"Biology Association",
"Chemical Engineering",
"Chemistry Society",
"Civil Engineering",
"Computer Engineering",
"Computer Science",
"Data Science",
"Electrical Engineering",
"Electronics",
"Engineering Design",
"Environmental Engineering",
"Environmental Science",
"Forensic Science",
"Geology",
"Geotechnical Engineering",
"Industrial Engineering",
"Marine Biology Society",
"Materials Engineering",
"Materials Science",
"Mathematics Olympiad",
"Mathematics Society",
"Mechanical Engineering",
"Medical Technology",
"Nanotechnology Society",
"Nuclear Engineering",
"Petroleum Engineering",
"Physics Society",
"Renewable Energy",
"Robotics",
"Rocketry Team",
"Science Olympiad",
"STEM Outreach Program",
"STEM Research Society",
"Structural Engineering",
"Systems Engineering",
"Women in STEM (WiSTEM)"];
const arts_organizations = [
"Calligraphy",
"Ceramics",
"Choir or Chorus",
"Collage",
"Creative Writing",
"Cultural Dance",
"Culinary Arts",
"Dance",
"Fashion Design",
"Film",
"Fine Arts",
"Glassblowing",
"Graffiti/Street Art",
"Graphic Design",
"Improv Comedy",
"Installation Art",
"Jewelry Making",
"Land Art",
"Literary Magazine",
"Mosaic Art",
"Music",
"Painting",
"Performance Art",
"Photography",
"Poetry",
"Printmaking",
"Sculpture",
"Textiles",
"Theater",
"Video Art",
"Visual Arts",
"Woodworking"];
const health_organizations = ["Cycling",
"Dance Fitness",
"Exercise Science",
"Hiking",
"Jogging",
"Kickboxing",
"Meditation",
"Nutrition",
"Outdoor Fitness",
"Pilates",
"Qi Gong",
"Running",
"Skiing",
"Snowboarding",
"Surfing",
"Tai Chi",
"Vegan",
"Vegetarian",
"Weightlifting",
"Yoga",
"Zumba"];
const gaming_organizations = ["Esports",
"Video Games",
"Board Games",
"RPG Club",
"Card Games",
"Chess",
"LAN Party",
"Mobile Gaming",
"Retro Gaming",
"Virtual Reality (VR)",
"Game Development",
"Game Design",
"Game Journalism"];
const music_organizations = ["A Capella",
"Accordion",
"Banjo",
"Bass Guitar",
"Cello",
"Chamber Orchestra",
"Clarinet",
"Comedy",
"Dance",
"Drama",
"Double Bass",
"Drum Kit",
"Electric Guitar",
"Flute",
"French Horn",
"Harmonica",
"Harp",
"Hip Hop Dance",
"Improv Comedy",
"Instrumental",
"Jazz Band",
"Mandolin",
"Musical Theater",
"Music Ensemble",
"Oboe",
"Opera",
"Performance Art",
"Piano",
"Saxophone",
"Shakespearean Society",
"Show Choir",
"Spoken Word Poetry Club",
"Stage Crew",
"Tap Dance",
"Trombone",
"Trumpet",
"Ukulele",
"Violin"];
const leadership_organizations = ["4-H Club",
"Alpha Phi Omega (APO)",
"Beta Club",
"Circle K International",
"DECA",
"FCCLA",
"Phi Beta Lambda (FBLA-PBL)",
"Future Educators Association (FEA)",
"Future Farmers of America (FFA)",
"Future Leaders of America (FLA)",
"Future Problem Solvers (FPS)",
"Girl Scouts/Boy Scouts of America",
"Golden Key International Honour Society",
"HOSA - Future Health Professionals",
"Junior Achievement",
"Key Club International",
"Kiwanis International",
"Leadership Conferences and Workshops",
"Leadership Development Program (LDP)",
"Lions Clubs International",
"Model United Nations (MUN)",
"National Honor Society (NHS)",
"National Society of Collegiate Scholars (NSCS)",
"National Society of Leadership and Success (NSLS)",
"Order of Omega",
"Residence Hall Associations (RHA)",
"Rotary International",
"SkillsUSA",
"Student Activities Board (SAB)",
"Student Ambassador Programs",
"Student Council",
"Student Leadership Advisory Board (SLAB)",
"Student-led Consulting Groups",
"Toastmasters International",
"Youth Leadership America"];
const socialjustice_organizations = ["Amnesty International",
"Anti-Violence Coalition",
"Asian Student Union",
"Black Student Union",
"Disability Rights Advocates",
"Feminist Collective",
"Human Rights Watch Student Task Force",
"Immigration Justice Coalition",
"Indigenous Peoples Alliance",
"Interfaith Alliance",
"LGBTQ+ Alliance",
"LGBTQ+ Rights Advocacy Group",
"Mental Health Advocacy Group",
"Multicultural Student Organization",
"NAACP",
"Peace and Justice Club",
"Refugee Support Network",
"Students Against Racism",
"Students for Environmental Action",
"United Nations Association",
"Womens Rights"];
const diversity_organizations = ["Allies for Inclusion",
"Cultural Diversity Club",
"Disability Services Office",
"Diversity Ambassadors Program",
"Diversity Council",
"Diversity Leadership Retreat",
"Ethnic Student Association",
"First-Gen",
"Inclusion Task Force",
"Interfaith Diversity Group",
"International Student Association",
"LGBTQ+ Resource Center",
"Multicultural Affairs Office",
"Social Justice Institute",
"Women's Resource Center"];
const career_organizations = ["Alumni Networking",
"Business Professional Association",
"Career Coaching",
"Career Fairs and Expos",
"Career Panels and Guest Speakers",
"Career Services Center",
"Co-ops",
"Employer Information Sessions",
"Entrepreneurship",
"Financial Literacy",
"Graduate School Preparation",
"Human Resources",
"Internship Opportunities",
"Interview Preparation",
"Job Search Support",
"Job Shadowing",
"Leadership Conferences",
"Leadership Development",
"Mentorship Program",
"Networking Events",
"Online Career Resources",
"Professional Association Memberships",
"Professional Development Seminars",
"Resume Building Workshops",
"Skill-Building Workshops",
"Work-Study Program",
"Young Professionals Group"];
const religion_organizations = ["Baha'i Campus Association",
"Buddhist Student Association",
"Catholic Campus Ministry",
"Chabad Jewish Student Organization",
"Christian Fellowship",
"Episcopal Campus Ministry",
"Hindu Students Council",
"Interfaith Student Organization",
"Islamic Society",
"Jewish Student Union",
"Latter-day Saint Student Association (LDS)",
"Lutheran Campus Ministry",
"Muslim Student Association (MSA)",
"Newman Center (Catholic)",
"Orthodox Christian Fellowship",
"Presbyterian Campus Ministry",
"Sikh Student Association",
"Unitarian Universalist Campus Ministry",
"Wesley Foundation (United Methodist)",
"Zen Meditation Group"];


  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  
  
  
  // Concatenating multiple arrays into one
  const combinedArray = [...stem_organizations, ...sports, ...arts_organizations, ...health_organizations, ...gaming_organizations, ...music_organizations, ...leadership_organizations, ...socialjustice_organizations, ...diversity_organizations, ...career_organizations, ...religion_organizations, ...volunteering_organizations];
  
  // Creating fuse instance with combinedArray
  const fuse = new Fuse(combinedArray, { includeScore: true, threshold: 0.3, keys: ['name'] });
  const change = () => {
    document.getElementById("first").classList.remove("offeredItemHighlighted");
    document.getElementById("first").classList.add("offeredItem");
  }
  const highlightFirst = () => {
    if(document.getElementById("first")){
      document.getElementById("first").classList.add("offeredItemHighlighted");
      document.getElementById("first").classList.remove("offeredItem");
      }
  }
  // Function to handle input change
  const handleInputChange = (event) => {
    if(document.getElementById("first")){
    document.getElementById("first").classList.add("offeredItemHighlighted");
    document.getElementById("first").classList.remove("offeredItem");
    }

    setSearchTerm(event.target.value);
    // Perform fuzzy search on combinedArray
    const results = fuse.search(event.target.value).map(result => result.item);
    // Filter exact match if found
    const exactMatchIndex = results.findIndex(item => item.toLowerCase() === event.target.value.toLowerCase());
    if (exactMatchIndex !== -1) {
      // Move exact match to the beginning
      const exactMatch = results.splice(exactMatchIndex, 1);
      setSearchResults([exactMatch[0]]);
    } else {
      if(results.length === 0){
        setSearchResults(["Create: '" + searchTerm + "'"]);
      }
      else{

      
      setSearchResults(results);
      }
    }
  };
  useEffect(() => {
    if(document.getElementById("first")){
      document.getElementById("first").classList.add("offeredItemHighlighted");
      document.getElementById("first").classList.remove("offeredItem");
      }
  
     
      // Perform fuzzy search on combinedArray
      const results = fuse.search(searchTerm).map(result => result.item);
      // Filter exact match if found
      const exactMatchIndex = results.findIndex(item => item.toLowerCase() === searchTerm.toLowerCase());
      if (exactMatchIndex !== -1) {
        // Move exact match to the beginning
        const exactMatch = results.splice(exactMatchIndex, 1);
        setSearchResults([exactMatch[0]]);
      } else {
        if(results.length === 0){
          setSearchResults(["Create: '" + searchTerm + "'"]);
        }
        else{
  
        
        setSearchResults(results);
        }
      }
  }, [searchTerm]);
  const addInput = (event) => {
      let added = event.target.innerHTML
      let newAdd = "";
     
      if(added.slice(0, 8) === 'Create: '){
        newAdd = added.slice(9, added.length - 1);
        props.handleSearchedCategoryClick(newAdd);
      }
      else{
        newAdd = added;
        if(sports.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Sports");
        }
        else if(volunteering_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Volunteering");
        }
        else if(arts_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Arts");
        }
        else if(music_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Music");
        }
        else if(career_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Career/Pre-professional");
        }
        else if(health_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Health/Fitness");
        }
        else if(religion_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Religious");
        }
        else if(diversity_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Diversity");
        }
        else if(socialjustice_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Social Justice");
        }
        else if(leadership_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Leadership");
        }
        else if(gaming_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Gaming");
        }
        else if(stem_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "STEM");
        }
      
      }
      
      setSearchTerm('');
  }
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // React to Enter key press
      let added = searchResults[0]
      let newAdd = "";
      if(added.slice(0, 8) === 'Create: '){
        newAdd = added.slice(9, added.length - 1);
        props.handleSearchedCategoryClick(newAdd);
      }
      else{
        newAdd = added;
        if(sports.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Sports");
        }
        else if(volunteering_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Volunteering");
        }
        else if(arts_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Arts");
        }
        else if(music_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Music");
        }
        else if(career_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Career/Pre-professional");
        }
        else if(health_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Health/Fitness");
        }
        else if(religion_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Religious");
        }
        else if(diversity_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Diversity");
        }
        else if(socialjustice_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Social Justice");
        }
        else if(leadership_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Leadership");
        }
        else if(gaming_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "Gaming");
        }
        else if(stem_organizations.includes(newAdd)){
          props.handleAlreadyCategory(newAdd, "STEM");
        }
      
      }
     
      setSearchTerm('');
    }
  };
  return (
    <div>
      <input 
        type="text" 
        placeholder="Search..." 
        value={searchTerm} 
        onChange={handleInputChange} 
        className='interests'
        onKeyPress={handleKeyPress}
      />
     {searchTerm !== "" &&  <div  className='itemsToRecommend'>
      {searchResults.length > 0 ? (
      <div>
          {searchResults.map((item, index) => {
            if(index === 0){
              return(
                <p id="first" className="offeredItemHighlighted" onClick={addInput}  onKeyPress={event => {
                 
                }} key={index}>{item}</p>
              )
            }
            else{
              return(
                <p className="offeredItem" onMouseOver={change} key={index} onClick={addInput}>{item}</p>
              );
            }
           
})}
       </div>
      ) : (
       
        <div>
           {searchTerm !== "" && <div>
           <br></br>
        <button  id="s" onClick={() => console.log('Make New')}>Add: "{searchTerm}"</button></div>}
        
        </div>
      )}
      </div> }
     
    </div> 
  );
};

export default SearchComponent;