import { useState } from "react";
import './App.css';
export default function T(props){
    if(props.items){
        console.log(props.items);
    }
    const items = props.items;
    const [currId, setCurrId] = useState(0);
    const scrollLeft = () => {
        let element = document.getElementById(props.categoryName + (currId - 3));
        if(element){
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          setCurrId(currId - 3);
        }
      }
      const scrollRight = () => {
    
        let element = document.getElementById(props.categoryName + (currId + 3));
        if(element){
          var leftPos = element.offsetLeft - 150;
          var container = element.parentElement;
        
          container.scrollTo({
            left: leftPos,
            behavior: 'smooth' // Optionally, use smooth scrolling
          });
          setCurrId(currId + 3);
        }
        else{
          var leftPos = element.offsetLeft - 150;
          let element = document.getElementById(props.categoryName + (currId + 3));
          var container = element.parentElement;
          container.scrollTo({
            left:leftPos,
            behavior: 'smooth' // Optionally, use smooth scrolling
          });
          
        }
      
      }
      
   return (
         <div className='categoryBox'>
          <div className='categoryTitle'>
          <p>{props.categoryName}</p>
          </div> 
         
          <div className='categoryContentHolderHolder'>
          { currId > 0 && <div className='scrollLeft' onClick={scrollLeft}>
               <img src={require('./ar1.png')} alt="more categories" ></img>
            </div>
          }
          {currId > 0 &&  <div className='fadeLeft'></div>}
          
          
          <div className='categoryContentHolder'>
       
             {
             items.map((item, index) => {
             if(index === 0){
              return (
                <button className='categoryNameFirst' id={props.categoryName + (index)} onClick={props.handleCategoryClick}>{item}</button>
              )
             }
             else{
              return (
                <button className='categoryName' id={props.categoryName + (index)} key={index} onClick={props.handleCategoryClick}>{item}</button>
              );
             }
                
             
              
})}
           
          </div>
          {currId < items.length - 3 && <div className='fadeRight'></div> }
          {currId < items.length - 3 &&  <div className='scroll' onClick={scrollRight}>
               <img src={require('./ar1.png')} alt="more categories" ></img>
              </div>
            }
         
       
         </div>
         </div>
   );
}