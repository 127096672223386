import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { useState } from 'react';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth, db } from './init';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";


function JoinGuide() {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const [b, setB] = useState(false);
  const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const actionCodeSettings = {
      // URL you want to redirect back to
      url: 'https://edu-gold.com/GuideInformation',
      handleCodeInApp: true,
    };
    async function sendEmail(){
      
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
  }
 
    async function googleSignIn(){
      signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    setDoc(doc(db, "users", result.user.uid), {
      email: result.user.email,
      uid: result.user.uid,
      guide: true
      
    });
    navigate('/GuideInformation');
    
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    function join(){
        sendEmail();
        setB(!b);
    }
    function handleB(){
      setB(!b);
    }
    const [currentUser, setCurrentUser] = useState('not logged in');
    const [menu, setMenu] = useState(false);
    const explore = () => {
      document.body.style.overflowY = 'auto';
      navigate('/Feed');
     }
     const signup = () => {
      document.body.style.overflowY = 'auto';
      navigate('/JoinGuide');
     }
     const signin = () => {
      document.body.style.overflowY = 'auto';
      navigate('/Signin');
     }
     function openNav(){
      setMenu(true);
      document.body.style.overflow = 'hidden';
     }
     function setMenuFalse(){
      setMenu(false);
      document.body.style.overflowY = 'auto';
     }

  return (
    <div>
      
      <NavBar guide={false} onClick={openNav}></NavBar>
      <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svggg'>
  {/* Define linear gradient for the background */}
  <defs>
    {/* Define linear gradient animation */}
    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#4158D0">
        <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="46%" stopColor="#C850C0">
        <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="100%" stopColor="#FFCC70">
        <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
      </stop>
    </linearGradient>
  </defs>

  {/* Rotate the entire SVG upside down */}
  <g transform="rotate(180 250 100)">
    {/* Draw the paths with their respective gradients */}
    <path d="M 0 120 C 100 100 200 00 500 0 L 0 0 L 0 0" fill="url(#gradient1)" />
  </g>
</svg>
{menu && <div className='menu_nav'>
    <div className='menu_nav_inner'>
      
      <img onClick={setMenuFalse} src={require('./exit.png')} alt="exit" className='menu_nav_exit'></img>
      <div className='nav_inner_holder'>
      <p onClick={explore}>Explore</p>
      <p onClick={signup}>Create a guide account</p>
      <p onClick={signin}>Sign in as a guide</p>
      </div> 
    </div>
    </div> }
      { /*!b && <div>
        <input type="text" placeholder='email' onChange={handleEmailChange}></input>
      <button onClick={join}>Join</button>
      <button onClick={googleSignIn}>Join with Google</button>
        </div>}
 */}
        {b &&
        <div className='sign-in-holder1'>
       
        <p>Link sent email to: {email}</p>
        <div className='sign-in' onClick={handleB}>
          <p>Resend Link</p>
          <img src={require('./rightArrow.png')} alt="hi"/>
        </div>
        </div>
        }
        {!b && 
    <div className='sign-in-holder'>
         <p className='sign-top1'><span className='g'>Become</span> a guide for EduGold</p>
         <div className='google-button'>
          <div className='google-button-center' onClick={googleSignIn}>
            <div className='image-box'>
              <img src={require('./google.png')}></img>
            </div>
            <div className='text-box'>
              <p>Continue with Google</p>
            </div>
          </div>
         
         </div>
         <p className='or'>or</p>
         <div className='inputTextHolder2'>
          <div className='inputTextHolderTop'>
          <p>Email</p>
          </div>
          <input type="text" className='inputText1' placeholder='Enter your email' onChange={handleEmailChange}></input>
        </div>
        <div className='sign-in' onClick={join}>
          <p>Verify Email</p>
          <img src={require('./rightArrow.png')} alt="hi"/>
        </div>
        <p>Already have an account? <span onClick={signin} className='signinbutton'>Sign in</span></p>
        </div>
}
    <a className="clickhere" href="https://docs.google.com/document/d/e/2PACX-1vQW2dFrJct2TJs8WHWpi9gqAZ9qzxaDpWnl7uaNmdh22HIdWCsR2Z4QLAH9QhXYTMAS8Ton1hota80N/pub" target="_blank">Click here for a detailed guide</a>
    </div>
  );
}

export default JoinGuide;
