import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link, useParams} from "react-router-dom";
import NavBar from './NavBar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useEffect, useState } from 'react';
import { db } from './init';
import { getDoc, doc, addDoc, collection, query, where, getDocs  } from 'firebase/firestore';
import { getUser } from './NavBar';
import { useNavigate } from 'react-router-dom';
function Book() {


  const [eight, setEight] = useState(false);
  const [ten, setTen] = useState(false);
  const [twelve, setTwelve] = useState(false);
  const [two, setTwo] = useState(false);
  const [four, setFour] = useState(false);
  const [six, setSix] = useState(false);
  const [finalData, setFinalDate] = useState(null);
  const [finalTime, setFinalTime] = useState(null);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();


  useEffect(() => {
      
    getUser().then(user => {
      if (user) {
       // alert(user.uid);
        setUser(user);
       
      } else {
        // Handle user not signed in
      }
    }).catch(error => {
      // Handle any errors
    });
     // setUser(getUser());
     
     //read();
      
   }, []);
    let { id } = useParams();
    const [date, setDate] = useState(new Date());
    const onChange = newDate => {
      setDate(newDate);
      
    };
    const docRef = doc(db, "times", id);
 useEffect(() => {
  setEight(false);
  setTen(false);
  setTwelve(false);
  setTwo(false);
  setFour(false);
  setSix(false);
  setTimes();
 }, [date]);
 async function setTimes(){
  const docSnap = await getDoc(docRef);
  let day = date.getDay();

  if(day === 0){
    if(docSnap.data().sunday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().sunday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().sunday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().sunday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().sunday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().sunday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 1){
    if(docSnap.data().monday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().monday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().monday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().monday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().monday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().monday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 2){
    if(docSnap.data().tuesday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().tuesday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().tuesday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().tuesday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().tuesday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().tuesday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 3){
    if(docSnap.data().wednesday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().wednesday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().wednesday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().wednesday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().wednesday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().wednesday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 4){
    if(docSnap.data().thursday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().thursday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().thursday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().thursday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().thursday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().thursday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 5){
    if(docSnap.data().friday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().friday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().friday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().friday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().friday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().friday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }
  else if(day === 6){
    if(docSnap.data().saturday_eight === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "8-10"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setEight(true);
      }
    }
    if(docSnap.data().saturday_ten === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "10-12"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
        booked = true;
      });
     if(!booked){
      setTen(true);
      }
    }
    if(docSnap.data().saturday_twelve === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "12-2"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
       booked = true;
      });
      if(!booked){
        setTwelve(true);
     }
    }
    if(docSnap.data().saturday_two === true){
      let the_collection = "bookings" + id;
    const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "2-4"));
    const querySnapshot = await getDocs(q);
    let booked = false;
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
      if(!booked){
        setTwo(true);
      }
    }
    if(docSnap.data().saturday_four === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "4-6"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
     booked = true;
    });
     if(!booked){
        setFour(true);
      }
    }
    if(docSnap.data().saturday_six === true){
      let the_collection = "bookings" + id;
      const q = query(collection(db, the_collection), where("date", "==", date), where("time", "==", "6-8"));
      const querySnapshot = await getDocs(q);
      let booked = false;
      querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
      booked = true;
    });
      if(!booked){
       setSix(true);
      }
    }

  }

 }
 async function bookEight(){
  setFinalDate(date);
  setFinalTime("8-10");
 }
 async function bookTen(){
  setFinalDate(date);
  setFinalTime("10-12");
  
 }
 async function bookTwelve(){
  setFinalDate(date);
  setFinalTime("12-2");
  
 }
 async function bookTwo(){
  setFinalDate(date);
  setFinalTime("2-4");
  
 }
 async function bookFour(){
  setFinalDate(date);
  setFinalTime("4-6");
  
 }
 async function bookSix(){
  setFinalDate(date);
  setFinalTime("6-8");
  
 }
async function finalBooking(){
  
  
if(finalTime === "8-10"){
  
 
  let the_collection = "bookings" + id;

  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "8-10",
    prospectiveId: user.uid 
  });

  let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  });

}
else if(finalTime === "10-12"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "10-12",
    prospectiveId: user.uid 
  });
  let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  });
}
else if(finalTime === "12-2"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "12-2",
    prospectiveId: user.uid 
  });
  let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  });
}
else if(finalTime === "2-4"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "2-4",
    prospectiveId: user.uid 
  });
  let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  });
}
else if(finalTime === "4-6"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "4-6",
    prospectiveId: user.uid 
  });
}
else if(finalTime === "6-8"){
  let the_collection = "bookings" + id;
  const docRef = await addDoc(collection(db, the_collection), {
    date: date,
    time: "6-8",
    prospectiveId: user.uid 
  });
  let prospective_collection = "prospectivebookings" + user.uid;
  const docRef1 = await addDoc(collection(db, prospective_collection), {
    guideId: id
  });
}

navigate('/CompletedBooking');
}
  return (
    <div className="App">
     <p>Booking Page: {id}</p>
     <div className="myCalendar">
     <Calendar onChange={onChange} />
     </div>
     {eight && <button onClick={bookEight}>8-10</button> }
     {ten && <button onClick={bookTen}>10-12</button> }
     {twelve && <button onClick={bookTwelve}>12-2</button> }
     {two && <button onClick={bookTwo}>2-4</button> }
     {four && <button onClick={bookFour}>4-6</button> }
     {six && <button onClick={bookSix}>6-8</button> }

    <p>Selected Date: {date.getDay()}</p>
    <button onClick={finalBooking}>Confirm</button>
    </div>
  );
}

export default Book;
