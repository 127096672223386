import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Link, useNavigation, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { auth, db } from './init';
import {isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { doc, setDoc, addDoc, collection, getDoc } from "firebase/firestore";
import { getUser, userIsGuide } from './NavBar';
import NavBarStudent from './components/NavBarStudent';
import { useUser } from './UserContext';
import { ReactSVG } from 'react-svg';
import SvgGradient from './SvgGradient';
import { motion } from "framer-motion";
import { Parallax } from 'react-scroll-parallax';
function Home() {
  
  const [theEmail, setTheEmail] = useState('not logged in');
  const [currentUser, setCurrentUser] = useState('not logged in');
  const { guide, refreshUserData } = useUser();
  const [enter, setEntered] = useState(false);
  const [progress, setProgress] = useState(0);
 
  useEffect(() => {
    refreshUserData();
    if (isSignInWithEmailLink(auth, window.location.href)) {  
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {    
        email = window.prompt('Please provide your email for confirmation');
      }  
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {  
          window.localStorage.removeItem('emailForSignIn');   
          const uid = result.user.uid;   
          setUser(uid, result.user.email);
          setCurrentUser(result.user.email);
          refreshUserData();
        })
        .catch((error) => {            
        });
        console.log("hi");
       
          
        

    }
    else{
            
      const user = getUser()
      .then((response) => {
        if(response){
        console.log(response.email + " " + response.uid);
       // setUser(response.uid, response.email);
        setCurrentUser(response.email);
        }
        
      })
     
    }
    
    async function setUser(id, email){
      const docRef = doc(db, "users", id);
      // alert(user.uid);
       const docSnap = await getDoc(docRef);
       if (!docSnap.exists()) {
        setDoc(doc(db, "users", id), {
          email: email,
          uid: id,
          guide: false
        });
       }
    }
    // This is the listener for auth state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setCurrentUser(user.email);
      } else {
        // User is signed out
        setCurrentUser('n');
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);
  const [images, setImages] = useState([]);
  const [numImages, setNumImages] = useState(0);
  /*const addImage = () => {
    let arr = images.slice(-3);
    let position = -500;
   
    setNumImages(numImages + 1);
    const newImage = {
      id: images.length,
      src: require('./undraw.png'), // Replace with your image path
      alt: 'Description of the image',
      position: position
       // Replace with your image description
    };
    setImages([...arr, newImage]);
  };
  
  
  useEffect(() => {
    
    const interval = setInterval(() => {
      addImage();
      
    }, 7000);

    return () => clearInterval(interval);
    
  }, [images]);

  */
 const navigate = useNavigate();
 const explore = () => {
  document.body.style.overflowY = 'auto';
  navigate('/Feed');
 }
 const signup = () => {
  document.body.style.overflowY = 'auto';
  navigate('/JoinGuide');
 }
 const signin = () => {
  document.body.style.overflowY = 'auto';
  navigate('/Signin');
 }
 const dashboard = () => {
  document.body.style.overflowY = 'auto';
  navigate('/GuideTours');
 }
 const account = () => {
  document.body.style.overflowY = 'auto';
  navigate('/Account/0');
 }
 useEffect(() => {
  //alert("entered");
  if(progress > 0.15 && progress < 0.75){
    setEntered(true);
  } else if(progress > 0.75){
    setEntered(false);
  }
  else{
    setEntered(false);
  }
 }, [progress]);
 
 const [menu, setMenu] = useState(false);
 function openNav(){
  setMenu(true);
  document.body.style.overflow = 'hidden';
 }
 function setMenuFalse(){
  setMenu(false);
  document.body.style.overflowY = 'auto';
 }
 let prevInt = 0;
 useEffect(() => {
 
    let randint = Math.floor(Math.random() * 8);
    prevInt = randint;
    if( document.getElementById('img' + randint)){
    document.getElementById('img' + randint).classList.add("changeSizeClass");
    }
  const intervalId = setInterval(() => {
    if(document.getElementById('img' + prevInt)){
    document.getElementById('img' + prevInt).classList.remove("changeSizeClass");
    }
    let randint = Math.floor(Math.random() * 8);
    prevInt = randint;
    if( document.getElementById('img' + randint)){
    document.getElementById('img' + randint).classList.add("changeSizeClass");
    }
    
  }, 2000); // Interval in milliseconds (e.g., 1000ms = 1 second)

  // Cleanup function to clear the interval when component unmounts or changes
  return () => {
    clearInterval(intervalId);
  };
}, []); //
  return (
    
    <div className="AppHome">

    <NavBar page="home" border={false} color={false} onClick={openNav}></NavBar>
    
    <SvgGradient />
    {menu && <div className='menu_nav'>
    <div className='menu_nav_inner'>
      
      <img onClick={setMenuFalse} src={require('./exit.png')} alt="exit" className='menu_nav_exit'></img>
      {currentUser === 'n' && <div className='nav_inner_holder'>
      <p onClick={explore}>Explore</p>
      <p onClick={signup}>Create a guide account</p>
      <p onClick={signin}>Sign in as a guide</p>
      </div> }
      {currentUser !== 'n' && <div className='nav_inner_holder'>
      <p onClick={explore}>Explore</p>
      <p onClick={dashboard}>Dashboard</p>
      <p onClick={account}>My Account</p>
      </div> }
    </div>
    </div> }
    {
     /*  
     <Link to="/GuideInformation1">guideinformation1</Link><p>Home</p>
     <p>{currentUser}</p>*/}
     <div className='H_first'>
      <div className='H_first_left'>
     <div className='H_first_left_container'>
      <h1>One-On-One college tours</h1>
     </div>
     <div className='H_first_left_container'>
      <p>EduGold connects current college students to high schoolers looking to get a more personalized experience in the college admissions process. Explore the culturally, academically, and socially diverse group of guides below</p>
     </div>
     <div className='H_first_left_container'>
     <button onClick={explore}>Explore Tours</button>
     </div>
      </div>
      <div className='H_first_right'>
      
      <div className='container'>
      <div alt="hi" className='img_new' id="img0">
        <div className='img_inner'>
        <img src={require('./p1.jpg')} alt="hi" />
        <h1>Aria Davis</h1>
        <h2>Tufts University</h2>
        </div>
      
      </div>
      <div alt="hi" className='img_new' id="img1">
      <div className='img_inner'>
        <img src={require('./p2.jpg')} alt="hi" />
        <h1>Ethan Williams</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img_new' id="img2">
      <div className='img_inner'>
        <img src={require('./p3.jpg')} alt="hi" />
        <h1>Luna Silverado</h1>
        <h2>Tufts University</h2>
        </div>
      </div>

     {/* <div alt="hi" className='img11 img22'>
      <div className='img_inner'>
        <img src={require('./p2.jpg')} alt="hi" />
        <h1>Ethan Williams</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img11 img44'>
      <div className='img_inner'>
        <img src={require('./p3.jpg')} alt="hi" />
        <h1>Luna Silverado</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img11 img55'>
      <div className='img_inner'>
        <img src={require('./p4.jpg')} alt="hi" />
        <h1>Phineas Johnson</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img11 img66'>
      <div className='img_inner'>
        <img src={require('./p5.jpg')} alt="hi" />
        <h1>Olivia Miller</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img11 img77'>
      <div className='img_inner'>
        <img src={require('./p6.jpg')} alt="hi" />
        <h1>Michael Jones</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
    */}
     
      
     
    { /*  images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} className='img' />
    ))} */}
     
      </div>
      <div className='container' id='last_container'>
      <div alt="hi" className='img_new id="img3"'>
      <div className='img_inner'>
        <img src={require('./p4.jpg')} alt="hi" />
        <h1 style={{color: 'white'}}>Phineas Johnson</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img_new' id="img4">
      <div className='img_inner'>
        <img src={require('./p5.jpg')} alt="hi" />
        <h1>Olivia Miller</h1>
        <h2>Harvard University</h2>
        </div>
      </div>
      <div alt="hi" className='img_new' id="img5">
      <div className='img_inner'>
        <img src={require('./p6.jpg')} alt="hi" />
        <h1>Michael Jones</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      
     
    { /*  images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} className='img' />
    ))} */}
     
      </div>
      <div className='container'>
      <div alt="hi" className='img_new' id="img6">
      <div className='img_inner'>
        <img src={require('./p8.jpeg')} alt="hi" />
        <h1>Daniel Lewis</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img_new' id="img7">
      <div className='img_inner'>
        <img src={require('./p9.jpeg')} alt="hi" />
        <h1>Sophia Wilson</h1>
        <h2>Tufts University</h2>
        </div>
      </div>
      <div alt="hi" className='img_new' id="img8">
      <div className='img_inner'>
        <img src={require('./p10.jpeg')} alt="hi" />
        <h1 style={{color: 'black'}}>William Clark</h1>
        <h2 style={{color: 'black'}}>Tufts University</h2>
        </div>
      </div>

     
      
     
    { /*  images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} className='img' />
    ))} */}
     
      </div>
      <div className='topRight'></div>
        </div>
     </div>

      
    
     

    
    <div className='second_header'>
      <h1>What We <span className='g'>Provide</span></h1>
    </div>
    <div className='H_second'>
    <div className='H_second_third'>
      <div className='third_1'>
      <img  src={require('./graduation.png')} alt="hi" className='cap' />
      </div>
      <div className='third_2'>
        <h2>Personalized Experience</h2>
      </div>
      <div className='third_3'>
        <p>Personalized campus tours tailored to your interests. Skip the generic tours; customize your visit with us to explore academics, activities, or student life that matter to you. Experience a real taste of campus life and find your perfect college journey.</p>
      </div>
   
    </div>
    <div className='H_second_third'>
    <div className='third_1'>
      <img src={require('./bulb.png')} alt="hi" />
      </div>
      <div className='third_2'>
      <h2>Real Student Insights</h2>
      </div>
      <div className='third_3'>
        <p>Connect with current students for authentic insights into college life. Get personal anecdotes, advice on academics and social life, and honest opinions on campus pros and cons. Experience the college beyond brochures with firsthand perspectives.</p>
      </div>
   
    </div>
    <div className='H_second_third'>
    <div className='third_1'>
      <img src={require('./handshake.png')} alt="hi" className='cap'/>
      </div>
      <div className='third_2'>
      <h2>Networking Opportunities</h2>
      </div>
      <div className='third_3'>
        <p>Start building your college network today. Our platform offers unique opportunities to connect with current students, paving the way for future collaborations, mentorships, and friendships. Dive into your college community before stepping foot on campus.</p>
      </div>
   
    </div>

    </div>

    
    <div className='H_second_thirdd'>
      <h1 className='why'>Why EduGold</h1>
      <div className='compare'>
        <div className='compareLeft'></div>
        <div className='compareRight'>
          <div className='compareRightHalf'>
            <p>EduGold</p>
          </div>
          <div className='compareRightHalf'>
            <p style={{textWrap: 'nowrap'}}>Traditional tours</p>
          </div>
        </div>
      </div>
      <div className='compare1'>
        <div className='compareLeft'>
          <h2>Personalized Experience</h2>
          <p>During a EduGold Tour, the prospective student has the undivided attention from the tour guide, allowing for a tailored experience based on their specific interests, questions, and concerns. </p>
        </div>
        <div className='compareRight'>
          <div className='compareRightHalf'>
            <img src={require('./checkmark.png')} alt="checkmark" className='check'/>
          </div>
          <div className='compareRightHalf'>
          <img src={require('./close.png')} alt="checkmark" className='check1'/>
          </div>
        </div>
      </div>
      <div className='compare1'>
        <div className='compareLeft'>
          <h2>Flexibility and Focus</h2>
          <p>Individual tours provide flexible scheduling tailored to the prospective student's needs. Unlike group tours with fixed itineraries, individual tours can be adjusted to focus on their specific interests.</p>
        </div>
        <div className='compareRight'>
          <div className='compareRightHalf'>
            <img src={require('./checkmark.png')} alt="checkmark" className='check'/>
          </div>
          <div className='compareRightHalf'>
          <img src={require('./close.png')} alt="checkmark" className='check1'/>
          </div>
        </div>
      </div>
      <div className='compare1'>
        <div className='compareLeft'>
          <h2>Opportunity</h2>
          <p> Individual tours provide a greater opportunity for engagement between the prospective student and the tour guide or admissions staff. Without competing voices, the student can ask questions more freely amd engage in deeper conversations.</p>
        </div>
        <div className='compareRight'>
          <div className='compareRightHalf'>
            <img src={require('./checkmark.png')} alt="checkmark" className='check'/>
          </div>
          <div className='compareRightHalf'>
          <img src={require('./close.png')} alt="checkmark" className='check1'/>
          </div>
        </div>
      </div>
    </div>
    <div className='H_third'></div>
    
   {/* <div className='H_forth'>

    <div className='H_second_third'>
      
    <div className='third_11'>
      <img src={require('./graduationhat.png')} alt="hi" className='capp' />
      </div>
      <div className='third_22'>
      <h1>100</h1>
      </div>
      <div className='third_33'>
       <h2>UNIVERSITIES TO EXPLORE</h2>
      </div>
   
    </div>
    <div className='H_second_third'>
    <div className='third_11'>
      <img src={require('./walk.png')} alt="hi" className='capp'/>
      </div>
      <div className='third_22'>
      <h1>1000+</h1>
      </div>
      <div className='third_33'>
        <h2>TOURS CONDUCTED</h2>
      </div>
   
    </div>
    <div className='H_second_third'>
    <div className='third_11'>
      <img src={require('./graduationstudent.png')} alt="hi" className='capp'/>
      </div>
      <div className='third_22'>
      <h1>300+</h1>
      </div>
      <div className='third_33'>
        <h2>TOUR GUIDES</h2>
      </div>
   
    </div>
    <div className='H_second_third'>
    <div className='third_11'>
      <img src={require('./backpack.png')} alt="hi" className='capp'/>
      </div>
      <div className='third_22'>
      <h1>1500+</h1>
      </div>
      <div className='third_33'>
        <h2>PROSPECTIVE STUDENTS</h2>
      </div>
   
    </div>
    </div>
  */}
    <div className='H_fifth'>
      <h1>Hear from our happy high schoolers</h1>
    </div>
    <div className='H_six'>
    <div className='H_six_third'>
    <div className='third_header'>
    <h3>James Henderson - Rising Junior</h3>
  </div>
  <div className='third_middle'>
    <h3>During my tour, I got to sit in on an actual marine biology class and speak with the professor afterwards. It was incredible - like a sneak peek into my future. That experience alone sealed the deal for me; I knew this was the university where I wanted to dive into my studies</h3>
    
   
  </div>
</div>
<div className='H_six_third'>
  <div className='third_header'>
    <h3>Allysa lunothy - Rising Senior</h3>
  </div>
  <div className='third_middle'>
    <h3>I never expected to feel so at home on a college campus, but my one-on-one tour completely changed that. Walking through the historical architecture while discussing philosophy with my guide - a current student - made me realize this is where my academic dreams will take flight.</h3>
   {/* <img src={require('./quote.png')} alt="quotation mark"></img> */}
  </div>
</div>
<div className='H_six_third'>
<div className='third_header'>
    <h3>Jospeh Aldright - Rising Senior</h3>
  </div>
  <div className='third_middle'>
    <h3>The highlight of my campus visit was the impromptu jam session with a few music majors. I just mentioned I played guitar, and next thing I knew, I was strumming chords and singing along like I'd been part of the band for years. That moment of connection told me everything I needed to know about the community here.</h3>
    
  </div>
</div>
    </div>
    <div className='H_seven'>
     <h2>EduGold, MEDFORD, MA 02155</h2>
     <h4>elliot.friesen7@gmail.com</h4>
     <a href='https://www.linkedin.com/in/elliot-friesen-6970a8240' target="_blank">in</a>
    </div>
  
    </div>
  );
}

export default Home;
