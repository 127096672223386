import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import NavBar from './NavBar';
import { useState } from 'react';
import { sendSignInLinkToEmail, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './init';
function Signin() {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const [b, setB] = useState(false);
  const [email, setEmail] = useState('');
    
    const actionCodeSettings = {
      // URL you want to redirect back to
      url: 'https://edu-gold.com/',
      handleCodeInApp: true,
    };
    async function sendEmail(){
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
  }
  async function googleSignIn(){
    signInWithPopup(auth, provider)
.then((result) => {
  // This gives you a Google Access Token. You can use it to access the Google API.
  const credential = GoogleAuthProvider.credentialFromResult(result);
  const token = credential.accessToken;
  // The signed-in user info.
  const user = result.user;
  
  navigate('/');
  
  // IdP data available using getAdditionalUserInfo(result)
  // ...
}).catch((error) => {
  // Handle Errors here.
  const errorCode = error.code;
  const errorMessage = error.message;
  // The email of the user's account used.
  const email = error.customData.email;
  // The AuthCredential type that was used.
  const credential = GoogleAuthProvider.credentialFromError(error);
  // ...
});
  }
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
   
    function join(){
        sendEmail();
        setB(!b);
    }
    const [currentUser, setCurrentUser] = useState('not logged in');
    const [menu, setMenu] = useState(false);
    const explore = () => {
      document.body.style.overflowY = 'auto';
      navigate('/Feed');
     }
     const signup = () => {
      document.body.style.overflowY = 'auto';
      navigate('/JoinGuide');
      
     }
     const signin = () => {
      document.body.style.overflowY = 'auto';
      navigate('/Signin');
     }
     function openNav(){
      setMenu(true);
      document.body.style.overflow = 'hidden';
     }
     function setMenuFalse(){
      setMenu(false);
      document.body.style.overflowY = 'auto';
     }
     function handleB(){
      setB(!b);
    }
  return (
    <div className="App">
      <NavBar guide={false} onClick={openNav}></NavBar>
      <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svggg'>
  {/* Define linear gradient for the background */}
  <defs>
    {/* Define linear gradient animation */}
    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#4158D0">
        <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="46%" stopColor="#C850C0">
        <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
      </stop>
      <stop offset="100%" stopColor="#FFCC70">
        <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
      </stop>
    </linearGradient>
  </defs>

  {/* Rotate the entire SVG upside down */}
  <g transform="rotate(180 250 100)">
    {/* Draw the paths with their respective gradients */}
    <path d="M 500 120 C 700 100 300 0 0 0 L 300 0 L 500 0" fill="url(#gradient1)" />
  </g>
</svg>
{menu && <div className='menu_nav'>
    <div className='menu_nav_inner'>
      
      <img onClick={setMenuFalse} src={require('./exit.png')} alt="exit" className='menu_nav_exit'></img>
      <div className='nav_inner_holder'>
      <p onClick={explore}>Explore</p>
      <p onClick={signup}>Create a guide account</p>
      <p onClick={signin}>Sign in as a guide</p>
      </div> 
    </div>
    </div> }
     {/*!b && <div>
        <input type="text" placeholder='email' onChange={handleEmailChange}></input>
      <button onClick={join}>Sign in</button>
      <button onClick={googleSignIn}>Sign in with google</button>
  </div> */}
{b &&
        <div className='sign-in-holder1'>
       
        <p>Link sent email to: {email}</p>
        <div className='sign-in' onClick={handleB}>
          <p>Resend Link</p>
          <img src={require('./rightArrow.png')} alt="hi"/>
        </div>
        </div>
        }
       
      {!b &&
        <div className='sign-in-holder'>
         <p className='sign-top'>Guide <span className='g'>sign in</span> to EduGold</p>
         <div className='google-button'>
          <div className='google-button-center' onClick={googleSignIn}>
            <div className='image-box'>
              <img src={require('./google.png')}></img>
            </div>
            <div className='text-box'>
              <p>Continue with Google</p>
            </div>
          </div>
         
         </div>
         <p className='or'>or</p>
         <div className='inputTextHolder2'>
          <div className='inputTextHolderTop'>
          <p>Email</p>
          </div>
          <input type="text" className='inputText1' placeholder='Enter your email' onChange={handleEmailChange}></input>
        </div>
        <div className='sign-in' onClick={join}>
          <p>Verify Email</p>
          <img src={require('./rightArrow.png')} alt="hi"/>
        </div>
      
        </div>
}
    </div>

  );
}

export default Signin;
