import React from 'react';
import './App.css'
const SvgGradient = () => {
   // const wavePath = "M0,100 L0,50 C20,80 40,90 60,80 C80,70 90,60 100,50 L100,100 Z";

    return (
        
        <svg viewBox="0 0 500 200" xmlns="http://www.w3.org/2000/svg" className='svgg'>
        {/* Define linear gradient for the background */}
        <defs>
          {/* Define linear gradient animation */}
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#4158D0">
              <animate attributeName="stop-color" values="#4158D0; #4168D0; #4158D0;" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="46%" stopColor="#C850C0">
              <animate attributeName="stop-color" values="#C850C0; #C860C0; #C850C0;" dur="5s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" stopColor="#FFCC70">
              <animate attributeName="stop-color" values="#FFCC70; #FFDC70; #FFCC70;" dur="5s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
  
        {/* Draw the paths with their respective gradients */}
        <path d="M 0 50 C 150 150 300 0 500 80 L 500 0 L 0 0" fill="url(#gradient1)" />
      </svg>
    );
  };
export default SvgGradient;
