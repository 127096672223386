import React, { useState, useRef, useEffect } from 'react';
import './App.css'

const CategoryChoice = (props) => {
  const [prevId, setPrevId] = useState('');
  
  return (
    
<div className='categoryChoice' id={"cat" + props.i} onClick={props.handleClick}>
                <div className='categoryImageHolder'>
                  <img src={props.src} alt="volunteer"/>
                </div>
                <div className='categoryTitleHolder'>
                  <p>{props.categoryName}</p>
                </div>
              </div>
     
    );
};

export default CategoryChoice;